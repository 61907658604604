import { useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { TMachineBooking } from '@repo/types'
import { format, set } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { DialogFooter } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'

import { useOrderPlanner } from '../hooks/use-order-planner'

const formSchema = z.object({
	startTime: z
		.string()
		.min(1, { message: 'Please enter a start time' })
		.regex(
			/^([01]\d|2[0-3]):([0-5]\d)$/,
			'Use 24-hour format (HH:mm), from 00:00 to 23:59',
		),
	startDate: z.date({
		required_error: 'Please select a start date',
		invalid_type_error: 'Please select a valid date',
	}),
})

type FormValues = z.infer<typeof formSchema>

export function ChangeStartDateDialog(props: {
	booking: TMachineBooking
	container?: HTMLElement | null
	onClose: () => void
	onSubmit?: SubmitHandler<Date>
	onReplan?: SubmitHandler<Date>
}) {
	const { booking, container, onClose, onSubmit, onReplan } = props
	const { moveBooking } = useOrderPlanner()
	const [isCalendarOpen, setIsCalendarOpen] = useState(false)

	const {
		control,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			startTime: format(booking.startDate, 'HH:mm'),
			startDate: new Date(booking.startDate),
		},
	})

	const processFormData = (data: FormValues) => {
		const [hours, minutes] = data.startTime.split(':').map(Number)
		return set(data.startDate, {
			hours,
			minutes,
			seconds: 0,
			milliseconds: 0,
		})
	}

	const onSubmitForm = handleSubmit(data => {
		const desiredStartDate = processFormData(data)

		const newSchedule = moveBooking({
			booking,
			desiredStartDate,
		})

		const actualStartDate = newSchedule?.startDate
			? new Date(newSchedule.startDate)
			: desiredStartDate

		toast.success(
			`Booking moved to ${format(actualStartDate, 'EEE d MMM yyyy, HH:mm')}`,
		)
		onSubmit?.(actualStartDate)
		onClose()
	})

	const handleReplanOrder = handleSubmit(data => {
		const desiredStartDate = processFormData(data)

		onReplan?.(desiredStartDate)
		onClose()
	})

	const transformTime = (value: string) => {
		const hasColon = value.includes(':')
		const digits = value.replace(/\D/g, '')

		if (digits.length < 2) return digits
		if (digits.length === 2) return digits + (hasColon ? ':' : '')
		if (digits.length === 3) return `${digits.slice(0, 2)}:${digits.slice(2)}`
		return `${digits.slice(0, 2)}:${digits.slice(2, 4)}`
	}

	const formatTimeOnBlur = (value: string) => {
		const isValidHour = /^(0?[0-9]|1[0-9]|2[0-3])$/.test(value)
		return isValidHour ? `${value.padStart(2, '0')}:00` : value
	}

	return (
		<GenericDialog
			title="Change start date"
			description={`Enter a new start date for this booking.${onReplan ? ' Or replan the order based on the selected date.' : ''}`}
			container={container}
			onClose={onClose}
		>
			<form onSubmit={onSubmitForm}>
				<div className="grid grid-cols-[80px_1fr] items-start gap-x-4">
					<div className="space-y-2">
						<Label htmlFor="startTime">Time</Label>
						<Controller
							name="startTime"
							control={control}
							render={({ field }) => (
								<Input
									{...field}
									id="startTime"
									className="w-full max-w-[80px] text-center"
									placeholder="00:00"
									error={Boolean(errors.startTime)}
									onChange={e => field.onChange(transformTime(e.target.value))}
									onBlur={e => field.onChange(formatTimeOnBlur(e.target.value))}
								/>
							)}
						/>
					</div>
					<div className="space-y-2">
						<Label htmlFor="startDate">Date</Label>
						<Controller
							name="startDate"
							control={control}
							render={({ field }) => (
								<Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
									<PopoverTrigger asChild>
										<Button
											id="startDate"
											variant="outline"
											className={cn(
												'w-full justify-start text-left font-normal',
												Boolean(errors.startDate) && 'border-destructive',
												!field.value && 'text-muted-foreground',
											)}
										>
											<CalendarIcon className="mr-2 h-4 w-4" />
											{field.value ? (
												format(field.value, 'PPP')
											) : (
												<span>Pick a date</span>
											)}
										</Button>
									</PopoverTrigger>
									<PopoverContent className="w-auto p-0">
										<Calendar
											mode="single"
											selected={field.value}
											onSelect={date => {
												field.onChange(date)
												setIsCalendarOpen(false)
											}}
											initialFocus
										/>
									</PopoverContent>
								</Popover>
							)}
						/>
					</div>
					{Object.keys(errors).length > 0 && (
						<div className="col-span-full py-1">
							{errors.startTime && (
								<p className="text-xs text-destructive">
									{errors.startTime.message}
								</p>
							)}
							{errors.startDate && (
								<p className="text-xs text-destructive">
									{errors.startDate.message}
								</p>
							)}
						</div>
					)}
				</div>
				<DialogFooter className="mt-6">
					<Button type="button" variant="outline" onClick={onClose}>
						Cancel
					</Button>
					{onReplan && (
						<Button type="button" variant="outline" onClick={handleReplanOrder}>
							Replan Order
						</Button>
					)}
					<Button type="submit" disabled={!isDirty}>
						Change Start Date
					</Button>
				</DialogFooter>
			</form>
		</GenericDialog>
	)
}
