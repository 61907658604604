import { TOrder, TOrderStatus, TProduct } from '@repo/types'
import { Link } from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'
import {
	compareAsc,
	format,
	isSameDay,
	isSameMonth,
	isSameYear,
} from 'date-fns'
import {
	ArrowLeftFromLine,
	ArrowRight,
	ArrowRightFromLine,
	BellRing,
	Copy,
	Eraser,
	GanttChart,
	Hammer,
	MoreHorizontal,
	Pencil,
	Power,
	PowerOff,
	Timer,
	Trash2,
} from 'lucide-react'

import { ClickToCopyBadge } from '@/components/click-to-copy-badge'
import { StatusBadge } from '@/components/status-badge'
import { DataTableColumnHeader } from '@/components/table/data-table-column-header'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@/components/ui/tooltip'
import { AlertIcon } from '@/features/alerts/components/alert-icon'
import { TPlanningAlert } from '@/features/validation/types'
import { formatNumericString } from '@/utils/format-numeric-string'

declare module '@tanstack/react-table' {
	interface TableMeta<TData> {
		actions: (order: TData) => {
			onEdit: () => void
			onDuplicate: () => void
			onDelete: () => void
			onShowInPlan?: () => void
			onPlanForward?: () => void
			onPlanBackward?: () => void
			onUnschedule?: () => void
			onDeactivate?: () => void
			onReactivate?: () => void
		}
	}
}

type TOrderTableColumn = TOrder<TProduct> & {
	productionDurationMinutes: number
	formattedProductionDuration: string
	alerts: TPlanningAlert[]
}

export const columns: ColumnDef<TOrderTableColumn>[] = [
	{
		id: 'select',
		header: ({ table }) => (
			<div className="flex items-center">
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && 'indeterminate')
					}
					onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
					aria-label="Select all"
				/>
			</div>
		),
		cell: ({ row }) => (
			<Checkbox
				checked={row.getIsSelected()}
				onCheckedChange={value => row.toggleSelected(!!value)}
				aria-label="Select row"
			/>
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'productionOrderNumber',
		meta: { label: 'Production Order Number' },
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="PO#"
				tooltip="Production Order Number"
			/>
		),
		cell: ({ row }) => {
			const productionOrderNumber = row.getValue<string>(
				'productionOrderNumber',
			)
			return (
				<ClickToCopyBadge
					value={productionOrderNumber}
					displayValue={`#${productionOrderNumber}`}
					variant="outline"
				/>
			)
		},
	},
	{
		accessorKey: 'status',
		meta: { label: 'Status' },
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Status" />
		),
		cell: ({ row }) => {
			const status = row.getValue<TOrderStatus>('status')

			const formatDateRange = (startDate: string, endDate: string) => {
				const sameYear = isSameYear(startDate, endDate)
				const sameMonth = sameYear && isSameMonth(startDate, endDate)
				const sameDay = sameMonth && isSameDay(startDate, endDate)

				if (sameDay) {
					return format(startDate, 'd MMM, yyyy')
				}
				if (sameMonth) {
					return `${format(startDate, 'd')}-${format(endDate, 'd MMM, yyyy')}`
				}
				if (sameYear) {
					return `${format(startDate, 'd MMM')}-${format(endDate, 'd MMM yyyy')}`
				}
				return `${format(startDate, 'd MMM yyyy')}-${format(endDate, 'd MMM yyyy')}`
			}

			const renderStatusWithDate = (
				status: TOrderStatus,
				dateString: string,
			) => (
				<div className="flex flex-col items-start gap-1">
					<StatusBadge status={status} />
					<span className="whitespace-nowrap text-xs text-muted-foreground">
						{dateString}
					</span>
				</div>
			)

			if (
				'plannedPeriod' in row.original &&
				['planned', 'in-progress'].includes(status)
			) {
				const { startDate, endDate } = row.original.plannedPeriod
				return renderStatusWithDate(status, formatDateRange(startDate, endDate))
			}

			if ('completionDate' in row.original && status === 'completed') {
				const dateString = format(row.original.completionDate, 'd MMM yyyy')
				return renderStatusWithDate(status, dateString)
			}

			return <StatusBadge status={status} />
		},
		filterFn: (row, id, value) => {
			return value.includes(row.getValue(id))
		},
		sortingFn: (rowA, rowB) => {
			const statusA = rowA.getValue<TOrderStatus>('status')
			const statusB = rowB.getValue<TOrderStatus>('status')

			const statusCompare = statusA.localeCompare(statusB)
			if (statusCompare !== 0) {
				return statusCompare
			}

			const getDateForStatus = (row: typeof rowA) => {
				if (['planned', 'in-progress'].includes(row.getValue('status'))) {
					return 'plannedPeriod' in row.original
						? row.original.plannedPeriod.startDate
						: null
				}
				if (row.getValue('status') === 'completed') {
					return 'completionDate' in row.original
						? row.original.completionDate
						: null
				}
				return null
			}

			const dateA = getDateForStatus(rowA)
			const dateB = getDateForStatus(rowB)

			if (dateA && dateB) {
				return compareAsc(dateA, dateB)
			}

			return 0
		},
	},
	{
		accessorKey: 'product',
		meta: { label: 'Product' },
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Product" />
		),
		cell: ({ row }) => {
			const product = row.getValue<TProduct>('product')

			return (
				<div className="grid justify-items-start gap-1">
					<span>{product.name}</span>
					<ClickToCopyBadge
						value={product.productNumber}
						displayValue={`#${product.productNumber}`}
						variant="outline"
					/>
				</div>
			)
		},
		filterFn: (row, id, value) => {
			const product = row.getValue<TProduct>(id)
			return (
				product.name.toLowerCase().includes(value.toLowerCase()) ||
				product.productNumber.toLowerCase().includes(value.toLowerCase())
			)
		},
	},
	{
		accessorKey: 'quantity',
		meta: { label: 'Quantity' },
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Quantity" />
		),
		cell: ({ row }) => {
			const quantity = row.getValue<number>('quantity')
			return <div className="text-right">{formatNumericString(quantity)}</div>
		},
	},
	{
		accessorKey: 'customerName',
		meta: { label: 'Customer' },
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Customer" />
		),
	},
	{
		accessorKey: 'salesOrderNumber',
		meta: { label: 'Sales Order Number' },
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="SO#"
				tooltip="Sales Order Number"
			/>
		),
		cell: ({ row }) => {
			const salesOrderNumber = row.getValue<string>('salesOrderNumber')
			return (
				<ClickToCopyBadge
					value={salesOrderNumber}
					displayValue={`#${salesOrderNumber}`}
					variant="outline"
				/>
			)
		},
	},
	{
		accessorKey: 'earliestStartDate',
		meta: { label: 'Earliest Start Date' },
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Earliest Start"
				tooltip="Earliest Start Date"
			/>
		),
		cell: ({ row }) => {
			const earliestStartDate = row.getValue<Date>('earliestStartDate')

			return (
				<span className="whitespace-nowrap">
					{format(earliestStartDate, 'd MMM yyyy')}
				</span>
			)
		},
	},
	{
		accessorKey: 'dueDate',
		meta: { label: 'Due Date' },
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Due" tooltip="Due Date" />
		),
		cell: ({ row }) => {
			const dueDate = row.getValue<Date>('dueDate')

			return (
				<span className="whitespace-nowrap">
					{format(dueDate, 'd MMM yyyy')}
				</span>
			)
		},
	},
	{
		accessorKey: 'productionDurationMinutes',
		meta: { label: 'Eff. Duration' },
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Eff. Duration"
				tooltip="Effective Production Duration"
			/>
		),
		cell: ({ row }) => {
			const productionDuration = row.original.formattedProductionDuration

			return (
				<div className="flex items-center whitespace-nowrap text-xs text-muted-foreground">
					<Timer className="mr-1 h-4 w-4 shrink-0" /> {productionDuration}
				</div>
			)
		},
	},
	{
		accessorKey: 'alerts',
		meta: { label: 'Alerts' },
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Alerts" />
		),
		cell: ({ row }) => {
			const alerts = row.getValue<TPlanningAlert[]>('alerts')
			const orderId = row.original.id

			if (!alerts || alerts.length === 0) return null

			const alertCounts = alerts.reduce(
				(acc: Record<string, number>, alert: TPlanningAlert) => {
					acc[alert.category] = (acc[alert.category] || 0) + 1
					return acc
				},
				{} as Record<string, number>,
			)
			const categories = Object.keys(alertCounts)

			return (
				<Link from="/orders" to="/alerts" search={{ orderId }}>
					<Tooltip>
						<TooltipTrigger>
							<Badge variant="outline" className="whitespace-nowrap">
								<BellRing className="mr-2 h-4 w-4 shrink-0 text-destructive" />{' '}
								{alerts.length}
							</Badge>
						</TooltipTrigger>
						<TooltipContent side="top">
							<div className="flex flex-col gap-2">
								<div className="flex items-center justify-between">
									<p className="font-medium">Current Alerts</p>
									{categories.length > 1 && (
										<Badge variant="secondary" className="font-mono">
											{alerts.length}
										</Badge>
									)}
								</div>

								<div className="flex flex-col gap-2">
									{alertCounts['overlap'] && (
										<div className="flex items-center justify-between">
											<div className="flex items-center gap-2">
												<AlertIcon category="overlap" />
												<span className="text-sm">Overlaps</span>
											</div>
											<Badge variant="outline" className="font-mono">
												{alertCounts['overlap']}
											</Badge>
										</div>
									)}
									{alertCounts['early'] && (
										<div className="flex items-center justify-between">
											<div className="flex items-center gap-2">
												<AlertIcon category="early" />
												<span className="text-sm">Early Scheduling</span>
											</div>
											<Badge variant="outline" className="font-mono">
												{alertCounts['early']}
											</Badge>
										</div>
									)}
									{alertCounts['late'] && (
										<div className="flex items-center justify-between">
											<div className="flex items-center gap-2">
												<AlertIcon category="late" />
												<span className="text-sm">Late Scheduling</span>
											</div>
											<Badge variant="outline" className="font-mono">
												{alertCounts['late']}
											</Badge>
										</div>
									)}
									{(alertCounts['transition-hard-linked'] || 0) +
										(alertCounts['transition-soft-linked'] || 0) >
										0 && (
										<div className="flex items-center justify-between">
											<div className="flex items-center gap-2">
												<AlertIcon category="transition-hard-linked" />
												<span className="text-sm">Transition Issues</span>
											</div>
											<Badge variant="outline" className="font-mono">
												{(alertCounts['transition-hard-linked'] || 0) +
													(alertCounts['transition-soft-linked'] || 0)}
											</Badge>
										</div>
									)}
									{alertCounts['tool-overlap'] && (
										<div className="flex items-center justify-between">
											<div className="flex items-center gap-2">
												<Hammer className="text-info h-4 w-4" />
												<span className="text-sm">Tool Conflicts</span>
											</div>
											<Badge variant="outline" className="font-mono">
												{alertCounts['tool-overlap']}
											</Badge>
										</div>
									)}
								</div>

								<div className="flex items-center gap-1">
									<span className="text-xs text-muted-foreground">
										View details in{' '}
										<span className="font-medium">Alert Center</span>
									</span>
									<ArrowRight className="h-3 w-3 text-muted-foreground" />
								</div>
							</div>
						</TooltipContent>
					</Tooltip>
				</Link>
			)
		},
	},
	{
		id: 'actions',
		cell: ({ row, table }) => {
			const actions = table.options.meta?.actions(row.original)

			if (!actions) {
				return null
			}

			return (
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button variant="ghost" className="h-8 w-8 p-0">
							<span className="sr-only">Open menu</span>
							<MoreHorizontal className="h-4 w-4" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end">
						<DropdownMenuLabel>Actions</DropdownMenuLabel>
						{actions.onReactivate && (
							<DropdownMenuItem onClick={actions.onReactivate}>
								<Power className="mr-2 h-4 w-4" />
								<span>Reactivate</span>
							</DropdownMenuItem>
						)}
						{actions.onShowInPlan && (
							<DropdownMenuItem onClick={actions.onShowInPlan}>
								<GanttChart className="mr-2 h-4 w-4" />
								<span>Show In Plan</span>
							</DropdownMenuItem>
						)}
						{actions.onUnschedule && (
							<DropdownMenuItem onClick={actions.onUnschedule}>
								<Eraser className="mr-2 h-4 w-4" />
								<span>Unschedule</span>
							</DropdownMenuItem>
						)}
						{actions.onPlanForward && (
							<DropdownMenuItem onClick={actions.onPlanForward}>
								<ArrowRightFromLine className="mr-2 h-4 w-4" />
								<span>Plan Forward</span>
							</DropdownMenuItem>
						)}
						{actions.onPlanBackward && (
							<DropdownMenuItem onClick={actions.onPlanBackward}>
								<ArrowLeftFromLine className="mr-2 h-4 w-4" />
								<span>Plan Backward</span>
							</DropdownMenuItem>
						)}
						<DropdownMenuSeparator />
						<DropdownMenuItem onClick={actions.onEdit}>
							<Pencil className="mr-2 h-4 w-4" />
							<span>Edit</span>
						</DropdownMenuItem>
						<DropdownMenuItem onClick={actions.onDuplicate}>
							<Copy className="mr-2 h-4 w-4" />
							<span>Duplicate</span>
						</DropdownMenuItem>
						{actions.onDeactivate && (
							<DropdownMenuItem onClick={actions.onDeactivate}>
								<PowerOff className="mr-2 h-4 w-4" />
								<span>Deactivate</span>
							</DropdownMenuItem>
						)}
						<DropdownMenuItem onClick={actions.onDelete}>
							<Trash2 className="mr-2 h-4 w-4" />
							<span>Delete</span>
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			)
		},
		enableSorting: false,
		enableHiding: false,
	},
]
