import { useMemo } from 'react'

import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'
import {
	addDays,
	addMonths,
	addYears,
	DateArg,
	differenceInDays,
	endOfDay,
	endOfMonth,
	endOfWeek,
	endOfYear,
	isSameDay,
	startOfDay,
	startOfMonth,
	startOfWeek,
	startOfYear,
} from 'date-fns'
import {
	Calendar1,
	CalendarDays,
	CalendarRange,
	ChevronLeft,
	ChevronRight,
	ChevronsLeft,
	ChevronsRight,
} from 'lucide-react'
import { DateRange } from 'react-day-picker'
import { z } from 'zod'

import { useAppSelector } from '@/app/hooks'
import { PageLayout } from '@/components/layout'
import {
	ScrollableToolbar,
	ScrollableToolbarContentLeft,
	ScrollableToolbarContentRight,
} from '@/components/scrollable-toolbar'
import { Button } from '@/components/ui/button'
import { DateRangePicker } from '@/components/ui/date-range-picker'
import { selectStaffDemand } from '@/features/planning/planning-slice'
import { StaffDemandChart } from '@/features/staff-demand/components/staff-demand-chart'
import {
	AggregationTypeOption,
	aggregationTypeSchema,
	ChartTypeOption,
	chartTypeSchema,
	IntervalOption,
	intervalSchema,
} from '@/features/staff-demand/schema'
import { selectStaffGroupNames } from '@/features/staff-groups/staff-groups-slice'
import { useSyncedPersistedState } from '@/lib/hooks/use-synced-persisted-state'

/** Calculates available intervals and default interval based on date range */
function getIntervalOptions(
	startDate: DateArg<Date> | null,
	endDate: DateArg<Date> | null,
): {
	availableIntervals: IntervalOption[]
	defaultInterval: IntervalOption
} {
	if (!startDate || !endDate) {
		return {
			availableIntervals: ['15min', '30min', '1h', '4h', '8h'],
			defaultInterval: '1h',
		}
	}

	const daysDiff = differenceInDays(endDate, startDate) + 1

	if (daysDiff > 100) {
		// More than a quarter
		return {
			availableIntervals: ['7d'],
			defaultInterval: '7d',
		}
	} else if (daysDiff > 21) {
		// More than 3 weeks
		return {
			availableIntervals: ['1d', '7d'],
			defaultInterval: '1d',
		}
	} else if (daysDiff > 7) {
		// More than a week
		return {
			availableIntervals: ['8h', '1d'],
			defaultInterval: '1d',
		}
	} else if (daysDiff > 1) {
		// Multiple days
		return {
			availableIntervals: ['1h', '4h', '8h', '1d'],
			defaultInterval: '1h',
		}
	} else {
		// Single day
		return {
			availableIntervals: ['15min', '30min', '1h', '4h', '8h'],
			defaultInterval: '1h',
		}
	}
}

const Route = createFileRoute('/staff-demand')({
	validateSearch: zodValidator(
		z.object({
			from: z
				.string()
				.datetime()
				.nullable()
				.default(() =>
					startOfWeek(new Date(), { weekStartsOn: 1 }).toISOString(),
				),
			to: z
				.string()
				.datetime()
				.nullable()
				.default(() =>
					endOfWeek(new Date(), { weekStartsOn: 1 }).toISOString(),
				),
			interval: intervalSchema.default('1h'),
			chartType: chartTypeSchema.optional(),
			aggregationType: aggregationTypeSchema.optional(),
			staffGroups: z.array(z.string()).optional(),
		}),
	),
	search: {
		middlewares: [
			({ search, next }) => {
				const result = next(search)

				if (!result.from || !result.to) return result

				const { availableIntervals, defaultInterval } = getIntervalOptions(
					result.from,
					result.to,
				)

				return {
					...result,
					interval: availableIntervals.includes(
						result.interval as IntervalOption,
					)
						? result.interval
						: defaultInterval,
				}
			},
		],
	},
	component: StaffDemandComponent,
})

/** Converts an Interval to number of minutes. */
function getIntervalInMinutes(interval: IntervalOption): number {
	switch (interval) {
		case '15min':
			return 15
		case '30min':
			return 30
		case '1h':
			return 60
		case '4h':
			return 240
		case '8h':
			return 480
		case '1d':
			return 1440
		case '7d':
			return 10080
		default:
			return 60
	}
}

function StaffDemandComponent() {
	const search = Route.useSearch()
	const navigate = useNavigate({ from: Route.fullPath })
	const allStaffGroups = useAppSelector(selectStaffGroupNames)
	const today = new Date()

	const chartType = useSyncedPersistedState<ChartTypeOption>(
		'staffDemand.chartType',
		{
			value: search.chartType,
			defaultValue: 'stacked',
		},
	)

	const aggregationType = useSyncedPersistedState<AggregationTypeOption>(
		'staffDemand.aggregationType',
		{
			value: search.aggregationType,
			defaultValue: 'max',
		},
	)

	const dateRange: DateRange = useMemo(
		() => ({
			from: search.from ? new Date(search.from) : undefined,
			to: search.to ? new Date(search.to) : undefined,
		}),
		[search.from, search.to],
	)

	const intervalMinutes = useMemo(
		() => getIntervalInMinutes(search.interval),
		[search.interval],
	)

	const period = useMemo(() => {
		const now = new Date()
		return {
			startDate: startOfDay(dateRange?.from ?? now),
			endDate: endOfDay(dateRange?.to ?? dateRange?.from ?? now),
		}
	}, [dateRange])

	const selectedStaffGroups = useMemo(
		() => search.staffGroups ?? [],
		[search.staffGroups],
	)

	const staffDemand = useAppSelector(state =>
		selectStaffDemand(state, {
			period,
			intervalMinutes,
			staffGroups: selectedStaffGroups,
		}),
	)

	const { availableIntervals } = useMemo(
		() =>
			getIntervalOptions(
				search.from ? new Date(search.from) : null,
				search.to ? new Date(search.to) : null,
			),
		[search.from, search.to],
	)

	const shiftDays = (days: number) => {
		const now = new Date()

		if (!dateRange?.from || !dateRange?.to) {
			navigate({
				search: prev => ({
					...prev,
					from: now.toISOString(),
					to: now.toISOString(),
				}),
			})
			return
		}

		const newFrom = addDays(dateRange.from, days)
		const newTo = addDays(dateRange.to, days)

		navigate({
			search: prev => ({
				...prev,
				from: newFrom.toISOString(),
				to: newTo.toISOString(),
			}),
		})
	}

	const shiftRange = (direction: number) => {
		if (!dateRange?.from || !dateRange?.to) {
			const now = new Date()
			navigate({
				search: prev => ({
					...prev,
					from: now.toISOString(),
					to: now.toISOString(),
				}),
			})
			return
		}

		// Check if it's a month range (starts at beginning of month and ends at end of month)
		const isMonthRange =
			isSameDay(dateRange.from, startOfMonth(dateRange.from)) &&
			isSameDay(dateRange.to, endOfMonth(dateRange.from))

		if (isMonthRange) {
			const newStart = addMonths(dateRange.from, direction)
			const newEnd = endOfMonth(newStart)
			navigate({
				search: prev => ({
					...prev,
					from: newStart.toISOString(),
					to: newEnd.toISOString(),
				}),
			})
			return
		}

		// Check if it's a year range
		const isYearRange =
			isSameDay(dateRange.from, startOfYear(dateRange.from)) &&
			isSameDay(dateRange.to, endOfYear(dateRange.from))

		if (isYearRange) {
			const newStart = addYears(dateRange.from, direction)
			const newEnd = endOfMonth(addMonths(newStart, 11))
			navigate({
				search: prev => ({
					...prev,
					from: newStart.toISOString(),
					to: newEnd.toISOString(),
				}),
			})
			return
		}

		// Handle all other ranges (including weeks) using precise day counting
		const rangeDays = differenceInDays(dateRange.to, dateRange.from) + 1
		const jumpSize = direction * rangeDays

		const newFrom = addDays(dateRange.from, jumpSize)
		const newTo = addDays(dateRange.to, jumpSize)

		navigate({
			search: prev => ({
				...prev,
				from: newFrom.toISOString(),
				to: newTo.toISOString(),
			}),
		})
	}

	const goToToday = () => {
		const now = new Date()

		navigate({
			search: prev => ({
				...prev,
				from: now.toISOString(),
				to: now.toISOString(),
				interval: '1h',
			}),
		})
	}

	const goToThisWeek = () => {
		const now = new Date()
		const weekStart = startOfWeek(now, { weekStartsOn: 1 })
		const weekEnd = endOfWeek(now, { weekStartsOn: 1 })
		navigate({
			search: prev => ({
				...prev,
				from: weekStart.toISOString(),
				to: weekEnd.toISOString(),
				interval: '1h',
			}),
		})
	}

	const goToThisMonth = () => {
		const now = new Date()
		const monthStart = startOfMonth(now)
		const monthEnd = endOfMonth(now)
		navigate({
			search: prev => ({
				...prev,
				from: monthStart.toISOString(),
				to: monthEnd.toISOString(),
				interval: '1d',
			}),
		})
	}

	const handleDateRangeChange = (range: DateRange | undefined) => {
		navigate({
			search: prev => ({
				...prev,
				from: range?.from?.toISOString() ?? null,
				to: range?.to?.toISOString() ?? null,
			}),
		})
	}

	const handleIntervalChange = (newInterval: IntervalOption) => {
		navigate({
			search: prev => ({
				...prev,
				interval: newInterval,
			}),
		})
	}

	const handleChartTypeChange = (newChartType: ChartTypeOption) => {
		navigate({
			search: prev => ({
				...prev,
				chartType: newChartType,
			}),
		})
	}

	const handleAggregationTypeChange = (
		newAggregationType: AggregationTypeOption,
	) => {
		navigate({
			search: prev => ({
				...prev,
				aggregationType: newAggregationType,
			}),
		})
	}

	const handleStaffGroupsChange = (selectedGroups: string[]) => {
		navigate({
			search: prev => ({
				...prev,
				staffGroups: selectedGroups,
			}),
		})
	}

	return (
		<>
			<PageLayout>
				<ScrollableToolbar>
					<ScrollableToolbarContentLeft>
						<h1 className="text-xl sm:text-2xl">Staff Demand</h1>
					</ScrollableToolbarContentLeft>
					<ScrollableToolbarContentRight>
						<div className="flex items-center gap-2">
							<Button
								variant="outline"
								onClick={goToToday}
								title="Go to Today"
								disabled={
									dateRange?.from &&
									isSameDay(dateRange.from, today) &&
									(!dateRange.to || isSameDay(dateRange.to, dateRange.from))
								}
							>
								<Calendar1 className="mr-2 h-4 w-4" />
								Today
							</Button>
							<Button
								variant="outline"
								onClick={goToThisWeek}
								title="Go to This Week"
								disabled={
									dateRange?.from &&
									isSameDay(
										dateRange.from,
										startOfWeek(today, { weekStartsOn: 1 }),
									) &&
									dateRange.to &&
									isSameDay(dateRange.to, endOfWeek(today, { weekStartsOn: 1 }))
								}
							>
								<CalendarRange className="mr-2 h-4 w-4" />
								This Week
							</Button>
							<Button
								variant="outline"
								onClick={goToThisMonth}
								title="Go to This Month"
								disabled={
									dateRange?.from &&
									isSameDay(dateRange.from, startOfMonth(today)) &&
									dateRange.to &&
									isSameDay(dateRange.to, endOfMonth(today))
								}
							>
								<CalendarDays className="mr-2 h-4 w-4" />
								This Month
							</Button>
							<div className="flex items-center gap-1">
								<Button
									variant="ghost"
									size="icon"
									className="w-auto px-2"
									onClick={() => shiftRange(-1)}
									title="Previous Range"
									disabled={
										!dateRange?.from ||
										!dateRange?.to ||
										isSameDay(dateRange.from, dateRange.to)
									}
								>
									<ChevronsLeft className="h-4 w-4" />
								</Button>
								<Button
									variant="ghost"
									size="icon"
									className="w-auto px-2"
									onClick={() => shiftDays(-1)}
									title="Previous Day"
								>
									<ChevronLeft className="h-4 w-4" />
								</Button>
								<DateRangePicker
									align="center"
									selected={dateRange}
									onSelect={handleDateRangeChange}
								/>
								<Button
									variant="ghost"
									size="icon"
									className="w-auto px-2"
									onClick={() => shiftDays(1)}
									title="Next Day"
								>
									<ChevronRight className="h-4 w-4" />
								</Button>
								<Button
									variant="ghost"
									size="icon"
									className="w-auto px-2"
									onClick={() => shiftRange(1)}
									title="Next Range"
									disabled={
										!dateRange?.from ||
										!dateRange?.to ||
										isSameDay(dateRange.from, dateRange.to)
									}
								>
									<ChevronsRight className="h-4 w-4" />
								</Button>
							</div>
						</div>
					</ScrollableToolbarContentRight>
				</ScrollableToolbar>
				<div className="py-8">
					<StaffDemandChart
						intervals={staffDemand.intervals}
						staffGroups={allStaffGroups}
						interval={search.interval}
						availableIntervals={availableIntervals}
						chartType={chartType}
						onIntervalChange={handleIntervalChange}
						onChartTypeChange={handleChartTypeChange}
						aggregationType={aggregationType}
						onAggregationTypeChange={handleAggregationTypeChange}
						selectedStaffGroups={selectedStaffGroups}
						onStaffGroupsChange={handleStaffGroupsChange}
					/>
				</div>
			</PageLayout>
		</>
	)
}

export { Route }
