import { forwardRef, useState } from 'react'

import { TMachine, TProductOperation } from '@repo/types'
import {
	Computer,
	Copy,
	MoreVertical,
	Pencil,
	Plus,
	Trash2,
	Users,
	Wrench,
} from 'lucide-react'

import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { cn } from '@/lib/utils'
import { pluralize } from '@/utils/pluralize'
import { stopPropagation } from '@/utils/stop-propagation'

import { getFormattedPhaseDuration } from '../utils/get-formatted-phase-duration'

const ProductOperationCard = forwardRef(function ProductOperationCard(
	props: {
		productOperation: Omit<TProductOperation, 'id' | 'productId'>
		machines: TMachine[]
		onEdit: () => void
		onDuplicate: () => void
		onDelete: () => void
		onCreateBefore: () => void
		onCreateAfter: () => void
		isFirst?: boolean
		isLast?: boolean
	} & Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>,
	ref: React.Ref<HTMLDivElement>,
) {
	const {
		productOperation,
		machines,
		onEdit,
		onDuplicate,
		onDelete,
		onCreateBefore,
		onCreateAfter,
		isFirst,
		isLast,
		className,
	} = props
	const [hover, setHover] = useState(false)
	const formattedBeforePhase = getFormattedPhaseDuration(
		productOperation.phases.before,
	)
	const formattedDuringPhase = getFormattedPhaseDuration(
		productOperation.phases.during[0]?.duration,
		'?',
	)
	const formattedAfterPhase = getFormattedPhaseDuration(
		productOperation.phases.after,
	)

	return (
		<div
			className="relative z-10 px-12 py-4"
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<Button
				type="button"
				onClick={onCreateBefore}
				size="sm"
				className={cn(
					'h-6 w-6 rounded-md p-0',
					'absolute left-2 top-1/2 -translate-y-1/2 transform transition-opacity ease-in',
					isFirst || hover ? 'opacity-100' : 'opacity-0',
				)}
				style={{ visibility: isFirst || hover ? 'visible' : 'hidden' }}
			>
				<Plus className="h-4 w-4" />
			</Button>
			<Button
				type="button"
				onClick={onCreateAfter}
				size="sm"
				className={cn(
					'h-6 w-6 rounded-md p-0',
					'absolute right-2 top-1/2 -translate-y-1/2 transform transition-opacity ease-in',
					isLast || hover ? 'opacity-100' : 'opacity-0',
				)}
				style={{ visibility: isLast || hover ? 'visible' : 'hidden' }}
			>
				<Plus className="h-4 w-4" />
			</Button>
			<Card
				ref={ref}
				className={cn('relative min-h-56 w-60 p-4', className)}
				role="button"
				onClick={onEdit}
			>
				<h2 className="truncate text-lg" title={productOperation.name}>
					{productOperation.name}
				</h2>
				<div className="my-2 flex rounded-lg border">
					{productOperation.phases.before &&
						productOperation.phases.before.quantity > 0 && (
							<div className="flex w-14 flex-col items-center justify-between rounded-lg p-2">
								<span className="text-[10px] uppercase leading-tight text-muted-foreground">
									Before
								</span>
								<span className="text-base">
									{formattedBeforePhase.quantity}
								</span>
								<span className="text-[10px] leading-tight">
									{formattedBeforePhase.unit}
								</span>
							</div>
						)}
					<div className="flex flex-grow flex-col items-center justify-between rounded-lg bg-muted p-2">
						<span className="text-[10px] uppercase leading-tight text-muted-foreground">
							During
						</span>
						<span className="text-xl">{formattedDuringPhase.quantity}</span>
						<span className="text-[10px] leading-tight">
							{formattedDuringPhase.unit}
						</span>
					</div>
					{productOperation.phases.after &&
						productOperation.phases.after.quantity > 0 && (
							<div className="flex w-14 flex-col items-center justify-between rounded-lg p-2">
								<span className="text-[10px] uppercase leading-tight text-muted-foreground">
									After
								</span>
								<span className="text-base">
									{formattedAfterPhase.quantity}
								</span>
								<span className="text-[10px] leading-tight">
									{formattedAfterPhase.unit}
								</span>
							</div>
						)}
				</div>
				<div className="flex flex-wrap gap-2">
					{productOperation.phases.during
						.map(productionPhase =>
							machines.find(
								machine => machine.id === productionPhase.machine.id,
							),
						)
						.filter(machine => machine !== undefined)
						.map(machine => (
							<Badge
								key={machine?.id}
								title={machine?.name}
								variant="outline"
								className="overflow-hidden whitespace-nowrap"
							>
								<Computer className="mr-2 h-4 w-4 min-w-4" />
								<span className="truncate">{machine?.name}</span>
							</Badge>
						))}
					{productOperation.tools.length > 0 && (
						<Badge
							variant="outline"
							className="overflow-hidden whitespace-nowrap"
						>
							<Wrench className="mr-2 h-4 w-4 min-w-4" />
							<span className="truncate">
								{productOperation.tools.length}{' '}
								{pluralize(productOperation.tools.length, 'Tool')}
							</span>
						</Badge>
					)}
					{productOperation.staffGroups.length > 0 && (
						<Badge
							variant="outline"
							className="overflow-hidden whitespace-nowrap"
						>
							<Users className="mr-2 h-4 w-4 min-w-4" />
							<span className="truncate">
								{productOperation.staffGroups.length}{' '}
								{pluralize(productOperation.staffGroups.length, 'Staff Group')}
							</span>
						</Badge>
					)}
				</div>
				<DropdownMenu>
					<DropdownMenuTrigger className="absolute right-2 top-2" asChild>
						<Button variant="ghost" className="h-8 w-8 p-2">
							<MoreVertical className="h-4 w-4 shrink-0" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end">
						<DropdownMenuItem onClick={stopPropagation(onEdit)}>
							<Pencil className="mr-2 h-4 w-4" />
							<span>Edit</span>
						</DropdownMenuItem>
						<DropdownMenuItem onClick={stopPropagation(onDuplicate)}>
							<Copy className="mr-2 h-4 w-4" />
							<span>Duplicate</span>
						</DropdownMenuItem>
						<DropdownMenuItem onClick={stopPropagation(onDelete)}>
							<Trash2 className="mr-2 h-4 w-4" />
							<span>Delete</span>
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			</Card>
		</div>
	)
})

export { ProductOperationCard }
