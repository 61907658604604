import { createId } from '@paralleldrive/cuid2'
import {
	createAsyncThunk,
	createEntityAdapter,
	createSelector,
	createSlice,
	EntityState,
} from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TOperator, TPhasedItemV2 } from '@repo/types'
import { PURGE } from 'redux-persist'
import { toast } from 'sonner'

import type { RootState } from '@/app/store'
import {
	editBooking,
	selectAllBookings,
} from '@/features/planning/planning-slice'

const operatorsAdapter = createEntityAdapter<TOperator>()

export type OperatorsState = EntityState<TOperator, string>
const initialState: OperatorsState = operatorsAdapter.getInitialState()

export const operatorsSlice = createSlice({
	name: 'operators',
	initialState,
	reducers: {
		createOperator: {
			reducer: (state, action: PayloadAction<TOperator>) => {
				const operatorExists = Object.values(state.entities).some(
					(operator): operator is TOperator =>
						operator?.name === action.payload.name,
				)
				if (!operatorExists && action.payload.name !== '') {
					operatorsAdapter.addOne(state, action.payload)
				}
			},
			prepare: (operatorData: Omit<TOperator, 'id'>) => {
				const id = createId()
				return { payload: { ...operatorData, id } }
			},
		},
		editOperator: (state, action: PayloadAction<TOperator>) => {
			operatorsAdapter.updateOne(state, {
				id: action.payload.id,
				changes: action.payload,
			})
		},
		_deleteOperator: (state, action: PayloadAction<string>) => {
			operatorsAdapter.removeOne(state, action.payload)
		},
	},
	extraReducers: builder => {
		builder
			.addCase(PURGE, () => initialState)
			.addCase(deleteOperator.rejected, (_, action) => {
				toast.error((action.payload as string) || 'Failed to delete operator')
			})
	},
})

export const deleteOperator = createAsyncThunk(
	`${operatorsSlice.name}/deleteOperator`,
	async (operatorId: string, { getState, dispatch, rejectWithValue }) => {
		try {
			const state = getState() as RootState
			const bookings = selectAllBookings(state)
			const operator = selectOperatorById(state, operatorId)

			// Validate operator exists
			if (!operator) {
				return rejectWithValue('Operator not found')
			}

			// Find all bookings that need to be updated
			const bookingsToUpdate = bookings.filter(booking =>
				booking.operators?.some(op => op.id === operatorId),
			)

			// Batch update all affected bookings
			bookingsToUpdate.forEach(booking => {
				const updatedOperators =
					booking.operators?.filter(op => op.id !== operatorId) || []
				dispatch(
					editBooking({
						id: booking.id,
						operators: updatedOperators,
					}),
				)
			})

			// Delete the operator itself
			dispatch(operatorsSlice.actions._deleteOperator(operatorId))

			return {
				operatorId,
				operatorName: operator.name,
				affectedBookingIds: bookingsToUpdate.map(b => b.id),
			}
		} catch (error) {
			console.error('Error deleting operator:', error)
			return rejectWithValue(
				error instanceof Error ? error.message : 'Failed to delete operator',
			)
		}
	},
)

export const { createOperator, editOperator } = operatorsSlice.actions

const adapterSelectors = operatorsAdapter.getSelectors<RootState>(
	state => state.operators,
)

// Basic selectors
export const selectOperators = adapterSelectors.selectAll
export const selectOperatorById = adapterSelectors.selectById
export const selectOperatorEntities = adapterSelectors.selectEntities

// Add new memoized selector for populated operators
export const selectPopulatedOperators = createSelector(
	[
		(state: RootState) => state,
		(_state: RootState, operators: TPhasedItemV2[]) => operators,
	],
	(state, operators) =>
		operators
			.map(operator => ({
				...operator,
				...selectOperatorById(state, operator.id),
			}))
			.filter(
				(operator): operator is TPhasedItemV2 & TOperator =>
					operator.name !== undefined,
			),
)

// Add new memoized selector for booking operators
export const selectBookingOperators = createSelector(
	[
		(state: RootState) => state,
		(
			_state: RootState,
			operators: { id: string; phases?: TPhasedItemV2['phases'] }[],
		) => operators,
	],
	(state, operators) =>
		(operators ?? [])
			.filter(operator => selectOperatorById(state, operator.id))
			.map(operator => {
				const operatorData = selectOperatorById(state, operator.id)
				return {
					initials: operatorData?.initials ?? '',
					name: operatorData?.name ?? '',
					phases: operator.phases,
				}
			}),
)

export default operatorsSlice.reducer
