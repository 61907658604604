import React from 'react'

import { TEffectiveTimeMinutes, TPhasedItemV2 } from '@repo/types'

import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'

type Phase = 'before' | 'during' | 'after'
type PhaseConfig = {
	label: string
	key: Phase
}

const PHASES: PhaseConfig[] = [
	{ key: 'before', label: 'S' },
	{ key: 'during', label: 'P' },
	{ key: 'after', label: 'T' },
]

type PhaseIndicatorProps = {
	label: string
	isActive: boolean
	isEnabled: boolean
}

function PhaseIndicator({ label, isActive, isEnabled }: PhaseIndicatorProps) {
	if (!isEnabled) return null

	return (
		<div
			className={cn(
				'flex h-6 w-6 items-center justify-center rounded-md text-xs font-medium text-muted-foreground',
				isActive
					? 'bg-primary text-primary-foreground'
					: 'bg-muted text-muted-foreground',
			)}
		>
			{label}
		</div>
	)
}

type PhaseIndicatorsProps = {
	phases?: TPhasedItemV2['phases']
	effectiveTimeMinutes: TEffectiveTimeMinutes
}

function PhaseIndicators({
	phases,
	effectiveTimeMinutes,
}: PhaseIndicatorsProps) {
	return (
		<div className="flex items-center gap-1">
			{PHASES.map(({ key, label }) => (
				<PhaseIndicator
					key={key}
					label={label}
					isActive={Boolean(phases?.[key])}
					isEnabled={Boolean(effectiveTimeMinutes[key])}
				/>
			))}
		</div>
	)
}

type PhaseDotIndicatorsProps = {
	phases?: TPhasedItemV2['phases']
}

function PhaseDotIndicators({ phases }: PhaseDotIndicatorsProps) {
	if (!phases) return null

	return (
		<div className="absolute -bottom-1 -right-0.5 flex gap-0.5 rounded-full bg-background p-0.5 shadow backdrop-blur-sm dark:bg-background">
			{phases.before && (
				<div className="h-[6px] w-[6px] rounded-full border border-slate-600 bg-background dark:border-slate-600" />
			)}
			{phases.during && (
				<div className="h-[6px] w-[6px] rounded-full bg-slate-600 dark:bg-slate-600" />
			)}
			{phases.after && (
				<div className="h-[6px] w-[6px] rounded-full bg-slate-600/50 dark:bg-slate-600/50" />
			)}
		</div>
	)
}

type OperatorAvatarProps = {
	initials: string
	name: string
	effectiveTimeMinutes: TEffectiveTimeMinutes
	container?: HTMLElement | null
	phases?: TPhasedItemV2['phases']
}

export function OperatorAvatar({
	initials,
	name,
	effectiveTimeMinutes,
	container,
	phases,
}: OperatorAvatarProps) {
	return (
		<div className="relative">
			<Tooltip>
				<TooltipTrigger asChild>
					<Avatar className="h-8 w-8 border border-slate-500 text-xs shadow ring-1 ring-background dark:border-slate-700">
						<AvatarFallback className="select-none bg-slate-600 font-medium text-slate-50 shadow-inner dark:bg-slate-900 dark:text-slate-50">
							{initials}
						</AvatarFallback>
					</Avatar>
				</TooltipTrigger>
				<TooltipContent container={container}>
					<div className="flex items-center gap-2">
						<span>{name}</span>
						<PhaseIndicators
							phases={phases}
							effectiveTimeMinutes={effectiveTimeMinutes}
						/>
					</div>
				</TooltipContent>
			</Tooltip>
			<PhaseDotIndicators phases={phases} />
		</div>
	)
}

type OperatorAvatarGroupProps = {
	operators: Array<{
		initials: string
		name: string
		phases?: TPhasedItemV2['phases']
	}>
	effectiveTimeMinutes: TEffectiveTimeMinutes
	status?: 'active' | 'muted' | 'hidden'
	container?: HTMLElement | null
}

export function OperatorAvatarGroup({
	operators,
	status = 'active',
	effectiveTimeMinutes,
	container,
}: OperatorAvatarGroupProps) {
	const sortedOperators = [...operators].sort((a, b) => {
		const getPhaseScore = (phases?: TPhasedItemV2['phases']) => {
			if (!phases) return 7
			const { before, during, after } = phases
			if (before && !during && !after) return 0 // Setup only
			if (before && during && !after) return 1 // Setup + Production
			if (before && during && after) return 2 // All phases
			if (before && !during && after) return 3 // Setup + Teardown
			if (!before && during && !after) return 4 // Production only
			if (!before && during && after) return 5 // Production + Teardown
			if (!before && !during && after) return 6 // Teardown only
			if (!before && !during && !after) return 7 // No phases
			return 8 // Fallback (shouldn't happen)
		}
		return getPhaseScore(a.phases) - getPhaseScore(b.phases)
	})

	const showAllAvatars = operators.length <= 4
	const visibleOperators = showAllAvatars
		? sortedOperators
		: sortedOperators.slice(0, 3)
	const remainingCount = showAllAvatars ? 0 : operators.length - 3

	if (operators.length === 0) return null

	return (
		<div
			className={cn(
				'flex items-center gap-1',
				status === 'muted' && 'opacity-20',
				status === 'hidden' && 'opacity-0',
			)}
		>
			<div className="flex -space-x-1">
				{visibleOperators.map((operator, index) => (
					<OperatorAvatar
						key={index}
						container={container}
						initials={operator.initials}
						name={operator.name}
						phases={operator.phases}
						effectiveTimeMinutes={effectiveTimeMinutes}
					/>
				))}
			</div>
			{remainingCount > 0 && (
				<Tooltip>
					<TooltipTrigger asChild>
						<div className="flex h-6 w-8 items-center justify-center rounded-md bg-muted text-xs font-medium text-muted-foreground shadow">
							+{remainingCount}
						</div>
					</TooltipTrigger>
					<TooltipContent
						container={container}
						sideOffset={8}
						className="grid grid-cols-[max-content,max-content] gap-x-4 gap-y-2"
					>
						{sortedOperators.slice(3).map((operator, index) => (
							<React.Fragment key={index}>
								<span className="max-w-[12rem] truncate">{operator.name}</span>
								<PhaseIndicators
									phases={operator.phases}
									effectiveTimeMinutes={effectiveTimeMinutes}
								/>
							</React.Fragment>
						))}
					</TooltipContent>
				</Tooltip>
			)}
		</div>
	)
}
