import { TMachine } from '@repo/types'
import { createFileRoute, notFound, useNavigate } from '@tanstack/react-router'
import { toast } from 'sonner'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { store } from '@/app/store'
import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import { useConfirmExitFormDialog } from '@/features/form-dialog/use-confirm-exit-form-dialog'
import { MachineForm } from '@/features/machines/forms/machine-form'
import {
	editMachine,
	selectMachine,
	selectMachineNames,
} from '@/features/machines/machines-slice'

const Route = createFileRoute('/machines/$machineId/edit')({
	component: EditMachineComponent,
	loader: async ({ params: { machineId } }) => {
		const machineSelector = selectMachine(machineId)
		const machine = machineSelector(store.getState())
		if (!machine) {
			throw notFound()
		}
		return { machine }
	},
})

function EditMachineComponent() {
	const { machine } = Route.useLoaderData()
	const { machineId } = Route.useParams()
	const navigate = useNavigate({ from: Route.fullPath })
	const dispatch = useAppDispatch()
	const machineNames = useAppSelector(selectMachineNames)

	const { ConfirmExitAlertDialog, formDialogProps, formProps } =
		useConfirmExitFormDialog<Omit<TMachine, 'id'>>({
			onSubmit: data => {
				// TODO: Try using async thunk for this
				dispatch(editMachine({ id: machineId, ...data }))
				toast.success(`Machine "${data.name}" updated`)
			},
			onClose: () => {
				navigate({
					to: '/machines',
					search: true,
				})
			},
		})

	return (
		<>
			<ConfirmExitAlertDialog />
			<GenericDialog
				title="Edit machine"
				description="Update machine details"
				hideDescription
				{...formDialogProps}
			>
				<MachineForm
					submitButtons={<Button type="submit">Save Machine</Button>}
					initialValues={machine}
					existingNames={machineNames.filter(name => name !== machine.name)}
					{...formProps}
				/>
			</GenericDialog>
		</>
	)
}

export { Route }
