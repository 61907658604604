import { forwardRef } from 'react'

import { TOrder, TProduct } from '@repo/types'
import { format, formatDistance } from 'date-fns'
import {
	ArrowLeftFromLine,
	ArrowRightFromLine,
	Building2,
	CalendarCheck,
	Copy,
	Eraser,
	GanttChart,
	Hash,
	MoreVertical,
	Package,
	PackageOpen,
	Pencil,
	Power,
	PowerOff,
	Timer,
	Trash2,
	X,
} from 'lucide-react'

import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { cn } from '@/lib/utils'
import { formatNumericString } from '@/utils/format-numeric-string'
import { stopPropagation } from '@/utils/stop-propagation'

import { OrderStatusBadge } from './order-status-badge'

const OrderCard = forwardRef(function OrderCard(
	props: {
		order: TOrder<TProduct>
		totalDurationMinutes: number
		onEdit: () => void
		onDuplicate: () => void
		onDelete: () => void
		onShowInPlan?: () => void
		onPlanForward?: () => void
		onPlanBackward?: () => void
		onUnschedule?: () => void
		onDeactivate?: () => void
		onReactivate?: () => void
	},
	ref: React.Ref<HTMLDivElement>,
) {
	const {
		order,
		totalDurationMinutes,
		onEdit,
		onDuplicate,
		onDelete,
		onShowInPlan,
		onPlanForward,
		onPlanBackward,
		onUnschedule,
		onDeactivate,
		onReactivate,
	} = props
	const duration = formatDistance(
		0,
		Math.min(totalDurationMinutes, 5_260_000 /* 10 years in mins */) *
			60 *
			1000,
		{
			includeSeconds: true,
		},
	)

	return (
		<Card
			ref={ref}
			className={cn(
				'relative min-h-40 p-4',
				order.status === 'inactive' && 'opacity-50 transition-opacity',
			)}
			role="button"
			onClick={onEdit}
		>
			<div className="flex flex-col gap-4">
				<div className="flex items-center gap-x-1 gap-y-2">
					<h2 className="truncate text-lg" title={order.productionOrderNumber}>
						#{order.productionOrderNumber}
					</h2>
				</div>
				<div className="flex flex-col items-start gap-x-1 gap-y-2">
					<OrderStatusBadge order={order} />
					{order.product && (
						<div className="flex max-w-full items-center gap-1">
							<Badge
								title={order.product.name}
								variant="secondary"
								className="overflow-hidden whitespace-nowrap"
							>
								<PackageOpen className="mr-2 h-4 w-4 min-w-4" />
								<span className="truncate">{order.product.name}</span>
							</Badge>
						</div>
					)}
					{order.product && (
						<>
							<div className="flex max-w-full items-center gap-1">
								<Badge
									title={order.product.productNumber}
									variant="outline"
									className="overflow-hidden whitespace-nowrap"
								>
									<Package className="mr-2 h-4 w-4 min-w-4" />
									<span className="truncate">
										{order.product.productNumber}
									</span>
								</Badge>
								<X className="h-3 w-3 min-w-3" />
								<Badge
									title={order.quantity.toString()}
									variant="outline"
									className="whitespace-nowrap"
								>
									{formatNumericString(order.quantity)}
								</Badge>
							</div>
						</>
					)}
					<div className="flex max-w-full items-center gap-1">
						<Badge
							variant="outline"
							className="whitespace-nowrap"
							title={format(order.dueDate, 'd MMM yyyy')}
						>
							<CalendarCheck className="mr-2 h-4 w-4 min-w-4" />
							{format(order.dueDate, 'd MMM yyyy')}
						</Badge>
						{order.product && (
							<Badge
								title={duration}
								variant="outline"
								className="whitespace-nowrap"
							>
								<Timer className="mr-2 h-4 w-4 min-w-4" /> {duration}
							</Badge>
						)}
					</div>
					<div className="flex max-w-full items-center gap-1">
						<Badge
							title={order.customerName}
							variant="outline"
							className="overflow-hidden whitespace-nowrap"
						>
							<Building2 className="mr-2 h-4 w-4 min-w-4" />
							<span className="truncate">{order.customerName}</span>
						</Badge>
						<Badge
							title={order.salesOrderNumber}
							variant="secondary"
							className="whitespace-nowrap"
						>
							<Hash className="mr-2 h-4 w-4 min-w-4" />
							{order.salesOrderNumber}
						</Badge>
					</div>
				</div>
			</div>
			<DropdownMenu>
				<DropdownMenuTrigger className="absolute right-2 top-2" asChild>
					<Button variant="ghost" className="h-8 w-8 p-2">
						<MoreVertical className="h-4 w-4 shrink-0" />
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="end">
					{onReactivate && (
						<DropdownMenuItem onClick={stopPropagation(onReactivate)}>
							<Power className="mr-2 h-4 w-4" />
							<span>Reactivate</span>
						</DropdownMenuItem>
					)}
					{onShowInPlan && (
						<DropdownMenuItem onClick={stopPropagation(onShowInPlan)}>
							<GanttChart className="mr-2 h-4 w-4" />
							<span>Show In Plan</span>
						</DropdownMenuItem>
					)}
					{onUnschedule && (
						<DropdownMenuItem onClick={stopPropagation(onUnschedule)}>
							<Eraser className="mr-2 h-4 w-4" />
							<span>Unschedule</span>
						</DropdownMenuItem>
					)}
					{onPlanForward && (
						<DropdownMenuItem onClick={stopPropagation(onPlanForward)}>
							<ArrowRightFromLine className="mr-2 h-4 w-4" />
							<span>Plan Forward</span>
						</DropdownMenuItem>
					)}
					{onPlanBackward && (
						<DropdownMenuItem onClick={stopPropagation(onPlanBackward)}>
							<ArrowLeftFromLine className="mr-2 h-4 w-4" />
							<span>Plan Backward</span>
						</DropdownMenuItem>
					)}
					<DropdownMenuSeparator />
					<DropdownMenuItem onClick={stopPropagation(onEdit)}>
						<Pencil className="mr-2 h-4 w-4" />
						<span>Edit</span>
					</DropdownMenuItem>
					<DropdownMenuItem onClick={stopPropagation(onDuplicate)}>
						<Copy className="mr-2 h-4 w-4" />
						<span>Duplicate</span>
					</DropdownMenuItem>
					{onDeactivate && (
						<DropdownMenuItem onClick={stopPropagation(onDeactivate)}>
							<PowerOff className="mr-2 h-4 w-4" />
							<span>Deactivate</span>
						</DropdownMenuItem>
					)}
					<DropdownMenuItem onClick={stopPropagation(onDelete)}>
						<Trash2 className="mr-2 h-4 w-4" />
						<span>Delete</span>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
		</Card>
	)
})

export { OrderCard }
