import { useCallback, useEffect, useRef, useState } from 'react'

export type ElementVisibility = {
	isVisible: boolean
	isLeftEdgeVisible: boolean
	leftOffset: number
}

const DEFAULT_VISIBILITY: ElementVisibility = {
	isVisible: false,
	isLeftEdgeVisible: false,
	leftOffset: 0,
}

const SCROLL_DEBOUNCE = 150
const REORDER_ANIMATION_DURATION = 250
const POSITION_THRESHOLD = 0.5

/**
 * Hook to track the visibility and position of an element relative to its container.
 * Handles scrolling, resizing, and DOM mutations efficiently.
 *
 * @param elementRef - Reference to the element to track
 * @param containerRef - Reference to the container element
 * @param rowLabelWidth - Width of the row label section
 * @param enabled - Whether the tracking is enabled
 * @returns Current visibility state
 */
export function useElementVisibility(
	elementRef: React.RefObject<HTMLElement>,
	containerRef: React.RefObject<HTMLElement>,
	rowLabelWidth: number,
	enabled = true,
): ElementVisibility {
	const [visibility, setVisibility] =
		useState<ElementVisibility>(DEFAULT_VISIBILITY)
	const rafId = useRef<number>()
	const scrollTimeout = useRef<number>()
	const lastVisibility = useRef(DEFAULT_VISIBILITY)
	const isReordering = useRef(false)
	const isScrolling = useRef(false)

	const calculateVisibility = useCallback(
		(element: HTMLElement, container: HTMLElement): ElementVisibility => {
			const containerRect = container.getBoundingClientRect()
			const elementRect = element.getBoundingClientRect()

			const isVisible =
				elementRect.left < containerRect.right &&
				elementRect.right > containerRect.left + rowLabelWidth

			const isLeftEdgeVisible =
				elementRect.left >= containerRect.left + rowLabelWidth
			const leftOffset = isLeftEdgeVisible
				? 0
				: containerRect.left + rowLabelWidth - elementRect.left

			return { isVisible, isLeftEdgeVisible, leftOffset }
		},
		[rowLabelWidth],
	)

	const checkVisibility = useCallback(() => {
		if (isScrolling.current) return
		if (!elementRef.current || !containerRef.current) return

		const element = elementRef.current
		const container = containerRef.current
		const newVisibility = calculateVisibility(element, container)

		const hasChanged =
			newVisibility.isVisible !== lastVisibility.current.isVisible ||
			newVisibility.isLeftEdgeVisible !==
				lastVisibility.current.isLeftEdgeVisible ||
			Math.abs(newVisibility.leftOffset - lastVisibility.current.leftOffset) >
				POSITION_THRESHOLD

		if (hasChanged) {
			lastVisibility.current = newVisibility
			setVisibility(newVisibility)
		}
	}, [calculateVisibility, containerRef, elementRef])

	const scheduleCheck = useCallback(
		(immediate = false) => {
			if (rafId.current) cancelAnimationFrame(rafId.current)

			if (immediate) {
				rafId.current = requestAnimationFrame(checkVisibility)
			} else {
				rafId.current = requestAnimationFrame(() => {
					checkVisibility()
					rafId.current = requestAnimationFrame(checkVisibility)
				})
			}
		},
		[checkVisibility],
	)

	useEffect(() => {
		if (!enabled) return

		const element = elementRef.current
		const container = containerRef.current
		if (!element || !container) return

		// Set up intersection observer
		const intersectionObserver = new IntersectionObserver(
			() => scheduleCheck(true),
			{ root: container, rootMargin: '0px -20% 0px -20%', threshold: 0 },
		)

		// Set up mutation observer for reordering detection
		const mutationObserver = new MutationObserver(() => {
			if (!isReordering.current) {
				isReordering.current = true
				setTimeout(() => {
					isReordering.current = false
					scheduleCheck(true)
				}, REORDER_ANIMATION_DURATION)
			}
		})

		// Set up resize observer
		const resizeObserver = new ResizeObserver(() => scheduleCheck())

		// Set up scroll listener
		const handleScroll = () => {
			isScrolling.current = true
			if (scrollTimeout.current) clearTimeout(scrollTimeout.current)
			scrollTimeout.current = window.setTimeout(() => {
				isScrolling.current = false
				checkVisibility()
			}, SCROLL_DEBOUNCE)
		}

		intersectionObserver.observe(element)
		mutationObserver.observe(container, { childList: true, subtree: true })
		resizeObserver.observe(container)
		container.addEventListener('scroll', handleScroll)

		// Initial check
		scheduleCheck(true)

		return () => {
			intersectionObserver.disconnect()
			mutationObserver.disconnect()
			resizeObserver.disconnect()
			container.removeEventListener('scroll', handleScroll)
			if (rafId.current) cancelAnimationFrame(rafId.current)
			if (scrollTimeout.current) clearTimeout(scrollTimeout.current)
		}
	}, [enabled, checkVisibility, scheduleCheck, containerRef, elementRef])

	return visibility
}
