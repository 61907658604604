import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TPhasedItem } from '@repo/types'
import {
	createMigrate,
	FLUSH,
	PAUSE,
	PERSIST,
	PersistedState,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	REHYDRATE,
} from 'redux-persist'

import customersReducer from '@/features/customers/customers-slice'
import machinesReducer from '@/features/machines/machines-slice'
import operatorsReducer from '@/features/operators/operators-slice'
import ordersReducer from '@/features/orders/orders-slice'
import planningReducer from '@/features/planning/planning-slice'
import { createAlertHash } from '@/features/planning/utils/create-alert-hash'
import productOperationsReducer from '@/features/products/product-operations-slice'
import productsReducer from '@/features/products/products-slice'
import staffGroupsReducer from '@/features/staff-groups/staff-groups-slice'
import toolsReducer from '@/features/tools/tools-slice'

import { indexedDbStorage } from './storage/indexeddb'
import { crossTabSync } from './sync/cross-tab-sync'

const rootReducer = combineReducers({
	machines: machinesReducer,
	products: productsReducer,
	productOperations: productOperationsReducer,
	tools: toolsReducer,
	staffGroups: staffGroupsReducer,
	orders: ordersReducer,
	customers: customersReducer,
	planning: planningReducer,
	operators: operatorsReducer,
})

const migrations = {
	2: (state: PersistedState): PersistedState => {
		let newState = { ...state } as RootState & PersistedState

		newState.planning = {
			...newState.planning,
			schedulingDirection: newState.planning.schedulingDirection ?? 'forward',
			zoomLevel: newState.planning.zoomLevel ?? '24h',
			bookings: newState.planning.bookings ?? [],
			// @ts-expect-error migration
			overlaps: newState.planning.overlaps ?? [],
			calendarAdjustments: newState.planning.calendarAdjustments ?? [],
		}

		newState = {
			...newState,
			orders: {
				...newState.orders,
				// @ts-expect-error migration
				list: newState.orders.list.map(order => {
					const updatedOrder = { ...order }
					if ('plannedPeriod' in updatedOrder) {
						updatedOrder.plannedPeriod = {
							...updatedOrder.plannedPeriod,
							startDate:
								updatedOrder.plannedPeriod.start ??
								updatedOrder.plannedPeriod.startDate,
							endDate:
								updatedOrder.plannedPeriod.end ??
								updatedOrder.plannedPeriod.endDate,
						}
						delete updatedOrder.plannedPeriod.start
						delete updatedOrder.plannedPeriod.end
					}
					return updatedOrder
				}),
			},
			planning: {
				...newState.planning,
				bookings: newState.planning.bookings.map(booking => {
					const updatedBooking = { ...booking }
					updatedBooking.phases = {
						...updatedBooking.phases,
						before: {
							...updatedBooking.phases.before,
							startDate:
								// @ts-expect-error migration
								updatedBooking.phases.before.start ??
								updatedBooking.phases.before.startDate,
							endDate:
								// @ts-expect-error migration
								updatedBooking.phases.before.end ??
								updatedBooking.phases.before.endDate,
						},
						during: {
							...updatedBooking.phases.during,
							startDate:
								// @ts-expect-error migration
								updatedBooking.phases.during.start ??
								updatedBooking.phases.during.startDate,
							endDate:
								// @ts-expect-error migration
								updatedBooking.phases.during.end ??
								updatedBooking.phases.during.endDate,
						},
						after: {
							...updatedBooking.phases.after,
							startDate:
								// @ts-expect-error migration
								updatedBooking.phases.after.start ??
								updatedBooking.phases.after.startDate,
							endDate:
								// @ts-expect-error migration
								updatedBooking.phases.after.end ??
								updatedBooking.phases.after.endDate,
						},
					}
					// @ts-expect-error migration
					delete updatedBooking.phases.before.start
					// @ts-expect-error migration
					delete updatedBooking.phases.before.end
					// @ts-expect-error migration
					delete updatedBooking.phases.during.start
					// @ts-expect-error migration
					delete updatedBooking.phases.during.end
					// @ts-expect-error migration
					delete updatedBooking.phases.after.start
					// @ts-expect-error migration
					delete updatedBooking.phases.after.end

					if ('progress' in updatedBooking) {
						if ('before' in updatedBooking.progress) {
							// @ts-expect-error migration
							if ('start' in updatedBooking.progress.before) {
								// @ts-expect-error migration
								updatedBooking.progress.before.startDate =
									updatedBooking.progress.before.start ??
									updatedBooking.progress.before.startDate
							}
							// @ts-expect-error migration
							if ('end' in updatedBooking.progress.before) {
								// @ts-expect-error migration
								updatedBooking.progress.before.endDate =
									updatedBooking.progress.before.end ??
									updatedBooking.progress.before.endDate
							}
							// @ts-expect-error migration
							delete updatedBooking.progress.before.start
							// @ts-expect-error migration
							delete updatedBooking.progress.before.end
						}
						if ('during' in updatedBooking.progress) {
							if ('start' in updatedBooking.progress.during) {
								// @ts-expect-error migration
								updatedBooking.progress.during.startDate =
									updatedBooking.progress.during.start ??
									updatedBooking.progress.during.startDate
							}
							if ('end' in updatedBooking.progress.during) {
								// @ts-expect-error migration
								updatedBooking.progress.during.endDate =
									updatedBooking.progress.during.end ??
									updatedBooking.progress.during.endDate
							}
							// @ts-expect-error migration
							delete updatedBooking.progress.during.start
							// @ts-expect-error migration
							delete updatedBooking.progress.during.end
						}
						if ('after' in updatedBooking.progress) {
							// @ts-expect-error migration
							if ('start' in updatedBooking.progress.after) {
								// @ts-expect-error migration
								updatedBooking.progress.after.startDate =
									updatedBooking.progress.after.start ??
									updatedBooking.progress.after.startDate
							}
							// @ts-expect-error migration
							if ('end' in updatedBooking.progress.after) {
								// @ts-expect-error migration
								updatedBooking.progress.after.endDate =
									updatedBooking.progress.after.end ??
									updatedBooking.progress.after.endDate
							}
							// @ts-expect-error migration
							delete updatedBooking.progress.after.start
							// @ts-expect-error migration
							delete updatedBooking.progress.after.end
						}
					}

					return updatedBooking
				}),
			},
		}

		return newState
	},
	3: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		newState.productOperations.list = newState.productOperations.list.map(
			productOperation => {
				const updatedProductOperation = {
					...productOperation,
					phases: {
						...productOperation.phases,
						// @ts-expect-error migration
						during: productOperation.machines.map(machine => ({
							machine,
							duration: productOperation.phases.during,
						})),
					},
				}

				// @ts-expect-error migration
				delete updatedProductOperation.machines

				return updatedProductOperation
			},
		)

		// @ts-expect-error migration
		newState.orders.list = newState.orders.list.map(order => {
			let updatedOrder = order
			if ('planningParameters' in order) {
				updatedOrder = { ...order }
				updatedOrder.planningParameters = {
					...updatedOrder.planningParameters,
					operations: updatedOrder.planningParameters.operations.map(
						// @ts-expect-error migration
						(operation, index) => ({
							...operation,
							phases: {
								...operation.phases,
								// @ts-expect-error migration
								during: operation.machines.map(machine => ({
									machine: { id: machine.id },
									duration:
										order.planningParameters.operations[index].phases.during,
								})),
							},
						}),
					),
				}
			}
			return updatedOrder
		})

		return newState
	},
	4: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		newState.planning.alerts = []

		// @ts-expect-error migration
		delete newState.planning.overlaps

		return newState
	},
	5: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		newState.planning.ignoredAlerts = {
			ids: [],
			entities: {},
		}

		newState.planning.alerts = newState.planning.alerts.map(alert => {
			// @ts-expect-error migration
			delete alert.status
			return {
				...alert,
				hash: createAlertHash(alert),
			}
		})

		return newState
	},
	6: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		// Transform the orders structure
		const transformedOrders = {
			// @ts-expect-error migration
			ids: newState.orders.list.map(order => order.id),
			// @ts-expect-error migration
			entities: newState.orders.list.reduce(
				// @ts-expect-error migration
				(acc, order) => {
					const updatedOrder = {
						...order,
						product: { id: order.productId }, // Transform productId to product object
					}

					// Remove the old productId field
					delete updatedOrder.productId

					acc[order.id] = updatedOrder
					return acc
				},
				{} as Record<string, unknown>,
			),
		}

		// Replace the old orders structure with the new one
		newState.orders = transformedOrders

		return newState
	},
	7: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		newState.planning.bookings = newState.planning.bookings.map(booking => {
			const order = newState.orders.entities[booking.orderId]
			let tools: TPhasedItem[] = []

			if (order && 'planningParameters' in order) {
				const operation = order.planningParameters.operations.find(
					op => op.id === booking.operationId,
				)
				if (operation) {
					tools = operation.tools
				}
			}

			return {
				...booking,
				tools,
			}
		})

		return newState
	},
	8: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		newState.planning.bookings = newState.planning.bookings.map(booking => {
			const order = newState.orders.entities[booking.orderId]
			let staffGroups: TPhasedItem[] = []

			if (order && 'planningParameters' in order) {
				const operation = order.planningParameters.operations.find(
					op => op.id === booking.operationId,
				)
				if (operation) {
					staffGroups = operation.staffGroups
				}
			}

			return {
				...booking,
				staffGroups,
			}
		})

		return newState
	},
	9: (state: PersistedState): PersistedState => {
		const newState = { ...state } as RootState & PersistedState

		newState.planning.bookings = newState.planning.bookings.map(booking => {
			if (!booking.operators) {
				return {
					...booking,
					operators: [],
				}
			}
			return booking
		})

		return newState
	},
}

export const storage = indexedDbStorage('polly-planning')

const persistConfig = {
	key: 'root',
	version: 9,
	storage,
	migrate: createMigrate(migrations, {
		debug: process.env.NODE_ENV === 'development',
	}),
}

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(
	persistConfig,
	rootReducer,
)

export const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(crossTabSync),
})

export const persistor = persistStore(store)

export type AppStore = typeof store
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
