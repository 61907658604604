import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react'

type FullScreenState = {
	isFullScreenSupported: boolean
	isFullScreen: boolean
	toggleFullScreen: () => void
	fullScreenRef: React.RefObject<HTMLElement>
	fullScreenContainer: HTMLElement | null
}

const FullScreenStateContext = createContext<FullScreenState | undefined>(
	undefined,
)

function FullScreenProvider({ children }: { children: ReactNode }) {
	const [isFullScreen, setIsFullScreen] = useState(false)
	const fullScreenRef = useRef<HTMLElement>(null)
	const isFullScreenSupported = Boolean(document.fullscreenEnabled)
	const [fullScreenContainer, setFullScreenContainer] =
		useState<HTMLElement | null>(null)

	function toggleFullScreen() {
		if (!document.fullscreenElement) {
			fullScreenRef.current?.requestFullscreen()
		} else if (document.exitFullscreen) {
			document.exitFullscreen()
		}
	}

	useEffect(() => {
		const syncFullScreenStatus = () => {
			setIsFullScreen(Boolean(document.fullscreenElement))
		}

		syncFullScreenStatus()
		document.addEventListener('fullscreenchange', syncFullScreenStatus)
		return () => {
			document.removeEventListener('fullscreenchange', syncFullScreenStatus)
		}
	}, [])

	useEffect(() => {
		setFullScreenContainer(isFullScreen ? fullScreenRef.current : null)
	}, [isFullScreen])

	const value: FullScreenState = {
		isFullScreenSupported,
		isFullScreen,
		toggleFullScreen,
		fullScreenRef,
		fullScreenContainer,
	}

	return (
		<FullScreenStateContext.Provider value={value}>
			{children}
		</FullScreenStateContext.Provider>
	)
}

function useFullScreen() {
	const context = useContext(FullScreenStateContext)

	if (context === undefined) {
		throw new Error(
			`${useFullScreen.name} must be used within a ${FullScreenProvider.name}`,
		)
	}
	return context
}

export { useFullScreen, FullScreenProvider }
