import { zodResolver } from '@hookform/resolvers/zod'
import { TMachineBooking } from '@repo/types'
import {
	ArrowLeftRight,
	Clock,
	Hash,
	MoveHorizontal,
	MoveLeft,
	MoveRight,
} from 'lucide-react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '@/components/ui/card'
import { DialogFooter } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'

import { useOrderPlanner } from '../hooks/use-order-planner'

const timeUnitSchema = z.enum(['minutes', 'hours', 'days'])

const alignmentSchema = z.enum(['push', 'align', 'pull'])

const formSchema = z.object({
	alignment: alignmentSchema,
	gap: z.object({
		enabled: z.boolean(),
		value: z.coerce
			.number()
			.int('Must be a whole number')
			.min(0, 'Must be 0 or greater')
			.max(1000, 'Must be 1000 or less'),
		unit: timeUnitSchema,
	}),
	window: z.object({
		enabled: z.boolean(),
		start: z.string().regex(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, {
			message: 'Use 24h format (HH:mm)',
		}),
		end: z.string().regex(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, {
			message: 'Use 24h format (HH:mm)',
		}),
	}),
	scope: z.object({
		enabled: z.boolean(),
		limit: z.coerce
			.number()
			.int('Must be a whole number')
			.min(1, 'Must be at least 1')
			.max(100, 'Must be 100 or less'),
	}),
})

type FormValues = z.infer<typeof formSchema>

const alignmentDescriptions = {
	push: 'Prevents overlaps by pushing bookings apart. If a gap is specified, ensures bookings are at least that far apart.',
	align:
		'Arranges bookings to have exact gaps between them - either zero gap or a specified gap size.',
	pull: 'Brings spread out bookings closer together by eliminating gaps or maintaining a specified minimum gap.',
}

export function AlignBookingsDialog(props: {
	booking: TMachineBooking
	container?: HTMLElement | null
	onClose: () => void
	onSubmit?: (values: FormValues) => void
}) {
	const { booking, container, onClose, onSubmit } = props
	const { alignBookings } = useOrderPlanner()

	const form = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			alignment: 'push',
			gap: {
				enabled: false,
				value: 15,
				unit: 'minutes',
			},
			window: {
				enabled: false,
				start: '08:00',
				end: '16:00',
			},
			scope: {
				enabled: false,
				limit: 5,
			},
		},
	})

	const handleSubmit = form.handleSubmit(data => {
		alignBookings({
			booking,
			gap: data.gap.enabled
				? { quantity: data.gap.value, unit: data.gap.unit }
				: undefined,
			startTimeWindow: data.window.enabled
				? {
						from: data.window.start,
						to: data.window.end,
					}
				: undefined,
			limit: data.scope.enabled ? data.scope.limit : undefined,
			pull: data.alignment === 'align' || data.alignment === 'pull',
			push: data.alignment === 'align' || data.alignment === 'push',
		})

		toast.success('Bookings aligned successfully')
		onSubmit?.(data)
		onClose()
	})

	return (
		<GenericDialog
			title="Align bookings"
			description="Automatically align subsequent bookings with consistent spacing"
			container={container}
			onClose={onClose}
		>
			<form onSubmit={handleSubmit} className="space-y-6">
				<div className="space-y-4">
					<div>
						<Label className="text-sm font-medium">Alignment Behavior</Label>
						<Controller
							name="alignment"
							control={form.control}
							render={({ field }) => (
								<div className="mt-2 space-y-2">
									<div className="grid grid-cols-3 gap-2">
										{['push', 'align', 'pull'].map(value => (
											<Button
												key={value}
												type="button"
												variant={field.value === value ? 'default' : 'outline'}
												className="w-full"
												onClick={() => field.onChange(value)}
											>
												<div className="flex items-center gap-2">
													{value.charAt(0).toUpperCase() + value.slice(1)}
													{value === 'push' && (
														<MoveRight className="h-4 w-4" />
													)}
													{value === 'align' && (
														<MoveHorizontal className="h-4 w-4" />
													)}
													{value === 'pull' && <MoveLeft className="h-4 w-4" />}
												</div>
											</Button>
										))}
									</div>
									<p className="text-sm text-muted-foreground">
										{
											alignmentDescriptions[
												field.value as keyof typeof alignmentDescriptions
											]
										}
									</p>
								</div>
							)}
						/>
					</div>

					<Card className="p-4">
						<CardHeader className="flex flex-row items-center justify-between space-y-0 p-0">
							<div className="space-y-1">
								<CardTitle className="text-sm font-medium">
									<div className="flex items-center gap-2">
										<ArrowLeftRight className="h-4 w-4" />
										Gap
									</div>
								</CardTitle>
								<CardDescription>
									Define spacing between bookings
								</CardDescription>
							</div>
							<Controller
								name="gap.enabled"
								control={form.control}
								render={({ field }) => (
									<Switch
										checked={field.value}
										onCheckedChange={field.onChange}
									/>
								)}
							/>
						</CardHeader>
						<CardContent className="p-0">
							{form.watch('gap.enabled') && (
								<div className="mt-2 grid grid-cols-2 gap-2">
									<div className="space-y-2">
										<Controller
											name="gap.value"
											control={form.control}
											render={({ field }) => (
												<Input
													className="text-right"
													type="number"
													placeholder="15"
													{...field}
													onChange={e => field.onChange(e.target.valueAsNumber)}
												/>
											)}
										/>
										{form.formState.errors.gap?.value && (
											<p className="text-xs text-destructive">
												{form.formState.errors.gap.value.message}
											</p>
										)}
									</div>
									<Controller
										name="gap.unit"
										control={form.control}
										render={({ field }) => (
											<Select
												value={field.value}
												onValueChange={field.onChange}
											>
												<SelectTrigger>
													<SelectValue />
												</SelectTrigger>
												<SelectContent>
													<SelectItem value="minutes">minutes</SelectItem>
													<SelectItem value="hours">hours</SelectItem>
													<SelectItem value="days">days</SelectItem>
												</SelectContent>
											</Select>
										)}
									/>
								</div>
							)}
						</CardContent>
					</Card>

					<Card className="p-4">
						<CardHeader className="flex flex-row items-center justify-between space-y-0 p-0">
							<div className="space-y-1">
								<CardTitle className="text-sm font-medium">
									<div className="flex items-center gap-2">
										<Clock className="h-4 w-4" />
										Start Time Window
									</div>
								</CardTitle>
								<CardDescription>
									Restrict start times to a daily time window
								</CardDescription>
							</div>
							<Controller
								name="window.enabled"
								control={form.control}
								render={({ field }) => (
									<Switch
										checked={field.value}
										onCheckedChange={field.onChange}
									/>
								)}
							/>
						</CardHeader>
						<CardContent className="p-0">
							{form.watch('window.enabled') && (
								<div className="mt-2 grid grid-cols-2 gap-4">
									<div className="space-y-1">
										<Label className="text-sm">From</Label>
										<Controller
											name="window.start"
											control={form.control}
											render={({ field }) => <Input type="time" {...field} />}
										/>
										{form.formState.errors.window?.start && (
											<p className="text-xs text-destructive">
												{form.formState.errors.window.start.message}
											</p>
										)}
									</div>
									<div className="space-y-1">
										<Label className="text-sm">To</Label>
										<Controller
											name="window.end"
											control={form.control}
											render={({ field }) => <Input type="time" {...field} />}
										/>
										{form.formState.errors.window?.end && (
											<p className="text-xs text-destructive">
												{form.formState.errors.window.end.message}
											</p>
										)}
									</div>
								</div>
							)}
						</CardContent>
					</Card>

					<Card className="p-4">
						<CardHeader className="flex flex-row items-center justify-between space-y-0 p-0">
							<div className="space-y-1">
								<CardTitle className="text-sm font-medium">
									<div className="flex items-center gap-2">
										<Hash className="h-4 w-4" />
										Booking Limit
									</div>
								</CardTitle>
								<CardDescription>
									Restrict the number of affected bookings
								</CardDescription>
							</div>
							<Controller
								name="scope.enabled"
								control={form.control}
								render={({ field }) => (
									<Switch
										checked={field.value}
										onCheckedChange={field.onChange}
									/>
								)}
							/>
						</CardHeader>
						<CardContent className="p-0">
							{form.watch('scope.enabled') && (
								<div className="mt-2 space-y-2">
									<Controller
										name="scope.limit"
										control={form.control}
										render={({ field }) => (
											<div className="relative">
												<Input
													type="number"
													placeholder="5"
													className="pr-20"
													{...field}
													onChange={e => field.onChange(e.target.valueAsNumber)}
												/>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
													<span className="text-sm text-muted-foreground">
														bookings
													</span>
												</div>
											</div>
										)}
									/>
									{form.formState.errors.scope?.limit && (
										<p className="text-xs text-destructive">
											{form.formState.errors.scope.limit.message}
										</p>
									)}
								</div>
							)}
						</CardContent>
					</Card>
				</div>

				<DialogFooter>
					<Button type="button" variant="outline" onClick={onClose}>
						Cancel
					</Button>
					<Button type="submit">Align Bookings</Button>
				</DialogFooter>
			</form>
		</GenericDialog>
	)
}
