import { createId } from '@paralleldrive/cuid2'
import { format, isBefore, min } from 'date-fns'

import { TPlanValidator } from './types'

const validateEarly: TPlanValidator = plan => {
	const alerts: ReturnType<TPlanValidator> = []

	plan.bookings.forEach(booking => {
		if (booking.status === 'completed') return // TODO: Add test case for this

		const order = plan.orders.find(order => order.id === booking.orderId)

		if (order && isBefore(booking.startDate, order.earliestStartDate)) {
			alerts.push({
				id: createId(),
				category: 'early',
				orders: [{ id: order.id }],
				bookings: [{ id: booking.id }],
				startDate: booking.startDate,
				endDate: min([booking.endDate, order.earliestStartDate]).toISOString(),
				suggestion: `Should start no earlier than ${format(
					order.earliestStartDate,
					'd MMM, HH:mm',
				)}.`,
			})
		}
	})

	return alerts
}

export { validateEarly }
