import { Fragment, useEffect, useMemo, useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { TMachine, TProduct, TProductOperation } from '@repo/types'
import { Blend, Link, Plus, Timer, Unlink } from 'lucide-react'
import {
	FieldErrors,
	SubmitHandler,
	useFieldArray,
	useForm,
	type UseFormHandleSubmit,
} from 'react-hook-form'
import { toast } from 'sonner'

import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Separator } from '@/components/ui/separator'
import { useConfirmExitFormDialog } from '@/features/form-dialog/use-confirm-exit-form-dialog'
import { TStaffGroup } from '@/features/staff-groups/staff-groups-slice'
import { TTool } from '@/features/tools/tools-slice'
import { useUniqueName } from '@/hooks/use-unique-name'
import { cn } from '@/lib/utils'

import { ProductOperationCard } from '../components/product-operation-card'
import { ProductOperationForm } from '../forms/product-operation-form'
import { ProductOperationTransitionForm } from './product-operation-transition-form'
import { productSchema, type ProductSchema } from './product-schema'

type SubmitButtonsRenderer =
	| ((props: {
			handleSubmit: UseFormHandleSubmit<ProductSchema>
	  }) => React.ReactNode)
	| React.ReactNode

function ProductForm(props: {
	submitButtons: SubmitButtonsRenderer
	onSubmit: SubmitHandler<ProductSchema>
	onError?: (errors: FieldErrors<ProductSchema>) => void
	machines: TMachine[]
	tools: TTool[]
	staffGroups: TStaffGroup[]
	existingNames: string[]
	existingProductNumbers: string[]
	onOperationChanged: (
		operation: Omit<TProductOperation, 'id' | 'productId'>,
	) => void
	onUnsavedChanges?: (hasUnsavedChanges: boolean) => void
	initialValues?: Omit<
		TProduct<Omit<TProductOperation, 'id' | 'productId'>>,
		'id'
	>
}) {
	const { onUnsavedChanges } = props

	const schema = useMemo(() => {
		return productSchema.refine(
			data => !props.existingProductNumbers.includes(data.productNumber),
			data => ({
				message: `Product number "${data.productNumber}" already exists`,
				path: ['productNumber'],
			}),
		)
	}, [props.existingProductNumbers])
	const {
		register,
		handleSubmit,
		watch,
		control,
		formState: { errors, isDirty },
	} = useForm<ProductSchema>({
		resolver: zodResolver(schema),
		defaultValues: props.initialValues,
	})

	useEffect(() => {
		onUnsavedChanges?.(isDirty)
	}, [isDirty, onUnsavedChanges])

	const { fields, insert, remove, update } = useFieldArray({
		name: 'operations',
		control,
	})

	const [operationBeingCreatedAtIndex, setOperationBeingCreatedAtIndex] =
		useState<number | undefined>()
	const [operationBeingEdited, setOperationBeingEdited] = useState<
		| { data: Omit<TProductOperation, 'id' | 'productId'>; index: number }
		| undefined
	>()
	const [operationTransitionBeingEdited, setOperationTransitionBeingEdited] =
		useState<
			| { data: Omit<TProductOperation, 'id' | 'productId'>; index: number }
			| undefined
		>()

	const createProductOperationFormDialog = useConfirmExitFormDialog<
		Omit<TProductOperation, 'id' | 'productId'>
	>({
		open: operationBeingCreatedAtIndex !== undefined,
		onSubmit: data => {
			if (operationBeingCreatedAtIndex === undefined) {
				toast.error('Operation could not be added in the desired location')
				return
			} else {
				insert(operationBeingCreatedAtIndex, data)
				setOperationBeingCreatedAtIndex(undefined)
				toast.success(`Operation "${data.name}" added`)
				props.onOperationChanged?.(data)
			}
		},
		onClose: () => {
			setOperationBeingCreatedAtIndex(undefined)
		},
	})

	const editProductOperationFormDialog = useConfirmExitFormDialog<
		Omit<TProductOperation, 'id' | 'productId'>
	>({
		open: operationBeingEdited !== undefined,
		onSubmit: data => {
			if (operationBeingEdited === undefined) {
				toast.error('Operation not found')
				return
			} else {
				update(operationBeingEdited.index, data)
				setOperationBeingEdited(undefined)
				toast.success(`Operation "${data.name}" updated`)
				props.onOperationChanged?.(data)
			}
		},
		onClose: () => {
			setOperationBeingEdited(undefined)
		},
	})

	const editProductOperationTransitionFormDialog = useConfirmExitFormDialog<
		Omit<TProductOperation, 'id' | 'productId'>
	>({
		open: operationTransitionBeingEdited !== undefined,
		onSubmit: data => {
			if (operationTransitionBeingEdited === undefined) {
				toast.error('Operation not found')
				return
			} else {
				update(operationTransitionBeingEdited.index, data)
				setOperationTransitionBeingEdited(undefined)
				toast.success(`Transition for operation "${data.name}" updated`)
				props.onOperationChanged?.(data)
			}
		},
		onClose: () => {
			setOperationTransitionBeingEdited(undefined)
		},
	})

	const productOperationNames = watch('operations').map(
		operation => operation.name,
	)
	const { getUniqueName } = useUniqueName(productOperationNames)

	const [parent] = useAutoAnimate()

	return (
		<>
			<createProductOperationFormDialog.ConfirmExitAlertDialog />
			<editProductOperationFormDialog.ConfirmExitAlertDialog />
			<editProductOperationTransitionFormDialog.ConfirmExitAlertDialog />
			<form
				onSubmit={handleSubmit(props.onSubmit, props.onError)}
				className="flex flex-grow flex-col gap-4"
				autoComplete="off"
			>
				<div className="grid w-full grid-cols-[minmax(0,_320px)_minmax(0,_320px)_1fr] items-end gap-x-4 gap-y-2">
					<Label htmlFor="name">Name</Label>
					<Label htmlFor="productNumber">Product Number</Label>
					<div className="row-span-2 justify-self-end">
						{typeof props.submitButtons === 'function'
							? props.submitButtons({ handleSubmit })
							: props.submitButtons}
					</div>
					<Input
						id="name"
						className="max-w-80"
						error={Boolean(errors.name)}
						{...register('name', { required: true })}
					/>
					<Input
						id="productNumber"
						className="max-w-80"
						error={Boolean(errors.productNumber)}
						{...register('productNumber', { required: true })}
					/>
					{errors.name && (
						<span className="-mt-1 text-xs text-destructive">
							{errors.name.message}
						</span>
					)}
					{errors.productNumber && (
						<span className="-mt-1 text-xs text-destructive">
							{errors.productNumber.message}
						</span>
					)}
				</div>
				<Separator />
				<div
					ref={parent}
					className={cn(
						'flex flex-grow items-center gap-10 overflow-x-auto rounded-lg bg-muted p-4 text-muted-foreground',
						Boolean(errors.operations) && 'border-2 border-destructive',
					)}
				>
					{fields.length === 0 && (
						<div className="flex w-full flex-col items-center justify-center gap-4">
							<h3 className="text-center">How is this product manufactured?</h3>
							<p className="text-center text-xs">
								Add operations to map your product&apos;s route through its
								production lifecycle.
								<br /> Detail the resources and production times required for
								each step.
							</p>
							<Button
								type="button"
								onClick={() => setOperationBeingCreatedAtIndex(0)}
								size="lg"
							>
								<Plus className="mr-2 h-4 w-4" />
								Add Starting Operation
							</Button>
						</div>
					)}
					{fields.map((field, index) => (
						<Fragment key={field.id}>
							<div className="relative">
								<ProductOperationCard
									productOperation={field}
									machines={props.machines}
									className={cn(
										Boolean(errors.operations?.[index]) &&
											'border-2 border-destructive',
									)}
									onEdit={() => setOperationBeingEdited({ data: field, index })}
									onDelete={() => remove(index)}
									onDuplicate={() =>
										insert(index + 1, {
											...field,
											name: getUniqueName(field.name),
										})
									}
									onCreateBefore={() => setOperationBeingCreatedAtIndex(index)}
									onCreateAfter={() =>
										setOperationBeingCreatedAtIndex(index + 1)
									}
									isFirst={index === 0}
									isLast={index === fields.length - 1}
								/>
								{index < fields.length - 1 && (
									<div className="absolute right-0 top-1/2 w-[136px] -translate-y-1/2 translate-x-[88px] transform">
										<div className="relative flex w-full items-center justify-center">
											<svg
												className="absolute h-[24px] w-[136px] text-slate-400 dark:text-slate-500"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 136 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M136 12m0 0H0"
												/>
												<polygon
													fill="currentColor"
													points="130,6 136,12 130,18"
												/>
											</svg>
											<Card
												className="z-10 flex flex-col items-center gap-2 p-2 hover:border-foreground hover:bg-card/80"
												role="button"
												onClick={() =>
													setOperationTransitionBeingEdited({
														data: field,
														index,
													})
												}
											>
												{!field.transition ||
													(field.transition?.kind === 'soft-linked' && (
														<span title="Configure transition">
															<Unlink className="h-4 w-4" />
														</span>
													))}
												{field.transition?.kind === 'hard-linked' && (
													<span title="Hard-linked">
														<Link className="h-4 w-4" />
													</span>
												)}
												{(field.transition?.overlap?.quantity ?? 0) > 0 && (
													<span title="Overlap">
														<Blend className="h-4 w-4" />
													</span>
												)}
												{(field.transition?.waitingTime?.quantity ?? 0) > 0 && (
													<span title="Waiting time">
														<Timer className="h-4 w-4" />
													</span>
												)}
											</Card>
										</div>
									</div>
								)}
								{Boolean(errors.operations?.[index]) && (
									<span className="absolute left-1/2 w-full -translate-x-1/2 transform text-center text-xs text-destructive">
										Please review this operation for errors and try again.
									</span>
								)}
							</div>
						</Fragment>
					))}
				</div>
				<GenericDialog
					title="Add operation"
					description="Add a new operation to the product"
					hideDescription
					className="max-h-[88dvh] max-w-[550px]"
					{...createProductOperationFormDialog.formDialogProps}
				>
					<ProductOperationForm
						submitButtons={<Button type="submit">Add Operation</Button>}
						initialValues={{
							name: getUniqueName('Operation 1'),
							phases: {
								before: {
									quantity: 0,
									unit: 'minutes',
								},
								during: [],
								after: {
									quantity: 0,
									unit: 'minutes',
								},
							},
							tools: [],
							staffGroups: [],
							transition: {
								kind: 'soft-linked',
								overlap: {
									quantity: 0,
									unit: 'percent',
								},
								waitingTime: {
									quantity: 0,
									unit: 'minutes',
								},
							},
						}}
						machines={props.machines}
						tools={props.tools}
						staffGroups={props.staffGroups}
						existingNames={productOperationNames}
						{...createProductOperationFormDialog.formProps}
					/>
				</GenericDialog>
				<GenericDialog
					title="Edit operation"
					description="Edit the operation details"
					hideDescription
					className="max-h-[88dvh] max-w-[550px]"
					{...editProductOperationFormDialog.formDialogProps}
				>
					<ProductOperationForm
						submitButtons={<Button type="submit">Save Operation</Button>}
						initialValues={operationBeingEdited?.data}
						machines={props.machines}
						tools={props.tools}
						staffGroups={props.staffGroups}
						existingNames={productOperationNames.filter(
							name => name !== operationBeingEdited?.data.name,
						)}
						{...editProductOperationFormDialog.formProps}
					/>
				</GenericDialog>
				<GenericDialog
					title="Edit operation transition"
					description="Edit the operation transition details"
					hideDescription
					className="max-h-[88dvh] max-w-[550px]"
					{...editProductOperationTransitionFormDialog.formDialogProps}
				>
					<ProductOperationTransitionForm
						submitButtons={
							<Button type="submit">Save Operation Transition</Button>
						}
						initialValues={operationTransitionBeingEdited?.data}
						{...editProductOperationTransitionFormDialog.formProps}
					/>
				</GenericDialog>
			</form>
		</>
	)
}

export { ProductForm }
