import { useEffect, useState } from 'react'

import {
	closestCenter,
	DndContext,
	DragEndEvent,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import {
	rectSortingStrategy,
	SortableContext,
	sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { TProduct } from '@repo/types'
import {
	createFileRoute,
	Link,
	Outlet,
	useNavigate,
} from '@tanstack/react-router'
import {
	ColumnFiltersState,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	PaginationState,
	RowSelectionState,
	SortingState,
	useReactTable,
	VisibilityState,
} from '@tanstack/react-table'
import { AlignJustify, LayoutGrid, Plus } from 'lucide-react'
import { toast } from 'sonner'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { Draggable } from '@/components/dnd/draggable'
import { PageLayout } from '@/components/layout'
import {
	ScrollableToolbar,
	ScrollableToolbarContentLeft,
	ScrollableToolbarContentRight,
} from '@/components/scrollable-toolbar'
import { DataTable } from '@/components/table/data-table'
import { Button } from '@/components/ui/button'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@/components/ui/tooltip'
import { ProductCard } from '@/features/products/components/product-card'
import { columns } from '@/features/products/components/table/columns'
import { DataTableToolbar } from '@/features/products/components/table/data-table-toolbar'
import {
	createProduct,
	reorderProduct,
	selectEnhancedProducts,
	selectProductNames,
	selectProductNumbers,
} from '@/features/products/products-slice'
import { useTouchDeviceDetector } from '@/hooks/use-touch-device-detector'
import { useUniqueName } from '@/hooks/use-unique-name'
import { useSyncedPersistedState } from '@/lib/hooks/use-synced-persisted-state'

type ViewOptions = 'list' | 'grid'

type ProductSearch = {
	view?: ViewOptions
	sorting?: SortingState
	columnFilters?: ColumnFiltersState
	columnVisibility?: VisibilityState
	rowSelection?: RowSelectionState
	pageIndex?: PaginationState['pageIndex']
	pageSize?: PaginationState['pageSize']
}

const Route = createFileRoute('/products')({
	component: ProductsComponent,
	validateSearch: (search: Record<string, unknown>): ProductSearch => {
		return {
			view: search.view as ProductSearch['view'],
			sorting: search.sorting as ProductSearch['sorting'],
			columnFilters: search.columnFilters as ProductSearch['columnFilters'],
			columnVisibility:
				search.columnVisibility as ProductSearch['columnVisibility'],
			rowSelection: search.rowSelection as ProductSearch['rowSelection'],
			pageIndex: search.pageIndex as ProductSearch['pageIndex'],
			pageSize: search.pageSize as ProductSearch['pageSize'],
		}
	},
})

function ProductsComponent() {
	const search = Route.useSearch()
	const navigate = useNavigate({ from: Route.fullPath })

	const view = useSyncedPersistedState<ViewOptions>('products.view', {
		value: search.view,
		defaultValue: 'list',
	})
	const columnVisibility = useSyncedPersistedState(
		'products.columnVisibility',
		{
			value: search.columnVisibility,
			defaultValue: {},
		},
	)
	const pageSize = useSyncedPersistedState('products.pageSize', {
		value: search.pageSize,
		defaultValue: 20,
	})

	const dispatch = useAppDispatch()
	const products = useAppSelector(selectEnhancedProducts)
	const productNames = useAppSelector(selectProductNames)
	const productNumbers = useAppSelector(selectProductNumbers)
	const [parent, enableAnimations] = useAutoAnimate()
	const { isTouchDevice } = useTouchDeviceDetector()
	const { getUniqueName } = useUniqueName(productNames)
	const { getUniqueName: getUniqueProductNumber } = useUniqueName(
		productNumbers,
		false,
	)

	const meta = {
		actions: (product: TProduct) => ({
			onEdit: () => {
				navigate({
					to: '/products/$productId/edit',
					params: { productId: product.id },
				})
			},
			onDuplicate: () => {
				dispatch(
					createProduct({
						...product,
						name: getUniqueName(product.name),
						productNumber: getUniqueProductNumber(product.productNumber),
					}),
				)
				toast.success(`Product "${product.name}" duplicated`)
			},
			onDelete: () => {
				navigate({
					to: '/products/$productId/delete',
					params: { productId: product.id },
				})
			},
		}),
	}

	const table = useReactTable({
		data: products,
		columns,
		meta,
		getRowId: originalRow => originalRow.id,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
	})

	const [tableState, setTableState] = useState({
		...table.initialState,
		sorting: search.sorting ?? [],
		columnFilters: search.columnFilters ?? [],
		rowSelection: search.rowSelection ?? {},
		columnVisibility,
		pagination: {
			pageIndex: search.pageIndex ?? 0,
			pageSize,
		},
	})

	useEffect(() => {
		if (
			Object.keys(search).every(
				key => search[key as keyof ProductSearch] === undefined,
			)
		) {
			setTableState({
				...table.initialState,
				columnVisibility,
				pagination: {
					...table.initialState.pagination,
					pageSize,
				},
			})
		}
	}, [search, table.initialState, columnVisibility, pageSize])

	table.setOptions(prev => ({
		...prev,
		state: tableState,
		onStateChange: updater => {
			const newTableState =
				updater instanceof Function ? updater(tableState) : updater

			navigate({
				search: old => ({
					...old,
					view,
					sorting: newTableState.sorting,
					columnFilters: newTableState.columnFilters,
					columnVisibility: newTableState.columnVisibility,
					rowSelection: newTableState.rowSelection,
					pageIndex: newTableState.pagination.pageIndex,
					pageSize: newTableState.pagination.pageSize,
				}),
				replace: true,
				resetScroll: false,
			})

			setTableState(updater)
		},
	}))

	function handleDragStart() {
		enableAnimations(false)
	}

	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event

		if (active.id !== over?.id) {
			const oldIndex = products.findIndex(m => m.id === active.id)
			const newIndex = products.findIndex(m => m.id === over?.id)
			if (oldIndex === -1 || newIndex === -1) return
			dispatch(reorderProduct({ newIndex, oldIndex }))
		}
		setTimeout(() => enableAnimations(true), 0)
	}

	const sensors = useSensors(
		useSensor(isTouchDevice ? TouchSensor : PointerSensor, {
			activationConstraint: isTouchDevice
				? { delay: 250, tolerance: 5 }
				: { distance: 10 },
		}),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	)

	return (
		<PageLayout>
			<ScrollableToolbar>
				<ScrollableToolbarContentLeft>
					<h1 className="text-xl sm:text-2xl">Products</h1>
				</ScrollableToolbarContentLeft>
				<ScrollableToolbarContentRight>
					<Button asChild>
						<Link to="/products/create">
							<Plus className="mr-2 h-4 w-4" />
							Create Product
						</Link>
					</Button>
					<Tabs value={view}>
						<TabsList aria-label="View options">
							<Tooltip>
								<TooltipTrigger>
									<TabsTrigger
										value="list"
										aria-label="Switch to list view"
										asChild
									>
										<Link to="." search={old => ({ ...old, view: 'list' })}>
											<AlignJustify className="h-4 w-4" />
										</Link>
									</TabsTrigger>
								</TooltipTrigger>
								<TooltipContent>
									<p>List View</p>
								</TooltipContent>
							</Tooltip>
							<Tooltip>
								<TooltipTrigger>
									<TabsTrigger
										value="grid"
										aria-label="Switch to grid view"
										asChild
									>
										<Link to="." search={old => ({ ...old, view: 'grid' })}>
											<LayoutGrid className="h-4 w-4" />
										</Link>
									</TabsTrigger>
								</TooltipTrigger>
								<TooltipContent>
									<p>Grid View</p>
								</TooltipContent>
							</Tooltip>
						</TabsList>
					</Tabs>
				</ScrollableToolbarContentRight>
			</ScrollableToolbar>
			<div className="py-8">
				{view === 'list' && (
					<DataTable
						table={table}
						columns={columns}
						renderToolbar={() => <DataTableToolbar table={table} />}
					/>
				)}
				{view === 'grid' && (
					<DndContext
						sensors={sensors}
						modifiers={[restrictToParentElement]}
						collisionDetection={closestCenter}
						onDragStart={handleDragStart}
						onDragEnd={handleDragEnd}
					>
						<SortableContext
							strategy={rectSortingStrategy}
							items={products.map(p => p.id)}
						>
							<div
								ref={parent}
								className="mt-4 grid w-full gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
							>
								{products.map(product => (
									<Draggable
										key={product.id}
										id={product.id}
										className="rounded-lg"
									>
										<ProductCard
											key={product.id}
											product={product}
											{...meta.actions(product)}
										/>
									</Draggable>
								))}
							</div>
						</SortableContext>
					</DndContext>
				)}
			</div>
			<Outlet />
		</PageLayout>
	)
}

export { Route }
