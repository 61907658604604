import { useEffect, useMemo, useState } from 'react'

import {
	addDays,
	addHours,
	addMinutes,
	format,
	isAfter,
	isBefore,
	isSameDay,
	startOfDay,
	startOfHour,
	startOfMinute,
	startOfWeek,
} from 'date-fns'
import { ChartColumn, ChartColumnStacked, Check, Settings } from 'lucide-react'
import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	ReferenceLine,
	XAxis,
	YAxis,
} from 'recharts'

import { Button } from '@/components/ui/button'
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '@/components/ui/card'
import {
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from '@/components/ui/chart'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { MultiSelectFilter } from '@/components/ui/multi-select-filter'
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectSeparator,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'
import type { AggregatedInterval } from '@/features/staff-demand/calculate-staff-demand'
import { cn } from '@/lib/utils'

import {
	AggregationTypeOption,
	ChartTypeOption,
	IntervalOption,
} from '../schema'

// Helper function to add interval to date
const addInterval = (
	date: Date,
	intervalType: IntervalOption,
	count: number = 1,
): Date => {
	switch (intervalType) {
		case '15min':
			return addMinutes(date, 15 * count)
		case '30min':
			return addMinutes(date, 30 * count)
		case '1h':
			return addHours(date, count)
		case '4h':
			return addHours(date, 4 * count)
		case '8h':
			return addHours(date, 8 * count)
		case '1d':
			return addDays(date, count)
		case '7d':
			return addDays(date, 7 * count)
	}
}

// Helper function for interval calculations
const getIntervalStart = (date: Date, intervalType: IntervalOption) => {
	switch (intervalType) {
		case '15min':
		case '30min':
			return startOfMinute(date)
		case '1h':
		case '4h':
		case '8h':
			return startOfHour(date)
		case '1d':
			return startOfDay(date)
		case '7d':
			return startOfWeek(date, { weekStartsOn: 1 })
	}
}

const ColorBar = ({
	index,
	className,
}: {
	index: number
	className?: string
}) => (
	<div
		className={cn(className, 'w-1 rounded-sm')}
		style={{
			backgroundColor: `hsl(var(--chart-${index + 1}))`,
		}}
	/>
)

export type StaffDemandChartProps = {
	intervals: AggregatedInterval[]
	staffGroups: string[]
	interval: IntervalOption
	availableIntervals: IntervalOption[]
	onIntervalChange: (interval: IntervalOption) => void
	chartType: ChartTypeOption
	onChartTypeChange: (chartType: ChartTypeOption) => void
	aggregationType: AggregationTypeOption
	onAggregationTypeChange: (aggregationType: AggregationTypeOption) => void
	selectedStaffGroups: string[]
	onStaffGroupsChange: (selectedGroups: string[]) => void
}

export function StaffDemandChart({
	intervals,
	staffGroups,
	interval,
	availableIntervals,
	onIntervalChange,
	chartType,
	onChartTypeChange,
	aggregationType,
	onAggregationTypeChange,
	selectedStaffGroups,
	onStaffGroupsChange,
}: StaffDemandChartProps) {
	// Get current time and update it every 10 seconds
	const [now, setNow] = useState(new Date())
	useEffect(() => {
		const interval = setInterval(() => {
			setNow(new Date())
		}, 10_000)
		return () => clearInterval(interval)
	}, [])

	// Create array of interval-based timestamps
	const intervalData = useMemo(() => {
		if (!intervals.length) return []

		return intervals.map(interval => ({
			date: interval.startDate,
			endDate: interval.endDate,
			...interval.aggregatedDemand[aggregationType],
			total: interval.aggregatedDemand.total[aggregationType],
		}))
	}, [intervals, aggregationType])

	const nowAlignedToInterval = useMemo(() => {
		// If we're showing the current time, align it to the current interval
		return getIntervalStart(now, interval)
	}, [now, interval])

	// Check if current time is within the chart range
	const showReferenceLine = useMemo(() => {
		if (!intervals.length) return false
		const chartStart = intervals[0].startDate
		const chartEnd = intervals[intervals.length - 1].endDate
		return isAfter(now, chartStart) && isBefore(now, chartEnd)
	}, [intervals, now])

	const chartConfig = useMemo(
		() =>
			Object.fromEntries(
				staffGroups.map(group => [
					group,
					{
						label: group,
						color: `hsl(var(--chart-${staffGroups.indexOf(group) + 1}))`,
					},
				]),
			),
		[staffGroups],
	)

	const generateCustomTicks = useMemo(() => {
		if (!intervals.length) {
			return []
		}

		const firstDate = intervals[0].startDate
		const lastDate = intervals[intervals.length - 1].endDate
		const isMultiDay = !isSameDay(firstDate, lastDate)

		if (isMultiDay) {
			// For multi-day ranges, ensure one tick per day
			const ticks = []
			let currentDate = startOfDay(firstDate)
			const endDate = startOfDay(lastDate)

			while (!isAfter(currentDate, endDate)) {
				ticks.push(currentDate.toISOString())
				currentDate = addDays(currentDate, 1)
			}
			return ticks
		} else {
			// For single day, ensure one tick per hour based on the interval
			const ticks = []
			let currentTime = startOfHour(firstDate)
			const endTime = lastDate

			// For intervals less than 1 hour, show hourly ticks
			// For larger intervals, match the interval size
			const tickInterval = ['15min', '30min'].includes(interval)
				? '1h'
				: interval

			while (!isAfter(currentTime, endTime)) {
				ticks.push(currentTime.toISOString())
				currentTime = addInterval(
					currentTime,
					tickInterval as IntervalOption,
					1,
				)
			}
			return ticks
		}
	}, [intervals, interval])

	return (
		<Card>
			<CardHeader className="space-y-4">
				{/* Title Section */}
				<div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
					<div className="min-w-0 flex-shrink">
						<CardTitle>Overview</CardTitle>
						<CardDescription>
							{!intervals.length ? (
								'No staff requirement data'
							) : (
								<>
									Staff requirements for{' '}
									{format(intervals[0].startDate, 'd MMM')}
									{!isSameDay(
										intervals[0].startDate,
										intervals[intervals.length - 1].endDate,
									) && (
										<>
											{' '}
											to{' '}
											{format(intervals[intervals.length - 1].endDate, 'd MMM')}
										</>
									)}
								</>
							)}
						</CardDescription>
					</div>

					{/* Desktop Controls */}
					<div className="hidden items-center gap-4 lg:flex">
						<MultiSelectFilter
							title="Staff Group"
							options={staffGroups.map((group, index) => ({
								value: group,
								label: group,
								icon: ({ className }) => (
									<ColorBar index={index} className={className} />
								),
							}))}
							selected={selectedStaffGroups}
							onChange={onStaffGroupsChange}
							align="end"
						/>
						<ToggleGroup
							type="single"
							value={chartType}
							onValueChange={value => {
								if (value) onChartTypeChange(value as ChartTypeOption)
							}}
						>
							<ToggleGroupItem
								value="stacked"
								aria-label="Stacked Bar Chart"
								title="Stacked View"
							>
								<ChartColumnStacked className="h-4 w-4" />
							</ToggleGroupItem>
							<ToggleGroupItem
								value="grouped"
								aria-label="Grouped Bar Chart"
								title="Grouped View"
							>
								<ChartColumn className="h-4 w-4" />
							</ToggleGroupItem>
						</ToggleGroup>
						<Separator orientation="vertical" className="h-6" />
						<ToggleGroup
							type="single"
							value={aggregationType}
							onValueChange={value => {
								if (value)
									onAggregationTypeChange(value as AggregationTypeOption)
							}}
						>
							<ToggleGroupItem
								value="min"
								aria-label="Minimum Values"
								title="Show Minimum Values"
							>
								Min
							</ToggleGroupItem>
							<ToggleGroupItem
								value="avg"
								aria-label="Average Values"
								title="Show Average Values"
							>
								Avg
							</ToggleGroupItem>
							<ToggleGroupItem
								value="max"
								aria-label="Maximum Values"
								title="Show Maximum Values"
							>
								Max
							</ToggleGroupItem>
						</ToggleGroup>
						<Select
							value={interval}
							onValueChange={value => onIntervalChange(value as IntervalOption)}
							disabled={availableIntervals.length === 1}
						>
							<SelectTrigger className="w-[190px]">
								<div>
									<span className="mr-2 text-muted-foreground">Interval:</span>
									<SelectValue placeholder="Select interval" />
								</div>
							</SelectTrigger>
							<SelectContent>
								{/* Group intervals by duration */}
								{availableIntervals.some(i =>
									['15min', '30min', '1h'].includes(i),
								) && (
									<SelectGroup>
										<SelectLabel className="text-xs">
											Short Intervals
										</SelectLabel>
										{availableIntervals.includes('15min') && (
											<SelectItem value="15min" className="text-sm">
												15 Minutes
											</SelectItem>
										)}
										{availableIntervals.includes('30min') && (
											<SelectItem value="30min" className="text-sm">
												30 Minutes
											</SelectItem>
										)}
										{availableIntervals.includes('1h') && (
											<SelectItem value="1h" className="text-sm">
												1 Hour
											</SelectItem>
										)}
									</SelectGroup>
								)}

								{availableIntervals.some(i =>
									['15min', '30min', '1h'].includes(i),
								) &&
									availableIntervals.some(i => ['4h', '8h'].includes(i)) && (
										<SelectSeparator />
									)}

								{availableIntervals.some(i => ['4h', '8h'].includes(i)) && (
									<SelectGroup>
										<SelectLabel className="text-xs">Work Periods</SelectLabel>
										{availableIntervals.includes('4h') && (
											<SelectItem value="4h" className="text-sm">
												4 Hour
											</SelectItem>
										)}
										{availableIntervals.includes('8h') && (
											<SelectItem value="8h" className="text-sm">
												8 Hour
											</SelectItem>
										)}
									</SelectGroup>
								)}

								{availableIntervals.some(i => ['4h', '8h'].includes(i)) &&
									availableIntervals.some(i => ['1d', '7d'].includes(i)) && (
										<SelectSeparator />
									)}

								{availableIntervals.some(i => ['1d', '7d'].includes(i)) && (
									<SelectGroup>
										<SelectLabel className="text-xs">
											Extended Periods
										</SelectLabel>
										{availableIntervals.includes('1d') && (
											<SelectItem value="1d" className="text-sm">
												Day
											</SelectItem>
										)}
										{availableIntervals.includes('7d') && (
											<SelectItem value="7d" className="text-sm">
												Week
											</SelectItem>
										)}
									</SelectGroup>
								)}
							</SelectContent>
						</Select>
					</div>
				</div>

				{/* Mobile Controls */}
				<div className="flex items-center justify-end gap-2 lg:hidden">
					<MultiSelectFilter
						title="Staff Groups"
						options={staffGroups.map((group, index) => ({
							value: group,
							label: group,
							icon: ({ className }) => (
								<ColorBar index={index} className={className} />
							),
						}))}
						selected={selectedStaffGroups}
						onChange={onStaffGroupsChange}
						align="end"
					/>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant="outline" size="icon">
								<Settings className="h-4 w-4" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end" className="w-56">
							<DropdownMenuLabel>Chart Settings</DropdownMenuLabel>
							<DropdownMenuGroup>
								<DropdownMenuItem asChild>
									<Select
										value={interval}
										onValueChange={value =>
											onIntervalChange(value as IntervalOption)
										}
										disabled={availableIntervals.length === 1}
									>
										<SelectTrigger className="w-full border-none bg-transparent p-2 shadow-none">
											<div>
												<span className="mr-2 text-muted-foreground">
													Interval:
												</span>
												<SelectValue placeholder="Select interval" />
											</div>
										</SelectTrigger>
										<SelectContent>
											{/* Group intervals by duration */}
											{availableIntervals.some(i =>
												['15min', '30min', '1h'].includes(i),
											) && (
												<SelectGroup>
													<SelectLabel className="text-xs">
														Short Intervals
													</SelectLabel>
													{availableIntervals.includes('15min') && (
														<SelectItem value="15min" className="text-sm">
															15 Minutes
														</SelectItem>
													)}
													{availableIntervals.includes('30min') && (
														<SelectItem value="30min" className="text-sm">
															30 Minutes
														</SelectItem>
													)}
													{availableIntervals.includes('1h') && (
														<SelectItem value="1h" className="text-sm">
															1 Hour
														</SelectItem>
													)}
												</SelectGroup>
											)}

											{availableIntervals.some(i =>
												['15min', '30min', '1h'].includes(i),
											) &&
												availableIntervals.some(i =>
													['4h', '8h'].includes(i),
												) && <SelectSeparator />}

											{availableIntervals.some(i =>
												['4h', '8h'].includes(i),
											) && (
												<SelectGroup>
													<SelectLabel className="text-xs">
														Work Periods
													</SelectLabel>
													{availableIntervals.includes('4h') && (
														<SelectItem value="4h" className="text-sm">
															4 Hour
														</SelectItem>
													)}
													{availableIntervals.includes('8h') && (
														<SelectItem value="8h" className="text-sm">
															8 Hour
														</SelectItem>
													)}
												</SelectGroup>
											)}

											{availableIntervals.some(i => ['4h', '8h'].includes(i)) &&
												availableIntervals.some(i =>
													['1d', '7d'].includes(i),
												) && <SelectSeparator />}

											{availableIntervals.some(i =>
												['1d', '7d'].includes(i),
											) && (
												<SelectGroup>
													<SelectLabel className="text-xs">
														Extended Periods
													</SelectLabel>
													{availableIntervals.includes('1d') && (
														<SelectItem value="1d" className="text-sm">
															Day
														</SelectItem>
													)}
													{availableIntervals.includes('7d') && (
														<SelectItem value="7d" className="text-sm">
															Week
														</SelectItem>
													)}
												</SelectGroup>
											)}
										</SelectContent>
									</Select>
								</DropdownMenuItem>
								<DropdownMenuSeparator />
								<DropdownMenuItem onClick={() => onChartTypeChange('stacked')}>
									<ChartColumnStacked className="mr-2 h-4 w-4" />
									<span>Stacked View</span>
									{chartType === 'stacked' && (
										<Check className="ml-auto h-4 w-4" />
									)}
								</DropdownMenuItem>
								<DropdownMenuItem onClick={() => onChartTypeChange('grouped')}>
									<ChartColumn className="mr-2 h-4 w-4" />
									<span>Grouped View</span>
									{chartType === 'grouped' && (
										<Check className="ml-auto h-4 w-4" />
									)}
								</DropdownMenuItem>
								<DropdownMenuSeparator />
								<DropdownMenuItem
									onClick={() => onAggregationTypeChange('min')}
								>
									Min Values
									{aggregationType === 'min' && (
										<Check className="ml-auto h-4 w-4" />
									)}
								</DropdownMenuItem>
								<DropdownMenuItem
									onClick={() => onAggregationTypeChange('avg')}
								>
									Avg Values
									{aggregationType === 'avg' && (
										<Check className="ml-auto h-4 w-4" />
									)}
								</DropdownMenuItem>
								<DropdownMenuItem
									onClick={() => onAggregationTypeChange('max')}
								>
									Max Values
									{aggregationType === 'max' && (
										<Check className="ml-auto h-4 w-4" />
									)}
								</DropdownMenuItem>
							</DropdownMenuGroup>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			</CardHeader>
			<CardContent>
				<ChartContainer
					config={chartConfig}
					className="max-h-[60vh] min-h-[200px] w-full"
				>
					<BarChart accessibilityLayer data={intervalData}>
						<CartesianGrid vertical={false} />
						<XAxis
							dataKey="date"
							tickLine={false}
							tickMargin={10}
							axisLine={false}
							interval="preserveStart"
							ticks={generateCustomTicks}
							minTickGap={16}
							height={40}
							tick={{ fill: 'var(--muted-foreground)' }}
							tickFormatter={value => {
								if (!intervals.length) {
									return format(value, 'd MMM, HH:mm')
								}

								// Get the first and last dates from the intervals
								const firstDate = intervals[0].startDate
								const lastDate = intervals[intervals.length - 1].endDate
								const isMultiDay = !isSameDay(firstDate, lastDate)

								if (isMultiDay) {
									if (interval === '7d') {
										// For week intervals, show the start and end dates with week number
										const intervalEnd = intervalData.find(
											d => d.date === value,
										)?.endDate
										if (intervalEnd) {
											const weekNum = format(new Date(value), 'w')
											// Use concise format: W11: 13-19/3
											return `W${weekNum}, ${format(value, 'd MMM')}-${format(intervalEnd, 'd MMM')}`
										}
									}
									// For other multi-day ranges, only show the date
									return format(value, 'd MMM')
								} else {
									// For single day, only show the time
									return format(value, 'HH:mm')
								}
							}}
						/>
						<YAxis
							tickLine={false}
							tickMargin={10}
							axisLine={false}
							tick={{ fill: 'var(--muted-foreground)' }}
							domain={[0, (dataMax: number) => Math.max(10, dataMax)]}
						/>
						<ChartTooltip
							cursor={true}
							content={
								<ChartTooltipContent
									indicator="line"
									labelFormatter={value => {
										if (interval === '7d') {
											// For week intervals, use the actual interval end date
											// since it's already properly aligned with calendar weeks
											const weekStart = new Date(value)
											const weekEnd = new Date(
												intervalData.find(d => d.date === value)?.endDate ??
													value,
											)
											const weekNum = format(weekStart, 'w')
											// Keep the full format in the tooltip since we have more space
											return `Week ${weekNum}: ${format(weekStart, 'd MMM')}-${format(weekEnd, 'd MMM')}`
										}
										return format(
											value,
											interval === '1d' ? 'd MMM' : 'd MMM, HH:mm',
										)
									}}
								/>
							}
						/>
						{(selectedStaffGroups.length
							? selectedStaffGroups
							: staffGroups
						).map(group => (
							<Bar
								key={group}
								dataKey={group}
								fill={`var(--color-${group})`}
								radius={4}
								stackId={chartType === 'stacked' ? 'staff' : undefined}
							>
								{intervalData.map((entry, index) => (
									<Cell
										key={`cell-${index}`}
										opacity={isBefore(entry.endDate, now) ? 0.4 : 1}
									/>
								))}
							</Bar>
						))}
						{showReferenceLine && (
							<ReferenceLine
								x={nowAlignedToInterval.toISOString()}
								stroke="hsl(var(--primary))"
								strokeWidth={2}
								isFront={true}
							/>
						)}
					</BarChart>
				</ChartContainer>
			</CardContent>
		</Card>
	)
}
