import { useEffect } from 'react'

import useLocalStorageState from 'use-local-storage-state'

export function useSyncedPersistedState<T>(
	key: string,
	options: { value?: T; defaultValue: T },
) {
	const [persistedValue, setPersistedValue] = useLocalStorageState(key, {
		defaultValue: options.defaultValue,
	})

	// Always persist the current value to localStorage
	useEffect(() => {
		if (options.value !== undefined) {
			setPersistedValue(options.value)
		}
	}, [setPersistedValue, options.value])

	// Return the URL value if present, fall back to persisted value only if URL value is undefined
	return options.value !== undefined ? options.value : persistedValue
}
