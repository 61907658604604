import { createId } from '@paralleldrive/cuid2'
import {
	createAsyncThunk,
	createEntityAdapter,
	createSelector,
	createSlice,
} from '@reduxjs/toolkit'
import type { PayloadAction, Update } from '@reduxjs/toolkit'
import {
	TCompletedOrder,
	TInProgressOrder,
	TMachineBooking,
	TOrder,
	TPendingOrder,
	TPeriodString,
	TPlannedOrder,
	TPlanningParameters,
	TProduct,
	TProductOperation,
} from '@repo/types'
import { compareAsc, formatDistance, max, min } from 'date-fns'
import { PURGE } from 'redux-persist'
import { toast } from 'sonner'

import type { RootState } from '@/app/store'
import { selectMachines } from '@/features/machines/machines-slice'
import { getCalendarAdjustmentsForMachine } from '@/features/planning/get-calendar-adjustments-for-machine'
import {
	deleteBooking,
	editBooking,
	selectAllBookings,
	selectCalendarAdjustments,
	validatePlan,
} from '@/features/planning/planning-slice'
import {
	getTotalOperationDurationMinutes,
	scheduleOperation,
} from '@/features/planning/schedule-order'
import { enhanceProductOperation } from '@/features/products/utils/enhance-product-operation'
import { formatNumericString } from '@/utils/format-numeric-string'

export function getTotalOrderDurationMinutes(args: {
	order: TOrder<unknown>
	product?: TProduct<TProductOperation>
	bookings: TMachineBooking[]
}) {
	const { order, product, bookings } = args

	if (!product) {
		return 0
	}

	if (order.status === 'planned') {
		return bookings
			.filter(booking => order.bookingIds.includes(booking.id))
			.reduce((total, booking) => total + booking.effectiveTimeMinutes.total, 0)
	} else {
		return product.operations.reduce(
			(total, operation) =>
				total +
				getTotalOperationDurationMinutes({
					setupDuration: operation.phases.before,
					teardownDuration: operation.phases.after,
					productionDuration: operation.phases.during[0].duration,
					quantity: order.quantity,
				}),
			0,
		)
	}
}

export function isActiveOrder(order: TOrder) {
	return ['pending', 'planned', 'in-progress'].includes(order.status)
}

const ordersAdapter = createEntityAdapter<TOrder>({
	sortComparer: (a, b) => {
		const aHasPlanned = 'plannedPeriod' in a
		const bHasPlanned = 'plannedPeriod' in b

		if (!aHasPlanned && !bHasPlanned) {
			return compareAsc(a.dueDate, b.dueDate)
		}

		if (!aHasPlanned) return -1
		if (!bHasPlanned) return 1

		return compareAsc(a.plannedPeriod.startDate, b.plannedPeriod.startDate)
	},
})

export const ordersSlice = createSlice({
	name: 'orders',
	initialState: ordersAdapter.getInitialState(),
	reducers: {
		createOrder: {
			reducer: ordersAdapter.addOne,
			prepare: (orderData: Omit<TPendingOrder, 'id' | 'status'>) => {
				return {
					payload: {
						...orderData,
						id: createId(),
						status: 'pending' as const,
					},
				}
			},
		},
		editOrder: (
			state,
			action: PayloadAction<Update<Omit<TOrder, 'status'>, string>>,
		) => {
			ordersAdapter.updateOne(state, action.payload)
		},
		deleteOrder: ordersAdapter.removeOne,
		advanceOrderToPlanned: (
			state,
			action: PayloadAction<{
				id: string
				bookingIds: string[]
				planningParameters: TPlanningParameters
				plannedPeriod: TPeriodString
			}>,
		) => {
			const { id, bookingIds, planningParameters, plannedPeriod } =
				action.payload
			const existingOrder = state.entities[id] as TPendingOrder | undefined

			if (existingOrder?.status === 'pending') {
				ordersAdapter.updateOne(state, {
					id,
					changes: {
						status: 'planned',
						bookingIds,
						planningParameters,
						plannedPeriod,
					} as Partial<TPlannedOrder>,
				})
			}
		},
		advanceOrderToInactive: (state, action: PayloadAction<string>) => {
			const id = action.payload
			const existingOrder = state.entities[id]

			if (existingOrder?.status === 'pending') {
				ordersAdapter.updateOne(state, {
					id,
					changes: { status: 'inactive' },
				})
			}
		},
		advanceOrderToInProgress: (state, action: PayloadAction<string>) => {
			const id = action.payload
			const existingOrder = state.entities[id]

			if (existingOrder?.status === 'planned') {
				ordersAdapter.updateOne(state, {
					id,
					changes: { status: 'in-progress' },
				})
			}
		},
		advanceOrderToCompleted: (
			state,
			action: PayloadAction<{ id: string; completionDate: string }>,
		) => {
			const { id, completionDate } = action.payload
			const existingOrder = state.entities[id]

			if (
				existingOrder?.status === 'planned' ||
				existingOrder?.status === 'in-progress'
			) {
				ordersAdapter.updateOne(state, {
					id,
					changes: {
						status: 'completed',
						completionDate,
					} as Partial<TCompletedOrder>,
				})
			}
		},
		revertOrderToPlanned: (state, action: PayloadAction<string>) => {
			const existingOrder = state.entities[action.payload]

			if (
				existingOrder?.status === 'completed' ||
				existingOrder?.status === 'in-progress'
			) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { completionDate, ...data } = existingOrder as TCompletedOrder
				ordersAdapter.setOne(state, {
					...data,
					status: 'planned' as const,
				})
			}
		},
		revertOrderToPending: (state, action: PayloadAction<string>) => {
			const existingOrder = state.entities[action.payload]

			if (
				existingOrder?.status === 'inactive' ||
				existingOrder?.status === 'planned' ||
				existingOrder?.status === 'in-progress'
			) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { bookingIds, planningParameters, plannedPeriod, ...data } =
					existingOrder as TPlannedOrder
				ordersAdapter.setOne(state, {
					...data,
					status: 'pending' as const,
				})
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(PURGE, state => {
			ordersAdapter.removeAll(state)
		})
	},
})

export const {
	createOrder,
	editOrder,
	advanceOrderToPlanned,
	advanceOrderToInactive,
	revertOrderToPending,
	advanceOrderToInProgress,
	advanceOrderToCompleted,
	revertOrderToPlanned,
} = ordersSlice.actions

export const {
	selectAll: selectAllOrders,
	selectById: selectOrderById,
	selectIds: selectOrderIds,
	selectEntities: selectOrderEntities,
} = ordersAdapter.getSelectors((state: RootState) => state.orders)

export const selectEnhancedOrders = createSelector(
	selectAllOrders,
	(state: RootState) => state.products.list,
	(state: RootState) => state.productOperations.list,
	(state: RootState) => state.planning.bookings,
	(state: RootState) => state.planning.alerts,
	(state: RootState) => state.planning.ignoredAlerts,
	(orders, products, productOperations, bookings, alerts, ignoredAlerts) =>
		orders.map(order => {
			const product =
				// TODO: Provide a better fallback or handle this case
				products.find(product => product.id === order.product.id) ?? products[0]
			const enhancedProduct = {
				...product,
				operations: product?.operations
					.map(operation => productOperations.find(o => o.id === operation.id))
					.filter((o): o is TProductOperation => Boolean(o)),
			}
			const productionDurationMinutes = Math.min(
				getTotalOrderDurationMinutes({
					order,
					product: enhancedProduct,
					bookings,
				}),
				5_260_000 /* 10 years in mins */,
			)
			return {
				...order,
				product,
				productionDurationMinutes,
				formattedProductionDuration: formatDistance(
					0,
					productionDurationMinutes * 60 * 1000,
					{
						includeSeconds: true,
					},
				),
				alerts: alerts.filter(
					alert =>
						!ignoredAlerts.entities[alert.hash] &&
						alert.orders.some(o => o.id === order.id),
				),
			}
		}),
)
export const selectCategorizedOrders = createSelector(
	selectAllOrders,
	orders => {
		const pendingOrders: TPendingOrder[] = []
		const plannedOrders: TPlannedOrder[] = []
		const inProgressOrders: TInProgressOrder[] = []
		const completedOrders: TCompletedOrder[] = []
		const activeOrders: (TPlannedOrder | TInProgressOrder)[] = []
		const processedOrders: (
			| TPlannedOrder
			| TInProgressOrder
			| TCompletedOrder
		)[] = []

		orders.forEach(order => {
			if (order.status === 'pending') {
				pendingOrders.push(order)
			} else if (order.status === 'planned') {
				plannedOrders.push(order)
				activeOrders.push(order)
				processedOrders.push(order)
			} else if (order.status === 'in-progress') {
				inProgressOrders.push(order)
				activeOrders.push(order)
				processedOrders.push(order)
			} else if (order.status === 'completed') {
				completedOrders.push(order)
				processedOrders.push(order)
			}
		})

		return {
			pendingOrders,
			plannedOrders,
			inProgressOrders,
			completedOrders,
			activeOrders,
			processedOrders,
		}
	},
)
export const selectProductionOrderNumbers = createSelector(
	selectAllOrders,
	orders => orders.map(order => order.productionOrderNumber),
)

export const cascadeDeleteOrder = createAsyncThunk(
	`${ordersSlice.name}/cascadeDeleteOrder`,
	async (
		orderId: string,
		{ dispatch, getState, rejectWithValue, fulfillWithValue },
	) => {
		try {
			const state = getState() as RootState
			const order = selectOrderById(state, orderId)

			if (!order) {
				return rejectWithValue('Order not found')
			}

			if ('bookingIds' in order) {
				order.bookingIds.forEach(bookingId => {
					dispatch(deleteBooking(bookingId))
				})
			}

			dispatch(ordersSlice.actions.deleteOrder(orderId))

			dispatch(validatePlan())

			toast.success(`Order #${order.productionOrderNumber} deleted`)

			return fulfillWithValue(orderId)
		} catch {
			return rejectWithValue('Failed to cascade delete order')
		}
	},
)

export const cascadeDeleteOrders = createAsyncThunk(
	`${ordersSlice.name}/cascadeDeleteOrders`,
	async (
		orderIds: string[],
		{ dispatch, getState, rejectWithValue, fulfillWithValue },
	) => {
		try {
			await new Promise(resolve => setTimeout(resolve, 0))
			const state = getState() as RootState
			const orders = orderIds
				.map(id => selectOrderById(state, id))
				.filter((order): order is TOrder => Boolean(order))

			if (orders.length === 0) {
				return rejectWithValue('No valid orders found')
			}

			// Delete all bookings first
			const bookingIds = orders.flatMap(order =>
				'bookingIds' in order ? order.bookingIds : [],
			)
			bookingIds.forEach(bookingId => {
				dispatch(deleteBooking(bookingId))
			})

			// Then delete all orders
			orders.forEach(order => {
				dispatch(ordersSlice.actions.deleteOrder(order.id))
			})

			dispatch(validatePlan())

			// Show success message with count
			if (orders.length === 1) {
				toast.success(`Deleted order #${orders[0].productionOrderNumber}`)
			} else {
				toast.success(`Deleted ${formatNumericString(orders.length)} orders`)
			}

			return fulfillWithValue(orderIds)
		} catch {
			return rejectWithValue('Failed to cascade delete orders')
		}
	},
)

export const updateOrderQuantity = createAsyncThunk(
	`${ordersSlice.name}/updateOrderQuantity`,
	async (
		args: { id: string; quantity: number },
		{ dispatch, getState, rejectWithValue },
	) => {
		try {
			const state = getState() as RootState
			const order = selectOrderById(state, args.id)
			const operations = state.productOperations.list
			const machines = selectMachines(state)
			const calendarAdjustments = selectCalendarAdjustments(state)
			const bookings = selectAllBookings(state)

			if (!order) {
				return rejectWithValue('Order not found')
			}

			// Update the order quantity first
			dispatch(
				ordersSlice.actions.editOrder({
					id: args.id,
					changes: { quantity: args.quantity },
				}),
			)

			// If the order is planned, we need to update its bookings
			if (order.status === 'planned') {
				const planningParameters: TPlanningParameters = {
					orderId: order.id,
					productId: order.product.id,
					quantity: args.quantity,
					earliestStartDate: order.dueDate,
					dueDate: order.dueDate,
					buffer: order.buffer,
					operations: operations
						.filter(o => o.productId === order.product.id)
						.map(o => {
							const enhancedOp = enhanceProductOperation(o, machines)
							return {
								id: o.id,
								phases: enhancedOp.phases,
								staffGroups: enhancedOp.staffGroups,
								tools: enhancedOp.tools,
								transition: enhancedOp.transition,
								machines: enhancedOp.phases.during.map(phase => ({
									id: phase.machine.id,
									availability: phase.machine.availability,
								})),
							}
						}),
				}

				// For each booking of the order, reschedule it with the new duration
				for (const bookingId of order.bookingIds) {
					const booking = bookings.find(b => b.id === bookingId)
					if (!booking) continue

					const operation = planningParameters.operations.find(
						o => o.id === booking.operationId,
					)
					if (!operation) continue

					const machine = machines.find(m => m.id === booking.machineId)
					if (!machine) continue

					// Schedule the operation with the new quantity
					const updatedSchedule = scheduleOperation({
						phases: operation.phases,
						planningParameters: {
							...planningParameters,
							earliestStartDate: booking.startDate,
							dueDate: order.dueDate,
						},
						machine,
						calendarAdjustments: getCalendarAdjustmentsForMachine({
							calendarAdjustments,
							machineId: booking.machineId,
						}),
						bookings: [],
						tools: booking.tools,
						direction: 'forward',
					})

					if (updatedSchedule) {
						dispatch(
							editBooking({
								...updatedSchedule,
								id: booking.id,
							}),
						)
					}
				}

				// Update the order's planned period based on the new booking times
				const updatedBookings = bookings.filter(b =>
					order.bookingIds.includes(b.id),
				)
				if (updatedBookings.length > 0) {
					const plannedPeriod = {
						startDate: min(updatedBookings.map(b => b.startDate)).toISOString(),
						endDate: max(updatedBookings.map(b => b.endDate)).toISOString(),
					}
					dispatch(
						ordersSlice.actions.editOrder({
							id: args.id,
							changes: { plannedPeriod } as Partial<TPlannedOrder>,
						}),
					)
				}
			}

			dispatch(validatePlan())

			return args
		} catch {
			return rejectWithValue('Failed to update order quantity')
		}
	},
)

export default ordersSlice.reducer
