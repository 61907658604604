import { useEffect, useMemo, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { TOrder, TProduct } from '@repo/types'
import { format } from 'date-fns'
import { Calendar as CalendarIcon, X } from 'lucide-react'
import {
	Controller,
	FieldErrors,
	SubmitHandler,
	useForm,
	UseFormHandleSubmit,
} from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import TextareaAutosize from 'react-textarea-autosize'

import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Combobox } from '@/components/ui/combobox'
import { DialogFooter } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'
import { TCustomer } from '@/features/customers/customers-slice'
import { cn } from '@/lib/utils'
import {
	decimalSeparator,
	thousandSeparator,
} from '@/utils/format-numeric-string'

import { OrderSchema, orderSchema } from './order-schema'

type SubmitButtonsRenderer =
	| ((props: {
			handleSubmit: UseFormHandleSubmit<OrderSchema>
	  }) => React.ReactNode)
	| React.ReactNode

function OrderForm(props: {
	submitButtons: SubmitButtonsRenderer
	onSubmit: SubmitHandler<OrderSchema>
	onError?: (errors: FieldErrors<OrderSchema>) => void
	products: TProduct[]
	customers: TCustomer[]
	invalidProductionOrderNumbers: string[]
	onUnsavedChanges?: (hasUnsavedChanges: boolean) => void
	initialValues?: Partial<Omit<TOrder, 'id'>>
	disableProductField?: boolean
	disableQuantityField?: boolean
	disableBufferField?: boolean
}) {
	const {
		submitButtons,
		onSubmit,
		onError,
		products,
		customers,
		invalidProductionOrderNumbers,
		onUnsavedChanges,
		initialValues,
		disableProductField,
		disableQuantityField,
		disableBufferField,
	} = props

	const schema = useMemo(() => {
		return orderSchema.refine(
			data =>
				!invalidProductionOrderNumbers.includes(data.productionOrderNumber),
			data => ({
				message: `Operation "${data.productionOrderNumber}" already exists`,
				path: ['productionOrderNumber'],
			}),
		)
	}, [invalidProductionOrderNumbers])
	const {
		register,
		handleSubmit,
		control,
		formState: { errors, isDirty },
	} = useForm<OrderSchema>({
		resolver: zodResolver(schema),
		defaultValues: initialValues,
	})

	useEffect(() => {
		onUnsavedChanges?.(isDirty)
	}, [isDirty, onUnsavedChanges])

	// TODO: Refactor to DatePicker component
	const [dueDateCalendarOpen, setDueDateCalendarOpen] = useState(false)
	const [earliestStartDateCalendarOpen, setEarliestStartDateCalendarOpen] =
		useState(false)

	return (
		<form
			onSubmit={e => {
				e.stopPropagation()
				handleSubmit(onSubmit, onError)(e)
			}}
			autoComplete="off"
		>
			<div className="grid gap-6 pb-6 pt-4">
				<div className="grid grid-cols-2 gap-4">
					<div className="flex flex-col space-y-1.5">
						<Label htmlFor="productionOrderNumber">
							Production Order Number
						</Label>
						<Input
							id="productionOrderNumber"
							className="flex-grow"
							error={Boolean(errors.productionOrderNumber)}
							{...register('productionOrderNumber', { required: true })}
						/>
						{errors.productionOrderNumber && (
							<span className="col-start-2 -mt-3 text-xs text-destructive">
								{errors.productionOrderNumber.message}
							</span>
						)}
					</div>
					<div className="flex flex-col space-y-1.5">
						<Label htmlFor="salesOrderNumber">Sales Order Number</Label>
						<Input
							id="salesOrderNumber"
							className="flex-grow"
							error={Boolean(errors.salesOrderNumber)}
							{...register('salesOrderNumber', { required: true })}
						/>
						{errors.salesOrderNumber && (
							<span className="col-start-2 -mt-3 text-xs text-destructive">
								{errors.salesOrderNumber.message}
							</span>
						)}
					</div>
				</div>
				<div className="flex flex-col space-y-1.5">
					<Label htmlFor="customerName">Customer Name</Label>
					<Controller
						name="customerName"
						control={control}
						render={({ field }) => (
							<Combobox
								initialValue={field.value}
								allowCreation={true}
								options={customers.map(c => ({
									value: c.name,
									label: c.name,
								}))}
								searchPlaceholder="Type customer name or select from list..."
								valuePlaceholder="Add a customer..."
								classNameButton="w-full"
								onSelect={field.onChange}
								error={Boolean(errors.customerName)}
							/>
						)}
					/>
					{errors.customerName && (
						<span className="-mt-3 text-xs text-destructive">
							{errors.customerName.message}
						</span>
					)}
				</div>
				<div className="grid grid-cols-[1fr_auto_auto] items-center gap-4">
					<div className="flex flex-col space-y-1.5">
						<Label htmlFor="product">Product</Label>
						<Controller
							name="product"
							control={control}
							render={({ field }) => (
								<Combobox
									initialValue={field.value?.id}
									options={products.map(p => ({
										value: p.id,
										label: `${p.productNumber} ${p.name}`,
									}))}
									error={Boolean(errors.product)}
									searchPlaceholder="Search products..."
									valuePlaceholder="Select a product..."
									classNameContent="w-[375px]"
									classNameButton="w-[210px]"
									onSelect={value => field.onChange({ id: value })}
									disabled={disableProductField}
								/>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<div className="h-3.5" />
						<X className="h-4 w-4 text-muted-foreground" />
					</div>
					<div className="flex flex-col space-y-1.5">
						<Label htmlFor="quantity">Order Quantity</Label>
						<Controller
							name="quantity"
							control={control}
							render={({ field }) => (
								<NumericFormat
									customInput={Input}
									id="quantity"
									className="flex-grow"
									error={Boolean(errors.quantity)}
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									placeholder="0"
									value={field.value === 0 ? '' : field.value}
									onValueChange={values => field.onChange(values.value)}
									disabled={disableQuantityField}
								/>
							)}
						/>
					</div>
					{errors.product && (
						<span className="-mt-3 text-xs text-destructive">
							{errors.product?.id?.message ?? errors.product.message}
						</span>
					)}
					{errors.quantity && (
						<span className="col-start-3 -mt-3 text-xs text-destructive">
							{errors.quantity.message}
						</span>
					)}
				</div>
				<div className="flex flex-col space-y-1.5">
					<Label htmlFor="earliestStartDate">
						Earliest Production Start Date
					</Label>
					<Controller
						name="earliestStartDate"
						control={control}
						render={({ field }) => (
							<Popover
								open={earliestStartDateCalendarOpen}
								onOpenChange={setEarliestStartDateCalendarOpen}
							>
								<PopoverTrigger asChild>
									<Button
										variant="outline"
										className={cn(
											'w-full justify-start text-left font-normal',
											!field.value && 'text-muted-foreground',
											errors.earliestStartDate && 'border-destructive',
										)}
									>
										<CalendarIcon className="mr-2 h-4 w-4" />
										{field.value ? (
											format(field.value, 'PPP')
										) : (
											<span>Pick a date</span>
										)}
									</Button>
								</PopoverTrigger>
								<PopoverContent className="w-auto p-0">
									<Calendar
										mode="single"
										selected={field.value ? new Date(field.value) : undefined}
										defaultMonth={
											field.value ? new Date(field.value) : undefined
										}
										onSelect={date => {
											if (date) {
												field.onChange(date.toISOString())
											}
											setEarliestStartDateCalendarOpen(false)
										}}
										initialFocus
									/>
								</PopoverContent>
							</Popover>
						)}
					/>
					{errors.earliestStartDate && (
						<span className="-mt-3 text-xs text-destructive">
							{errors.earliestStartDate.message}
						</span>
					)}
				</div>
				<div className="flex flex-col space-y-1.5">
					<Label htmlFor="dueDate">Order Completion Due Date</Label>
					<Controller
						name="dueDate"
						control={control}
						render={({ field }) => (
							<Popover
								open={dueDateCalendarOpen}
								onOpenChange={setDueDateCalendarOpen}
							>
								<PopoverTrigger asChild>
									<Button
										variant="outline"
										className={cn(
											'w-full justify-start text-left font-normal',
											!field.value && 'text-muted-foreground',
											errors.dueDate && 'border-destructive',
										)}
									>
										<CalendarIcon className="mr-2 h-4 w-4" />
										{field.value ? (
											format(field.value, 'PPP')
										) : (
											<span>Pick a date</span>
										)}
									</Button>
								</PopoverTrigger>
								<PopoverContent className="w-auto p-0">
									<Calendar
										mode="single"
										selected={field.value ? new Date(field.value) : undefined}
										defaultMonth={
											field.value ? new Date(field.value) : undefined
										}
										onSelect={date => {
											if (date) {
												field.onChange(date.toISOString())
											}
											setDueDateCalendarOpen(false)
										}}
										initialFocus
									/>
								</PopoverContent>
							</Popover>
						)}
					/>
					{errors.dueDate && (
						<span className="-mt-3 text-xs text-destructive">
							{errors.dueDate.message}
						</span>
					)}
				</div>
				<div className="flex flex-col space-y-1.5">
					<Label htmlFor="buffer.quantity">Buffer Time</Label>
					<div className="grid grid-cols-2 gap-4">
						<Controller
							name="buffer.quantity"
							control={control}
							render={({ field }) => (
								<NumericFormat
									customInput={Input}
									id="buffer.quantity"
									className="text-right"
									error={Boolean(errors.buffer?.quantity)}
									placeholder="0"
									value={field.value === 0 ? '' : field.value}
									onValueChange={values => field.onChange(values.value || 0)}
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									disabled={disableBufferField}
								/>
							)}
						/>
						<Controller
							name="buffer.unit"
							control={control}
							render={({ field }) => (
								<Select
									defaultValue={field.value}
									onValueChange={field.onChange}
									disabled={disableBufferField}
								>
									<SelectTrigger
										onBlur={field.onBlur}
										error={Boolean(errors.buffer?.unit)}
									>
										<SelectValue placeholder="Select time unit" />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value="hours">hours</SelectItem>
										<SelectItem value="days">days</SelectItem>
									</SelectContent>
								</Select>
							)}
						/>
						{errors.buffer?.message && (
							<span className="-mt-3 text-xs text-destructive">
								{errors.buffer.message}
							</span>
						)}
						{errors.buffer?.quantity && (
							<span className="-mt-3 text-xs text-destructive">
								{errors.buffer.quantity.message}
							</span>
						)}
						{errors.buffer?.unit && (
							<span className="col-start-2 -mt-3 text-xs text-destructive">
								{errors.buffer.unit.message}
							</span>
						)}
					</div>
				</div>
				<div className="flex flex-col space-y-1.5">
					<Label htmlFor="comment">Comment</Label>
					<TextareaAutosize
						id="comment"
						minRows={2}
						className={cn(
							'flex w-full resize-none rounded-md border border-input bg-background px-3 py-2 text-base ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
						)}
						placeholder="Add a comment about this order..."
						{...register('comment')}
					/>
				</div>
			</div>
			<DialogFooter>
				{typeof submitButtons === 'function'
					? submitButtons({ handleSubmit })
					: submitButtons}
			</DialogFooter>
		</form>
	)
}

export { OrderForm }
