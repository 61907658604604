export type TTimeUnit = 'seconds' | 'minutes' | 'hours'

export type TProductionTimeUnit =
	| 'minutes'
	| 'seconds_per_piece'
	| 'minutes_per_piece'
	| 'pieces_per_second'
	| 'pieces_per_minute'

export type TPeriodString = {
	startDate: string
	endDate: string
}

export type TPeriodFlexible = {
	startDate: Date | string
	endDate: Date | string
}

export type TPeriod = {
	startDate: Date
	endDate: Date
}

export type TSchedulingDirection = 'forward' | 'backward'

export type TCalendarPeriodAdjustment = {
	status: 'open' | 'closed'
	startDate: string
	endDate: string
}

export const weekdays = [
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
] as const

export type TTimeRange = {
	from: string
	to: string
}

export type TMachineAvailability = {
	[key in (typeof weekdays)[number]]: Array<TTimeRange>
}

export type TMachine = {
	id: string
	name: string
	availability: TMachineAvailability
}

export type TPhaseFlag = {
	before: boolean
	during: boolean
	after: boolean
}

export type TPhasedItem = {
	name: string
	factor: number
	phases: TPhaseFlag
}

export type TPhasedItemV2 = {
	id: string
	factor: number
	phases: TPhaseFlag
}

export type TPhaseSchedule<T extends TPeriod | TPeriodString = TPeriod> = {
	before: T
	during: T
	after: T
}

export type TProductOperation<T = TMachineReference> = {
	id: string
	productId: string
	name: string
	phases: TProductOperationPhases<T>
	tools: TPhasedItem[]
	staffGroups: TPhasedItem[]
	transition?: TTransition
}

type TOverlapTimeUnit = 'minutes' | 'pieces' | 'percent'

export type TPhaseDuration<
	T extends TTimeUnit | TProductionTimeUnit | TOverlapTimeUnit,
> = {
	quantity: number
	unit: T
}

export type TMachineReference = {
	id: string
}

export type TProductOperationPhases<T = TMachineReference> = {
	before?: TPhaseDuration<TTimeUnit>
	during: Array<{ machine: T; duration: TPhaseDuration<TProductionTimeUnit> }>
	after?: TPhaseDuration<TTimeUnit>
}

export type TTransition = {
	kind: 'hard-linked' | 'soft-linked'
	overlap: TPhaseDuration<TOverlapTimeUnit>
	waitingTime: TPhaseDuration<TTimeUnit>
}

export type TBufferDuration = {
	quantity: number
	unit: 'hours' | 'days'
}

export type TBookingStatus = 'planned' | 'in-progress' | 'completed'

export type TPlanningParameters = {
	orderId: string
	productId: string
	earliestStartDate: string
	dueDate: string
	buffer: TBufferDuration
	quantity: number
	operations: (Pick<
		TProductOperation,
		'phases' | 'staffGroups' | 'tools' | 'transition' | 'id'
	> & {
		machines: Pick<TMachine, 'id' | 'availability'>[]
		earliestStartDate?: string // TODO: Should operations have an earliestStartDate?
		status?: TBookingStatus // TODO: Should operations have a status in PlannningParameters?
	})[]
}

export type TEffectiveTimeMinutes = {
	before: number
	during: number
	after: number
	total: number
}

// TODO: Consider adding metadata for order and product, for UI purposes
// TODO: Consider integrating with orders-slice, so that bookings are created when orders are planned, and orders are stored in the planning slice
type BaseMachineBooking = {
	id: string
	status: TBookingStatus
	machineId: string
	productId: string
	operationId: string
	orderId: string
	startDate: string
	endDate: string
	effectiveTimeMinutes: TEffectiveTimeMinutes
	phases: TPhaseSchedule<TPeriodString>
	tools: TPhasedItem[]
	staffGroups: TPhasedItem[]
	operators: TPhasedItemV2[]
	compatibleMachines: string[]
}

export type TPlannedMachineBooking = BaseMachineBooking & {
	status: 'planned'
}

export type TInProgressMachineBooking = BaseMachineBooking & {
	status: 'in-progress'
	progress: {
		before?:
			| { completed: false; startDate: string; endDate?: string }
			| { completed: true; startDate: string; endDate: string }
		during:
			| {
					completed: false
					startDate?: string
					endDate?: string
					percentage: number
					quantity: number
			  }
			| {
					completed: true
					startDate: string
					endDate: string
					percentage: number
					quantity: number
			  }
		after?:
			| { completed: false; startDate?: string; endDate?: string }
			| { completed: true; startDate: string; endDate: string }
	}
}

export type TCompletedMachineBooking = BaseMachineBooking & {
	status: 'completed'
	progress: {
		before?: { completed: true; startDate: string; endDate: string }
		during: {
			completed: true
			startDate: string
			endDate: string
			percentage: number
			quantity: number
		}
		after?: { completed: true; startDate: string; endDate: string }
	}
}

export type TMachineBooking =
	| TPlannedMachineBooking
	| TInProgressMachineBooking
	| TCompletedMachineBooking

export type TCalendarAdjustment = TCalendarPeriodAdjustment & {
	id: string
	description?: string
	affectedMachines: Array<{ id: string }>
}

export type TOrderStatus =
	| 'pending'
	| 'inactive'
	| 'planned'
	| 'in-progress'
	| 'completed' // 'cancelled' | 'paused' | 'delayed'

export type ProductReference = {
	id: string
}

type BaseOrder<T = ProductReference> = {
	id: string
	status: TOrderStatus
	productionOrderNumber: string
	salesOrderNumber: string
	customerName: string
	product: T
	quantity: number
	earliestStartDate: string
	dueDate: string
	buffer: TBufferDuration
	comment?: string // Optional comment field
}

export type TPendingOrder<T = ProductReference> = BaseOrder<T> & {
	status: 'pending'
}

export type TInactiveOrder<T = ProductReference> = BaseOrder<T> & {
	status: 'inactive'
}

export type TPlannedOrder<T = ProductReference> = BaseOrder<T> & {
	status: 'planned'
	bookingIds: string[]
	planningParameters: TPlanningParameters
	plannedPeriod: TPeriodString
}

export type TInProgressOrder<T = ProductReference> = BaseOrder<T> &
	Pick<TPlannedOrder, 'bookingIds' | 'planningParameters' | 'plannedPeriod'> & {
		status: 'in-progress'
	}

export type TCompletedOrder<T = ProductReference> = BaseOrder<T> &
	Pick<TPlannedOrder, 'bookingIds' | 'planningParameters' | 'plannedPeriod'> & {
		status: 'completed'
		completionDate: string
	}

export type TOrder<T = ProductReference> =
	| TPendingOrder<T>
	| TInactiveOrder<T>
	| TPlannedOrder<T>
	| TInProgressOrder<T>
	| TCompletedOrder<T>

export type ProductOperationReference = {
	id: string
}

export type TProduct<T = ProductOperationReference> = {
	id: string
	name: string
	productNumber: string
	operations: T[]
}

export type TOperator = {
	id: string
	name: string
	initials: string
}
