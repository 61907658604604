import { z } from 'zod'

import { parseFlexibleNumber } from '@/utils/parse-flexible-number'

const timeUnitSchema = z.union([
	z.literal('seconds'),
	z.literal('minutes'),
	z.literal('hours'),
])

const productionTimeUnitSchema = z.union([
	z.literal('minutes'),
	z.literal('seconds_per_piece'),
	z.literal('minutes_per_piece'),
	z.literal('pieces_per_second'),
	z.literal('pieces_per_minute'),
])

const overlapTimeUnitSchema = z.union([
	z.literal('minutes'),
	z.literal('pieces'),
	z.literal('percent'),
])

const phaseQuantitySchema = <T extends z.ZodTypeAny>(unitSchema: T) =>
	z.object({
		quantity: z
			.union([
				z.number(),
				z
					.string()
					.trim()
					.transform(value => (value === '' ? 0 : parseFlexibleNumber(value))),
			])
			.refine(value => value >= 0, {
				message: 'Value must be ≥ 0',
			}),
		unit: unitSchema,
	})

const phaseFlagSchema = z
	.object({
		before: z.boolean(),
		during: z.boolean(),
		after: z.boolean(),
	})
	.refine(data => data.before || data.during || data.after, {
		message: 'Select at least one phase or remove row',
	})

const phasedItemSchema = z.object({
	name: z.string().trim(),
	factor: z
		.union([
			z.number(),
			z
				.string()
				.trim()
				.transform(value => (value === '' ? '1' : value))
				.transform(value => parseFlexibleNumber(value)),
		])
		.default(1)
		.refine(value => value > 0, {
			message: 'Factor must be > 0',
		}),
	phases: phaseFlagSchema,
})

export type PhasedItemSchema = z.infer<typeof phasedItemSchema>
export type PhasedItemInputSchema = z.input<typeof phasedItemSchema>

export const productOperationSchema = z.object({
	id: z.string().optional(),
	name: z
		.string({
			required_error: 'Please enter a name',
		})
		.trim()
		.min(1, 'Please enter a name'),
	phases: z.object({
		before: phaseQuantitySchema(timeUnitSchema).optional(),
		during: z
			.array(
				z
					.object({
						machine: z.object({ id: z.string() }),
						duration: phaseQuantitySchema(productionTimeUnitSchema),
					})
					.refine(
						phase =>
							phase.machine.id.trim() === '' || phase.duration.quantity > 0,
						{
							message: 'Duration must be > 0',
							path: ['duration', 'quantity'],
						},
					),
			)
			.refine(
				durations =>
					durations.length > 0 && durations[0].machine.id.trim() !== '',
				{
					message: 'Please select a machine',
					path: ['0', 'machine'],
				},
			)
			.transform(durations =>
				durations.filter(duration => duration.machine.id.trim() !== ''),
			)
			.refine(durations => durations.length > 0, {
				message: 'Please select a machine',
			})
			.refine(
				durations => {
					const uniqueIds = new Set(
						durations.map(duration => duration.machine.id),
					)
					return uniqueIds.size === durations.length
				},
				{
					message: 'Machines must be unique',
				},
			),
		after: phaseQuantitySchema(timeUnitSchema).optional(),
	}),
	tools: z
		.array(phasedItemSchema)
		.transform(tools => tools.filter(tool => tool.name.trim() !== '')),
	staffGroups: z
		.array(phasedItemSchema)
		.transform(staffGroups =>
			staffGroups.filter(staffGroup => staffGroup.name.trim() !== ''),
		),
	transition: z
		.object({
			kind: z
				.union([z.literal('hard-linked'), z.literal('soft-linked')])
				.default('soft-linked'),
			waitingTime: phaseQuantitySchema(timeUnitSchema).default({
				quantity: 0,
				unit: 'minutes',
			}),
			overlap: phaseQuantitySchema(overlapTimeUnitSchema).default({
				quantity: 0,
				unit: 'percent',
			}),
		})
		.optional(),
})

export type ProductOperationOutputSchema = z.output<
	typeof productOperationSchema
>
export type ProductOperationInputSchema = z.input<typeof productOperationSchema>

export const productSchema = z.object({
	name: z
		.string({
			required_error: 'Please enter a name',
			invalid_type_error: 'Name must be a string',
		})
		.trim()
		.min(1, { message: 'Please enter a name' }),
	productNumber: z
		.string({
			required_error: 'Please enter a product number',
			invalid_type_error: 'Product number must be a string',
		})
		.trim()
		.min(1, { message: 'Please enter a product number' })
		.refine(value => /\S/.test(value), {
			message: 'Product number cannot be just spaces',
		}),
	operations: z.array(productOperationSchema),
})

export type ProductSchema = z.infer<typeof productSchema>
