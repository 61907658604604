import { forwardRef, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { TMachineBooking } from '@repo/types'
import { format, isSameDay } from 'date-fns'
import {
	ArrowRight,
	Calendar,
	CircleCheck,
	GanttChart,
	Hash,
	MoreVertical,
	Package,
	Pencil,
	PenLine,
	Plus,
	RotateCcw,
	Route,
	ServerCog,
	Sliders,
	Users,
} from 'lucide-react'
import { Controller, useForm } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import { z } from 'zod'

import { useAppSelector } from '@/app/hooks'
import { ClickToCopyBadge } from '@/components/click-to-copy-badge'
import { GenericDialog } from '@/components/generic-dialog'
import { StatusBadge } from '@/components/status-badge'
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { DialogFooter } from '@/components/ui/dialog'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Progress } from '@/components/ui/progress'
import { selectMachine } from '@/features/machines/machines-slice'
import { OperatorAvatarGroup } from '@/features/operators/components/operator-avatar'
import { selectBookingOperators } from '@/features/operators/operators-slice'
import { selectOrderById } from '@/features/orders/orders-slice'
import { ChangeStartDateDialog } from '@/features/planning/components/change-start-date-dialog'
import { selectProductOperation } from '@/features/products/product-operations-slice'
import { selectProduct } from '@/features/products/products-slice'
import {
	decimalSeparator,
	formatNumericString,
	thousandSeparator,
} from '@/utils/format-numeric-string'
import { stopPropagation } from '@/utils/stop-propagation'

const getFormSchema = (quantity: number) =>
	z.object({
		amount: z
			.number()
			.min(-quantity, {
				message: `Amount must be greater than or equal to -${formatNumericString(quantity)}`,
			})
			.max(quantity, {
				message: `Amount must be less than or equal to ${formatNumericString(quantity)}`,
			})
			.refine(val => val !== 0, {
				message: 'Amount cannot be zero',
			}),
	})

type FormValues = z.infer<ReturnType<typeof getFormSchema>>

function AdjustProgressByAmountDialog({
	quantity,
	container,
	onClose,
	onSubmit,
}: {
	quantity: number
	container?: HTMLElement | null
	onClose: () => void
	onSubmit: (amount: number) => void
}) {
	const {
		handleSubmit,
		watch,
		control,
		formState: { errors, isDirty },
	} = useForm<FormValues>({
		resolver: zodResolver(getFormSchema(quantity)),
		defaultValues: {
			amount: undefined,
		},
	})

	const amount = watch('amount')
	const isNegative = amount < 0

	const onSubmitForm = handleSubmit(data => {
		onSubmit(data.amount)
		onClose()
	})

	return (
		<GenericDialog
			title="Add or remove units"
			description="Enter a positive number to add units, or a negative number to remove units from the current progress."
			container={container}
			onClose={onClose}
		>
			<form onSubmit={onSubmitForm}>
				<div className="space-y-4">
					<div className="space-y-2">
						<div className="flex items-center justify-between">
							<Label htmlFor="amount">Change in Units</Label>
							<Badge variant={isNegative ? 'destructive' : 'default'}>
								{isNegative ? 'Removing Units' : 'Adding Units'}
							</Badge>
						</div>

						<Controller
							name="amount"
							control={control}
							render={({ field: { onChange, value } }) => (
								<NumericFormat
									id="amount"
									customInput={Input}
									value={value}
									placeholder={`Add (+) or remove (-) up to ${formatNumericString(quantity)} units`}
									onValueChange={values => {
										onChange(values.floatValue)
									}}
									decimalSeparator={decimalSeparator}
									thousandSeparator={thousandSeparator}
									error={Boolean(errors.amount)}
									className={isNegative ? 'border-destructive' : undefined}
									allowNegative
									min={-quantity}
									max={quantity}
								/>
							)}
						/>

						{errors.amount && (
							<p className="text-sm text-destructive">
								{errors.amount.message}
							</p>
						)}
					</div>

					<DialogFooter>
						<Button type="button" variant="outline" onClick={onClose}>
							Cancel
						</Button>
						<Button
							type="submit"
							disabled={!amount || !isDirty}
							variant={isNegative ? 'destructive' : 'default'}
						>
							{isNegative ? 'Remove Units' : 'Add Units'}
						</Button>
					</DialogFooter>
				</div>
			</form>
		</GenericDialog>
	)
}

function SetProgressAmountDialog({
	quantity,
	container,
	onClose,
	onSubmit,
}: {
	quantity: number
	container?: HTMLElement | null
	onClose: () => void
	onSubmit: (amount: number) => void
}) {
	const {
		handleSubmit,
		watch,
		control,
		formState: { errors, isDirty },
	} = useForm<FormValues>({
		resolver: zodResolver(
			z.object({
				amount: z
					.number()
					.min(0, {
						message: 'Total units must be 0 or greater',
					})
					.max(quantity, {
						message: `Total units cannot exceed ${formatNumericString(quantity)}`,
					}),
			}),
		),
		defaultValues: {
			amount: undefined,
		},
	})

	const amount = watch('amount')

	const onSubmitForm = handleSubmit(data => {
		onSubmit(data.amount)
		onClose()
	})

	return (
		<GenericDialog
			title="Set total completed units"
			description="Enter the absolute total number of units completed so far. This will override the current progress."
			container={container}
			onClose={onClose}
		>
			<form onSubmit={onSubmitForm}>
				<div className="space-y-4">
					<div className="space-y-2">
						<Label htmlFor="amount">Total Completed Units</Label>
						<Controller
							name="amount"
							control={control}
							render={({ field: { onChange, value } }) => (
								<NumericFormat
									id="amount"
									customInput={Input}
									value={value}
									placeholder={`Enter total completed units (max ${formatNumericString(quantity)})`}
									onValueChange={values => {
										onChange(values.floatValue)
									}}
									decimalSeparator={decimalSeparator}
									thousandSeparator={thousandSeparator}
									error={Boolean(errors.amount)}
									allowNegative={false}
									min={0}
									max={quantity}
								/>
							)}
						/>

						{errors.amount && (
							<p className="text-sm text-destructive">
								{errors.amount.message}
							</p>
						)}
					</div>

					<DialogFooter>
						<Button type="button" variant="outline" onClick={onClose}>
							Cancel
						</Button>
						<Button type="submit" disabled={!amount || !isDirty}>
							Set Total Units
						</Button>
					</DialogFooter>
				</div>
			</form>
		</GenericDialog>
	)
}

export const ProgressCard = forwardRef(function ProgressCard(
	props: {
		booking: TMachineBooking
		container?: HTMLElement | null
		onResetBooking?: () => void
		onStartBooking?: () => void
		onCompleteBookingSetup?: () => void
		onAdjustProgressByPercent?: (percent: number) => void
		onAdjustProgressByAmount?: (amount: number) => void
		onSetProgressAmount?: (amount: number) => void
		onStartBookingProduction?: () => void
		onCompleteBookingProduction?: () => void
		onCompleteBooking?: () => void
		onShowInPlan?: () => void
		onEditOrder?: () => void
		onAssignOperators?: () => void
	},
	ref: React.Ref<HTMLDivElement>,
) {
	const {
		booking,
		container,
		onResetBooking,
		onStartBooking,
		onCompleteBookingSetup,
		onStartBookingProduction,
		onAdjustProgressByPercent,
		onAdjustProgressByAmount,
		onSetProgressAmount,
		onCompleteBookingProduction,
		onCompleteBooking,
		onShowInPlan,
		onEditOrder,
		onAssignOperators,
	} = props
	const machine = useAppSelector(selectMachine(booking.machineId))
	const operation = useAppSelector(selectProductOperation(booking.operationId))
	const order = useAppSelector(state => selectOrderById(state, booking.orderId))
	const product = useAppSelector(selectProduct(booking.productId))
	const bookingOperators = useAppSelector(state =>
		selectBookingOperators(state, booking.operators),
	)
	const [changeStartDateDialogOpen, setChangeStartDateDialogOpen] =
		useState(false)
	const [
		adjustProgressByAmountDialogOpen,
		setAdjustProgressByAmountDialogOpen,
	] = useState(false)
	const [setProgressAmountDialogOpen, setSetProgressAmountDialogOpen] =
		useState(false)
	const planningParameters =
		order?.status === 'planned' ||
		order?.status === 'in-progress' ||
		order?.status === 'completed'
			? order.planningParameters
			: null
	const plannedQuantity = planningParameters?.quantity ?? order?.quantity ?? 0
	const needsSetup = booking.effectiveTimeMinutes.before > 0
	const needsTeardown = booking.effectiveTimeMinutes.after > 0

	function renderInProgress() {
		if (booking.status !== 'in-progress') {
			return null
		}

		const { before, during, after } = booking.progress

		if (before && !before.completed) {
			return (
				<div className="space-y-2 rounded-lg bg-muted p-4">
					<div>
						<h3 className="font-semibold">Machine Setup</h3>
						<p className="text-sm text-muted-foreground">
							Scheduled{' '}
							{format(booking.phases.before.startDate, 'd MMM, HH:mm')} &mdash;{' '}
							{format(booking.phases.before.endDate, 'd MMM, HH:mm')}
						</p>
					</div>
					<Button
						className="w-full"
						onClick={onCompleteBookingSetup}
						disabled={!onCompleteBookingSetup}
					>
						Complete & Start Production
					</Button>
				</div>
			)
		}

		if (during && !during.startDate) {
			return (
				<div className="space-y-2 rounded-lg bg-muted p-4">
					<div>
						<h3 className="font-semibold">Production Start</h3>
						<p className="text-sm text-muted-foreground">
							Scheduled{' '}
							{format(booking.phases.during.startDate, 'd MMM, HH:mm')}
						</p>
					</div>
					<Button
						className="w-full"
						onClick={onStartBookingProduction}
						disabled={!onStartBookingProduction}
					>
						Start Production
					</Button>
				</div>
			)
		}

		if (during && !during.completed) {
			return (
				<>
					<div className="space-y-2 rounded-lg bg-muted p-4">
						<div>
							<div className="mb-2 flex items-center justify-between">
								<h3 className="font-semibold">Production Progress</h3>
								<Badge variant="outline" className="bg-background">
									{Number.isInteger(during.percentage)
										? during.percentage
										: during.percentage.toFixed(2)}
									%
								</Badge>
							</div>
							<Progress
								value={during.percentage}
								className="h-2 bg-background"
							/>
							<div className="mt-2 text-sm text-muted-foreground">
								{formatNumericString(during.quantity)} of{' '}
								{formatNumericString(plannedQuantity)} units
							</div>
						</div>
						<div className="space-y-1">
							<div className="grid grid-cols-2 gap-1">
								<Button
									variant="outline"
									onClick={() => setAdjustProgressByAmountDialogOpen(true)}
									disabled={!onAdjustProgressByAmount}
								>
									<Sliders className="mr-2 h-4 w-4 shrink-0" />
									Adjust Units
								</Button>
								<Button
									variant="outline"
									disabled={!onSetProgressAmount}
									onClick={() => setSetProgressAmountDialogOpen(true)}
								>
									<PenLine className="mr-2 h-4 w-4 shrink-0" />
									Set Units
								</Button>
							</div>
							<div className="grid grid-cols-4 gap-1">
								<Button
									variant="outline"
									disabled={
										during.percentage <= 0 || !onAdjustProgressByPercent
									}
									onClick={() => onAdjustProgressByPercent?.(-10)}
								>
									-10%
									<span className="sr-only">Decrease by 10%</span>
								</Button>
								<Button
									variant="outline"
									disabled={
										during.percentage <= 0 || !onAdjustProgressByPercent
									}
									onClick={() => onAdjustProgressByPercent?.(-1)}
								>
									-1%
									<span className="sr-only">Decrease by 1%</span>
								</Button>
								<Button
									variant="outline"
									disabled={
										during.percentage >= 100 || !onAdjustProgressByPercent
									}
									onClick={() => onAdjustProgressByPercent?.(1)}
								>
									+1%
									<span className="sr-only">Increase by 1%</span>
								</Button>
								<Button
									variant="outline"
									disabled={
										during.percentage >= 100 || !onAdjustProgressByPercent
									}
									onClick={() => onAdjustProgressByPercent?.(10)}
								>
									+10%
									<span className="sr-only">Increase by 10%</span>
								</Button>
							</div>
						</div>
						<Button
							className="w-full"
							disabled={!onCompleteBookingProduction}
							onClick={onCompleteBookingProduction}
						>
							{needsTeardown
								? 'Complete & Start Teardown'
								: 'Complete Production'}
						</Button>
					</div>
				</>
			)
		}

		if (after && !after.completed) {
			return (
				<div className="space-y-2 rounded-lg bg-muted p-4">
					<div>
						<h3 className="font-semibold">Machine Teardown</h3>
						<p className="text-sm text-muted-foreground">
							Scheduled {format(booking.phases.after.startDate, 'd MMM, HH:mm')}{' '}
							&mdash; {format(booking.phases.after.endDate, 'd MMM, HH:mm')}
						</p>
					</div>
					<Button
						className="w-full"
						disabled={!onCompleteBooking}
						onClick={onCompleteBooking}
					>
						Complete Teardown
					</Button>
				</div>
			)
		}

		return null
	}

	return (
		<>
			<Card ref={ref} className="relative flex flex-col justify-between">
				<CardHeader className="p-4 pb-0">
					<CardTitle>
						<div className="flex items-center gap-2">
							#{order?.productionOrderNumber}
							<StatusBadge status={booking.status} />
						</div>
					</CardTitle>
					<div className="text-sm">
						<Calendar className="mr-2 inline h-4 w-4 shrink-0 align-text-bottom" />
						<span>
							{format(booking.startDate, 'd MMM yyyy, HH:mm')} &mdash;{' '}
							{isSameDay(booking.startDate, booking.endDate)
								? format(booking.endDate, 'HH:mm')
								: format(booking.endDate, 'd MMM, HH:mm')}
						</span>
					</div>
					<div className="text-sm">
						<Package className="mr-2 inline h-4 w-4 shrink-0 align-text-bottom" />
						<ClickToCopyBadge
							value={product?.productNumber ?? ''}
							displayValue={`#${product?.productNumber ?? ''}`}
							variant="outline"
							className="mr-2 max-w-[200px]"
						/>
						<span className="truncate">{product?.name}</span>
					</div>
					<div className="text-sm">
						<ServerCog className="mr-2 inline h-4 w-4 shrink-0 align-text-bottom" />
						<span>{machine?.name}</span>
					</div>
					<div className="text-sm">
						<Route className="mr-2 inline h-4 w-4 shrink-0 align-text-bottom" />
						<span>{operation?.name}</span>
					</div>
					<div className="text-sm">
						<Hash className="mr-2 inline h-4 w-4 shrink-0 align-text-bottom" />
						<span>{formatNumericString(plannedQuantity)} units</span>
					</div>
					<div className="pb-4 text-sm">
						<div className="flex items-center gap-2">
							<Users className="h-4 w-4 shrink-0" />
							<Button
								variant={booking.operators.length > 0 ? 'ghost' : 'outline'}
								className="h-6 px-2 text-sm"
								onClick={onAssignOperators}
								disabled={!onAssignOperators}
							>
								{booking.operators.length > 0 ? (
									<>
										{booking.operators.length}{' '}
										{booking.operators.length === 1 ? 'Operator' : 'Operators'}
									</>
								) : (
									<>
										<Plus className="mr-2 h-4 w-4 shrink-0" />
										Assign Operators
									</>
								)}
							</Button>
							{bookingOperators.length > 0 && (
								<OperatorAvatarGroup
									container={container}
									operators={bookingOperators}
									effectiveTimeMinutes={booking.effectiveTimeMinutes}
								/>
							)}
						</div>
					</div>
					{order?.comment && (
						<Accordion type="single" collapsible className="w-full">
							<AccordionItem value="comment" className="border-b-0">
								<AccordionTrigger className="py-1 hover:no-underline">
									<div className="flex items-center gap-2 text-sm text-muted-foreground">
										<PenLine className="h-4 w-4 shrink-0" />
										<span className="line-clamp-1 text-left italic">
											{order.comment}
										</span>
									</div>
								</AccordionTrigger>
								<AccordionContent className="pb-0 pt-2">
									<div
										className="text-sm italic text-muted-foreground"
										style={{ whiteSpace: 'pre-line' }}
									>
										{order.comment}
									</div>
								</AccordionContent>
							</AccordionItem>
						</Accordion>
					)}
					{booking.status !== 'planned' && booking.startDate && (
						<div className="mb-4 text-sm">
							<Button
								className="h-8 w-full px-2"
								variant="outline"
								onClick={() => setChangeStartDateDialogOpen(true)}
							>
								<Calendar className="mr-2 h-4 w-4 shrink-0" />{' '}
								<span className="mr-1 text-muted-foreground">Started at:</span>
								{format(booking.startDate, 'd MMM yyyy, HH:mm')}
							</Button>
						</div>
					)}
				</CardHeader>

				<CardContent className="space-y-2 p-4">
					<div>
						{booking.status === 'completed' && (
							<div className="my-1 text-xs text-muted-foreground">
								Actual:{' '}
								{format(
									booking.progress.before?.startDate ??
										booking.progress.during.startDate,
									'd MMM yyyy, HH:mm',
								)}{' '}
								&mdash;{' '}
								{format(
									booking.progress.after?.endDate ??
										booking.progress.during.endDate,
									'd MMM yyyy, HH:mm',
								)}
							</div>
						)}
					</div>

					<div className="space-y-2">
						{onStartBooking && (
							<div className="flex flex-col gap-1">
								<Button className="w-full" onClick={onStartBooking}>
									Start {needsSetup ? 'Setup' : 'Production'}
								</Button>
							</div>
						)}
						{booking.status === 'in-progress' && (
							<>
								<span className="text-xs font-medium uppercase text-muted-foreground">
									Current task
								</span>
								{renderInProgress()}
							</>
						)}
						{booking.status === 'completed' && (
							<div className="flex flex-col gap-1">
								<Button variant="outline" className="w-full" disabled>
									<CircleCheck className="mr-2 h-4 w-4" /> Completed
								</Button>
							</div>
						)}
					</div>
					{booking.status !== 'completed' && (
						<Card className="opacity-50">
							<CardHeader className="px-4 py-2">
								<CardTitle className="flex items-center justify-center text-sm">
									<ArrowRight className="mr-2 h-4 w-4" /> Next Up:&nbsp;
									<span className="font-normal">
										{(() => {
											if (booking.status === 'planned') {
												return needsSetup ? 'Machine Setup' : 'Start Production'
											}

											if (booking.status === 'in-progress') {
												const { before, during, after } = booking.progress

												if (before && !before.completed) {
													return 'Production'
												}

												if (during && !during.startDate) {
													return 'Production'
												}

												if (during?.startDate && !during.completed) {
													return needsTeardown
														? 'Machine Teardown'
														: 'Completed'
												}

												if (after && !after.completed) {
													return 'Completed'
												}
											}

											return null
										})()}
									</span>
								</CardTitle>
							</CardHeader>
						</Card>
					)}
				</CardContent>

				<DropdownMenu>
					<DropdownMenuTrigger className="absolute right-2 top-2" asChild>
						<Button variant="ghost" className="h-8 w-8 p-2">
							<MoreVertical className="h-4 w-4 shrink-0" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end" container={container}>
						{onShowInPlan && (
							<DropdownMenuItem onClick={stopPropagation(onShowInPlan)}>
								<GanttChart className="mr-2 h-4 w-4" />
								<span>Show In Plan</span>
							</DropdownMenuItem>
						)}
						{onEditOrder && (
							<DropdownMenuItem onClick={stopPropagation(onEditOrder)}>
								<Pencil className="mr-2 h-4 w-4" />
								<span>Edit Order</span>
							</DropdownMenuItem>
						)}
						<DropdownMenuItem
							onClick={stopPropagation(onResetBooking)}
							data-disabled={!onResetBooking}
						>
							<RotateCcw className="mr-2 h-4 w-4" />
							<span>Reset</span>
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			</Card>
			{changeStartDateDialogOpen && (
				<ChangeStartDateDialog
					booking={booking}
					container={container}
					onClose={() => setChangeStartDateDialogOpen(false)}
					onSubmit={() => setChangeStartDateDialogOpen(false)}
				/>
			)}
			{adjustProgressByAmountDialogOpen && (
				<AdjustProgressByAmountDialog
					quantity={plannedQuantity}
					container={container}
					onClose={() => setAdjustProgressByAmountDialogOpen(false)}
					onSubmit={amount => {
						onAdjustProgressByAmount?.(amount)
						setAdjustProgressByAmountDialogOpen(false)
					}}
				/>
			)}
			{setProgressAmountDialogOpen && (
				<SetProgressAmountDialog
					quantity={plannedQuantity}
					container={container}
					onClose={() => setSetProgressAmountDialogOpen(false)}
					onSubmit={amount => {
						onSetProgressAmount?.(amount)
						setSetProgressAmountDialogOpen(false)
					}}
				/>
			)}
		</>
	)
})
