import {
	TCalendarAdjustment,
	TInProgressMachineBooking,
	TMachineAvailability,
	TPeriod,
} from '@repo/types'
import { addMinutes, differenceInMinutes, max } from 'date-fns'

import { adjustOpenPeriods } from './adjust-open-periods'
import { getCalendarAdjustmentsForMachine } from './get-calendar-adjustments-for-machine'
import { getOpenPeriods } from './get-open-periods'

/**
 * Calculates the exact end date of progress by mapping completed work minutes
 * onto actual working periods, skipping over closed times (nights, weekends, holidays).
 *
 * This is crucial for Gantt chart visualizations where:
 * - Total bar width represents calendar duration (including closures)
 * - Progress fill must only account for actual working time
 * - Phase completion (setup/teardown) affects progress calculation
 *
 * @param args.booking - In-progress booking with phase completion states
 * @param args.machineId - Target machine ID for availability rules
 * @param args.availability - Machine's base schedule (without adjustments)
 * @param args.calendarAdjustments - Calendar exceptions (holidays, overtime etc)
 *
 * @returns {Date} - Exact datetime when progress minutes are exhausted
 *
 * @remarks
 * - All dates are treated as UTC (no timezone conversion)
 * - Setup/teardown phases are all-or-nothing in progress calculations
 * - Production phase uses percentage-based progress
 * - Closed periods are automatically skipped in the timeline
 * - If no working periods exist, returns original start date
 *
 * @example
 * // Visualizing 4h progress on a task spanning a weekend:
 * // [Fri 18:00]======[Sat/Sun closed]======[Mon 10:00]
 * // Progress fill would jump from Fri 18:00 to Mon 10:00
 */
export function getProgressEndDate(args: {
	booking: TInProgressMachineBooking
	machineId: string
	availability: TMachineAvailability
	calendarAdjustments: TCalendarAdjustment[]
}): Date {
	const { booking, machineId, availability, calendarAdjustments } = args

	// Calculate total effective progress minutes across all phases
	const totalProgress = calculateTotalProgress(booking)

	// Get actual working periods considering availability and adjustments
	const workingPeriods = getEffectiveWorkingPeriods(
		booking,
		machineId,
		availability,
		calendarAdjustments,
	)

	// Map progress minutes to actual calendar time
	return calculateProgressEndDate(
		booking.startDate,
		totalProgress,
		workingPeriods,
	)
}

function calculateTotalProgress(booking: TInProgressMachineBooking): number {
	let progress = 0

	// Setup phase (all or nothing)
	if (booking.progress.before?.completed) {
		progress += booking.effectiveTimeMinutes.before
	}

	// Production phase (partial progress)
	progress +=
		(booking.progress.during.percentage * booking.effectiveTimeMinutes.during) /
		100

	// Teardown phase (all or nothing)
	if (booking.progress.after?.completed) {
		progress += booking.effectiveTimeMinutes.after
	}

	return progress
}

function getEffectiveWorkingPeriods(
	booking: TInProgressMachineBooking,
	machineId: string,
	availability: TMachineAvailability,
	calendarAdjustments: TCalendarAdjustment[],
): Array<TPeriod> {
	// Base availability within booking timeframe
	const basePeriods = getOpenPeriods({
		startDate: new Date(booking.startDate),
		endDate: new Date(booking.endDate),
		availability,
	})

	// Apply calendar adjustments
	return adjustOpenPeriods({
		startDate: new Date(booking.startDate),
		endDate: new Date(booking.endDate),
		openPeriods: basePeriods,
		calendarAdjustments: getCalendarAdjustmentsForMachine({
			calendarAdjustments,
			machineId,
		}),
	})
}

function calculateProgressEndDate(
	startDate: string,
	totalMinutes: number,
	periods: Array<TPeriod>,
): Date {
	let remaining = totalMinutes
	let currentDate = new Date(startDate)

	for (const period of periods) {
		const periodStart = max([currentDate, period.startDate])
		const periodEnd = period.endDate

		const availableMinutes = differenceInMinutes(periodEnd, periodStart)
		if (availableMinutes <= 0) continue

		const consumed = Math.min(remaining, availableMinutes)
		remaining -= consumed

		currentDate = addMinutes(periodStart, consumed)

		if (remaining <= 0) break
	}

	return currentDate
}
