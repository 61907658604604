import { forwardRef } from 'react'

import { TCalendarAdjustment, TMachine } from '@repo/types'
import { format } from 'date-fns'
import { CheckCheck, Computer, MoreVertical, Trash2 } from 'lucide-react'

import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { stopPropagation } from '@/utils/stop-propagation'

const CalendarAdjustmentCard = forwardRef(function CalendarAdjustmentCard(
	props: {
		calendarAdjustment: TCalendarAdjustment
		machines: TMachine[]
		onDelete: () => void
	} & { container?: HTMLElement | null },
	ref: React.Ref<HTMLDivElement>,
) {
	const startYear = format(props.calendarAdjustment.startDate, 'yyyy')
	const startWeek = format(props.calendarAdjustment.startDate, 'I')
	const endYear = format(props.calendarAdjustment.endDate, 'yyyy')
	const endWeek = format(props.calendarAdjustment.endDate, 'I')

	return (
		<Card ref={ref} className="relative min-w-0 p-4">
			<div className="flex flex-col items-start gap-2">
				{props.calendarAdjustment.description && (
					<h2
						title={props.calendarAdjustment.description}
						className="w-full truncate text-sm"
					>
						{props.calendarAdjustment.description}
					</h2>
				)}
				<div className="flex w-full flex-col">
					<span className="w-full truncate text-xs" title="Period">
						<time dateTime={props.calendarAdjustment.startDate}>
							{format(props.calendarAdjustment.startDate, 'EEE d MMM, HH:mm')}
						</time>{' '}
						&ndash;{' '}
						<time dateTime={props.calendarAdjustment.endDate}>
							{format(props.calendarAdjustment.endDate, 'EEE d MMM, HH:mm')}
						</time>
					</span>
					{startYear === endYear && startWeek === endWeek ? (
						<span className="w-full truncate text-xs text-muted-foreground">
							Week {startWeek}, {startYear}
						</span>
					) : (
						<span className="w-full truncate text-xs text-muted-foreground">
							Week {startWeek}, {startYear} &ndash; Week {endWeek}, {endYear}
						</span>
					)}
				</div>
				{props.calendarAdjustment.status === 'open' && (
					<Badge className="text-yelemeraldlow-800 whitespace-nowrap bg-emerald-100 hover:bg-emerald-100 dark:bg-emerald-800 dark:text-emerald-100 dark:hover:bg-emerald-800">
						Open
					</Badge>
				)}
				{props.calendarAdjustment.status === 'closed' && (
					<Badge className="whitespace-nowrap bg-red-100 text-red-800 hover:bg-red-100 dark:bg-red-800 dark:text-red-100 dark:hover:bg-red-800">
						Closed
					</Badge>
				)}
				{props.machines.length > 0 && (
					<div className="flex flex-wrap gap-1">
						{props.machines.map(machine => (
							<Badge
								key={machine.id}
								title={machine.name}
								variant="outline"
								className="overflow-hidden whitespace-nowrap"
							>
								<Computer className="mr-2 h-4 w-4 min-w-4" />
								<span className="truncate">{machine.name}</span>
							</Badge>
						))}
					</div>
				)}
				{props.machines.length === 0 && (
					<Badge
						title="Affects all machines"
						variant="outline"
						className="overflow-hidden whitespace-nowrap"
					>
						<CheckCheck className="mr-2 h-4 w-4 min-w-4" />
						<span>All Machines</span>
					</Badge>
				)}
			</div>
			<DropdownMenu>
				<DropdownMenuTrigger className="absolute right-2 top-2" asChild>
					<Button variant="ghost" className="h-8 w-8 p-2">
						<MoreVertical className="h-4 w-4 shrink-0" />
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="end" container={props.container}>
					<DropdownMenuItem onClick={stopPropagation(props.onDelete)}>
						<Trash2 className="mr-2 h-4 w-4" />
						<span>Delete</span>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
		</Card>
	)
})

export { CalendarAdjustmentCard }
