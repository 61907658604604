import { useEffect, useMemo, useRef, useState } from 'react'

import { TMachineBooking } from '@repo/types'
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'
import { format } from 'date-fns'
import { LayoutGrid, X } from 'lucide-react'
import { z } from 'zod'

import { useAppSelector } from '@/app/hooks'
import { PageLayout } from '@/components/layout'
import {
	ScrollableToolbar,
	ScrollableToolbarContentLeft,
	ScrollableToolbarContentRight,
} from '@/components/scrollable-toolbar'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { MultiSelectFilter } from '@/components/ui/multi-select-filter'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@/components/ui/tooltip'
import { selectMachines } from '@/features/machines/machines-slice'
import { selectOperators } from '@/features/operators/operators-slice'
import { selectAllOrders } from '@/features/orders/orders-slice'
import {
	calculatePeriod,
	selectCategorizedBookings,
} from '@/features/planning/planning-slice'
import { ProgressCard } from '@/features/progress/components/progress-card'
import { useProgressActions } from '@/features/progress/hooks/use-progress-actions'

const progressSearchSchema = z.object({
	orderIds: z.array(z.string()).optional(),
	machineIds: z.array(z.string()).optional(),
	operatorIds: z.array(z.string()).optional(),
	changeStartDate: z.string().optional(),
	dateFilter: z.enum(['all', '+24h', '-24h', '+7d', '-7d']).default('+24h'),
})

const Route = createFileRoute('/progress')({
	component: ProgressComponent,
	validateSearch: zodValidator(progressSearchSchema),
})

function ProgressComponent() {
	const search = Route.useSearch()
	const navigate = useNavigate({ from: Route.fullPath })
	const isFiltered =
		search.orderIds?.length ||
		search.machineIds?.length ||
		search.operatorIds?.length ||
		search.dateFilter !== '+24h'
	const period = calculatePeriod(search.dateFilter)
	const filterCriteria = useMemo(
		() => ({
			orderIds: search.orderIds,
			machineIds: search.machineIds,
			operatorIds: search.operatorIds,
			dateFilter: search.dateFilter,
		}),
		[search.orderIds, search.machineIds, search.operatorIds, search.dateFilter],
	)

	const { planned, inProgress, completed } = useAppSelector(state =>
		selectCategorizedBookings(state, filterCriteria),
	)
	const machines = useAppSelector(selectMachines)
	const orders = useAppSelector(selectAllOrders)
	const operators = useAppSelector(selectOperators)

	const {
		resetBooking,
		startBooking,
		completeBookingSetup,
		startBookingProduction,
		completeBookingProduction,
		completeBooking,
		adjustProgressByPercent,
		adjustProgressByAmount,
		setProgressAmount,
	} = useProgressActions()

	const [activeBookingId, setActiveBookingId] = useState<string | null>(null)
	const activeBookingRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		if (activeBookingId && activeBookingRef.current) {
			const element = activeBookingRef.current
			const bounding = element.getBoundingClientRect()
			const padding = 72

			const isOutsideViewport =
				bounding.top < 0 || bounding.bottom > window.innerHeight
			if (isOutsideViewport) {
				const offsetTop = bounding.top + window.scrollY - padding

				window.scrollTo({
					top: offsetTop,
					behavior: 'smooth',
				})
			}
			element.classList.add(
				'transition-colors',
				'bg-background/20',
				'border-muted-foreground',
			)
			setTimeout(() => {
				element.classList.remove('bg-background/20', 'border-muted-foreground')
				setTimeout(() => {
					element.classList.remove('transition-colors')
					setActiveBookingId(null)
				}, 150)
			}, 1800)
		}
	}, [activeBookingId])

	function showInPlan(booking: TMachineBooking) {
		navigate({
			to: '/planning',
			search: {
				bookingId: booking.id,
				orderId: booking.orderId,
			},
		})
	}

	function editOrder(booking: TMachineBooking) {
		navigate({
			search: old => ({ ...old, editOrder: booking.orderId }),
		})
	}

	function assignOperators(booking: TMachineBooking) {
		navigate({
			search: old => ({ ...old, assignOperators: booking.id }),
		})
	}

	return (
		<>
			<PageLayout>
				<ScrollableToolbar>
					<ScrollableToolbarContentLeft>
						<h1 className="text-xl sm:text-2xl">Progress</h1>
					</ScrollableToolbarContentLeft>
					<ScrollableToolbarContentRight>
						{isFiltered && (
							<Button
								variant="ghost"
								onClick={() => {
									navigate({
										search: old => ({
											...old,
											orderIds: undefined,
											machineIds: undefined,
											operatorIds: undefined,
											dateFilter: undefined,
										}),
									})
								}}
								className="h-8 px-2 lg:px-3"
							>
								Reset
								<X className="ml-2 h-4 w-4" />
							</Button>
						)}
						<MultiSelectFilter
							title="Operator"
							align="end"
							options={operators.map(operator => ({
								value: operator.id,
								label: operator.name,
							}))}
							selected={search.operatorIds || []}
							onChange={selectedIds => {
								navigate({
									search: old => ({
										...old,
										operatorIds: selectedIds,
									}),
								})
							}}
						/>
						<MultiSelectFilter
							title="Order"
							align="end"
							options={orders.map(order => ({
								value: order.id,
								label: order.productionOrderNumber,
							}))}
							selected={search.orderIds || []}
							onChange={selectedIds => {
								navigate({
									search: old => ({ ...old, orderIds: selectedIds }),
								})
							}}
						/>
						<MultiSelectFilter
							title="Machine"
							align="end"
							options={machines.map(machine => ({
								value: machine.id,
								label: machine.name,
							}))}
							selected={search.machineIds || []}
							onChange={selectedIds => {
								navigate({
									search: old => ({ ...old, machineIds: selectedIds }),
								})
							}}
						/>
						<Select
							key={search.dateFilter}
							value={search.dateFilter}
							onValueChange={value => {
								navigate({
									search: old => ({
										...old,
										dateFilter: value
											? (value as NonNullable<typeof search.dateFilter>)
											: undefined,
									}),
								})
							}}
						>
							<SelectTrigger className="w-[196px] shrink-0">
								<div>
									<span className="mr-2 text-muted-foreground">Time:</span>
									<SelectValue placeholder="Select Time" />
								</div>
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="all">All Time</SelectItem>
								<Separator />
								<SelectItem value="+24h">Next 24 hours</SelectItem>
								<SelectItem value="-24h">Past 24 hours</SelectItem>
								<SelectItem value="+7d">Next 7 days</SelectItem>
								<SelectItem value="-7d">Past 7 days</SelectItem>
							</SelectContent>
						</Select>
						<Tabs value="grid">
							<TabsList aria-label="View options">
								<Tooltip>
									<TooltipTrigger>
										<TabsTrigger
											value="grid"
											aria-label="Switch to grid view"
											asChild
										>
											<Link to="." search={old => ({ ...old, view: 'grid' })}>
												<LayoutGrid className="h-4 w-4" />
											</Link>
										</TabsTrigger>
									</TooltipTrigger>
									<TooltipContent>
										<p>Grid View</p>
									</TooltipContent>
								</Tooltip>
							</TabsList>
						</Tabs>
					</ScrollableToolbarContentRight>
				</ScrollableToolbar>
				<div className="py-8">
					{inProgress.length === 0 &&
						planned.length === 0 &&
						completed.length === 0 && (
							<h2 className="text-md my-2 text-muted-foreground">
								No progress activity found.
							</h2>
						)}
					{inProgress.length > 0 && (
						<div className="mb-10">
							<div className="flex w-full items-center gap-2">
								<h2 className="my-2 text-lg">In Progress</h2>
								<Badge className="justify-center font-mono">
									{inProgress.length}
								</Badge>
								<span className="text-muted-foreground">Showing all</span>
							</div>
							<Separator />
							<div className="mt-4 grid w-full gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
								{inProgress.map(booking => (
									<ProgressCard
										key={booking.id}
										ref={
											booking.id === activeBookingId ? activeBookingRef : null
										}
										booking={booking}
										onResetBooking={() => {
											resetBooking(booking)
											setActiveBookingId(booking.id)
										}}
										onCompleteBookingSetup={() => {
											completeBookingSetup(booking)
											setActiveBookingId(booking.id)
										}}
										onStartBookingProduction={() => {
											startBookingProduction(booking)
											setActiveBookingId(booking.id)
										}}
										onAdjustProgressByPercent={percent => {
											adjustProgressByPercent({ booking, percent })
											setActiveBookingId(booking.id)
										}}
										onAdjustProgressByAmount={amount => {
											adjustProgressByAmount({ booking, amount })
											setActiveBookingId(booking.id)
										}}
										onSetProgressAmount={amount => {
											setProgressAmount({ booking, amount })
											setActiveBookingId(booking.id)
										}}
										onCompleteBookingProduction={() => {
											completeBookingProduction(booking)
											setActiveBookingId(booking.id)
										}}
										onCompleteBooking={() => {
											completeBooking(booking)
											setActiveBookingId(booking.id)
										}}
										onShowInPlan={() => showInPlan(booking)}
										onEditOrder={() => editOrder(booking)}
										onAssignOperators={() => assignOperators(booking)}
									/>
								))}
							</div>
						</div>
					)}
					{planned.length > 0 && (
						<div className="mb-10">
							<div className="flex w-full items-center gap-2">
								<h2 className="my-2 text-lg">Planned</h2>
								<Badge className="justify-center font-mono">
									{planned.length}
								</Badge>
								<span className="text-muted-foreground">
									{period ? (
										<>past &mdash; {format(period.endDate, 'd MMM yyyy')}</>
									) : (
										<>Showing all</>
									)}
								</span>
							</div>
							<Separator />
							<div className="mt-4 grid w-full gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
								{planned.map(booking => (
									<ProgressCard
										key={booking.id}
										ref={
											booking.id === activeBookingId ? activeBookingRef : null
										}
										booking={booking}
										onStartBooking={() => {
											startBooking(booking)
											setActiveBookingId(booking.id)
										}}
										onShowInPlan={() => showInPlan(booking)}
										onEditOrder={() => editOrder(booking)}
										onAssignOperators={() => assignOperators(booking)}
									/>
								))}
							</div>
						</div>
					)}
					{completed.length > 0 && (
						<div className="mb-10">
							<div className="flex w-full items-center gap-2">
								<h2 className="my-2 text-lg">Completed</h2>
								<Badge className="justify-center font-mono">
									{completed.length}
								</Badge>
								<span className="text-muted-foreground">
									{period ? (
										<>
											{search.dateFilter === '+24h' ||
											search.dateFilter === '+7d'
												? 'now'
												: format(period.startDate, 'd MMM yyyy')}{' '}
											&mdash;{' '}
											{search.dateFilter === '-24h' ||
											search.dateFilter === '-7d'
												? 'now'
												: format(period.endDate, 'd MMM yyyy')}
										</>
									) : (
										<>Showing all</>
									)}
								</span>
							</div>
							<Separator />
							<div className="mt-4 grid w-full gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
								{completed.map(booking => (
									<ProgressCard
										key={booking.id}
										ref={
											booking.id === activeBookingId ? activeBookingRef : null
										}
										booking={booking}
										onResetBooking={() => {
											resetBooking(booking)
											setActiveBookingId(booking.id)
										}}
										onShowInPlan={() => showInPlan(booking)}
										onEditOrder={() => editOrder(booking)}
									/>
								))}
							</div>
						</div>
					)}
				</div>
			</PageLayout>
		</>
	)
}

export { Route }
