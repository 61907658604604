import { useCallback } from 'react'

import { createId } from '@paralleldrive/cuid2'
import {
	TCalendarAdjustment,
	TMachineBooking,
	TPendingOrder,
	TPlannedMachineBooking,
	TPlannedOrder,
	TSchedulingDirection,
} from '@repo/types'
import {
	addDays,
	addMinutes,
	areIntervalsOverlapping,
	compareAsc,
	format,
	isAfter,
	isBefore,
	max,
	min,
	roundToNearestMinutes,
	setHours,
	setMinutes,
} from 'date-fns'
import { toast } from 'sonner'

import { useAppDispatch, useAppStore } from '@/app/hooks'
import { selectMachines } from '@/features/machines/machines-slice'
import {
	advanceOrderToPlanned,
	editOrder,
	revertOrderToPending,
	selectCategorizedOrders,
} from '@/features/orders/orders-slice'
import { selectProductOperations } from '@/features/products/product-operations-slice'

import {
	getCalendarAdjustmentsForMachine,
	isMachineAffectedByCalendarAdjustment,
} from '../get-calendar-adjustments-for-machine'
import { getPlanningParameters } from '../get-planning-parameters'
import {
	createBooking,
	createCalendarAdjustment,
	deleteBooking,
	deleteCalendarAdjustment,
	editBooking,
	selectAllBookings,
	selectCalendarAdjustments,
	validatePlan,
} from '../planning-slice'
import {
	convertToMinutes,
	scheduleOperation,
	scheduleOrder,
} from '../schedule-order'

/**
 * Checks if a given time falls within the start time window on its day
 */
function isWithinStartTimeWindow(
	time: Date,
	startTimeWindow: { from: string; to: string },
): boolean {
	const timeString = format(time, 'HH:mm')
	return timeString >= startTimeWindow.from && timeString <= startTimeWindow.to
}

/**
 * Gets the next valid start time that falls within the start time window
 * If the time is outside the window, moves to the next available window start
 */
function getNextValidStartTime(
	time: Date,
	startTimeWindow: { from: string; to: string },
): Date {
	const [fromHours, fromMinutes] = startTimeWindow.from.split(':').map(Number)
	const [toHours, toMinutes] = startTimeWindow.to.split(':').map(Number)

	const windowStart = setMinutes(setHours(time, fromHours), fromMinutes)
	const windowEnd = setMinutes(setHours(time, toHours), toMinutes)

	// If time is before today's window, return today's window start
	if (isBefore(time, windowStart)) {
		return windowStart
	}

	// If time is after today's window, return next day's window start
	if (isAfter(time, windowEnd)) {
		return setMinutes(setHours(addDays(time, 1), fromHours), fromMinutes)
	}

	return time
}

function useOrderPlanner() {
	const dispatch = useAppDispatch()
	const store = useAppStore()

	const planOrder = useCallback(
		async (args: {
			order: TPendingOrder
			direction: TSchedulingDirection
		}): Promise<TPlannedMachineBooking[] | null> => {
			return new Promise(resolve => {
				const _store = store.getState()
				const bookings = selectAllBookings(_store)
				const operations = selectProductOperations(_store)
				const calendarAdjustments = selectCalendarAdjustments(_store)

				const { order, direction } = args

				const planningParameters = getPlanningParameters({
					order,
					operations,
				})

				if (!planningParameters.operations.length) {
					toast.error(
						`Order #${order.productionOrderNumber} could not be planned because the product has no operations`,
					)
					resolve(null)
					return
				}

				const newBookings = scheduleOrder({
					planningParameters,
					calendarAdjustments,
					bookings: bookings.filter(b => b.orderId !== order.id),
					direction,
					earliestAllowedStartDate: new Date(),
				})

				if (!newBookings?.length) {
					toast.error(
						`Order #${order.productionOrderNumber} could not be planned`,
					)
					resolve(null)
					return
				}

				const plannedPeriod = {
					startDate: min(
						newBookings.map(booking => booking.startDate),
					).toISOString(),
					endDate: max(
						newBookings.map(booking => booking.endDate),
					).toISOString(),
				}

				const bookingIds = newBookings.map(booking => {
					const id = createId()
					dispatch(createBooking(booking, id))
					return id
				})

				dispatch(
					advanceOrderToPlanned({
						id: order.id,
						bookingIds,
						planningParameters,
						plannedPeriod,
					}),
				)

				dispatch(validatePlan())

				// Allow state to update before resolving
				setTimeout(() => {
					resolve(
						newBookings.map((b, index) => ({
							...b,
							id: bookingIds[index],
						})),
					)
				}, 0)
			})
		},
		[dispatch, store],
	)

	async function resetOrderPlan(orderId: string): Promise<void> {
		return new Promise(resolve => {
			const _store = store.getState()
			const bookings = selectAllBookings(_store)
			const { activeOrders } = selectCategorizedOrders(_store)

			const order = activeOrders.find(order => order.id === orderId)
			if (!order) {
				toast.error('Could not find order')
				resolve()
				return
			}

			const bookingsForOrder = bookings.filter(b => b.orderId === orderId)
			bookingsForOrder.forEach(b => dispatch(deleteBooking(b.id)))
			dispatch(revertOrderToPending(orderId))

			dispatch(validatePlan())

			// Allow state to update before resolving
			setTimeout(() => {
				resolve()
			}, 0)
		})
	}

	function alignBookings(args: {
		booking: TMachineBooking
		gap?: {
			quantity: number
			unit: 'minutes' | 'hours' | 'days'
		}
		startTimeWindow?: { from: string; to: string } // Format: "HH:mm"
		limit?: number
		pull?: boolean
		push?: boolean
	}) {
		const _store = store.getState()
		const bookings = selectAllBookings(_store)
		const machines = selectMachines(_store)

		const {
			booking,
			gap = { quantity: 0, unit: 'minutes' },
			startTimeWindow,
			limit,
			pull = true,
			push = true,
		} = args

		const gapInMinutes = convertToMinutes(gap)
		const machine = machines.find(machine => machine.id === booking.machineId)
		if (!machine) return

		const subsequentBookingsOnMachine = bookings
			.filter(
				b =>
					b.machineId === booking.machineId &&
					!isBefore(b.startDate, booking.startDate) &&
					b.id !== booking.id,
			)
			.sort((a, b) => compareAsc(a.startDate, b.startDate))

		let nextStartDate = booking.endDate

		// If limit is provided, only process that many bookings
		const bookingsToProcess = limit
			? subsequentBookingsOnMachine.slice(0, limit)
			: subsequentBookingsOnMachine

		bookingsToProcess.forEach(b => {
			// Calculate desired start date with gap
			let desiredStartDate = addMinutes(nextStartDate, gapInMinutes)

			// Ensure the desired start date is within setup window
			if (
				startTimeWindow &&
				!isWithinStartTimeWindow(desiredStartDate, startTimeWindow)
			) {
				desiredStartDate = getNextValidStartTime(
					desiredStartDate,
					startTimeWindow,
				)
			}

			const shouldMove =
				(push && isAfter(desiredStartDate, b.startDate)) ||
				(pull && isBefore(desiredStartDate, b.startDate))

			const canMove = b.status === 'planned'

			if (shouldMove && canMove) {
				// When pulling, verify we don't move beyond original position
				if (pull && isBefore(desiredStartDate, b.startDate)) {
					const originalStart = new Date(b.startDate)
					// If pulled time would be outside setup window, try to find valid time
					if (
						startTimeWindow &&
						!isWithinStartTimeWindow(desiredStartDate, startTimeWindow)
					) {
						const validStart = getNextValidStartTime(
							desiredStartDate,
							startTimeWindow,
						)
						// Only pull if we can find a valid time that's not past original position
						if (isAfter(validStart, originalStart)) {
							nextStartDate = b.endDate
							return
						}
						desiredStartDate = validStart
					}
				}

				const newSchedule = moveBooking({
					booking: b,
					desiredStartDate,
				})

				if (newSchedule) {
					nextStartDate = newSchedule.endDate
				} else {
					nextStartDate = b.endDate
				}
			} else {
				nextStartDate = b.endDate
			}
		})
	}

	function moveBooking(args: {
		booking: TMachineBooking
		desiredStartDate: Date
		adjustments?: Pick<
			TCalendarAdjustment,
			'startDate' | 'endDate' | 'status' | 'affectedMachines'
		>[]
	}) {
		const _store = store.getState()
		const bookings = selectAllBookings(_store)
		const machines = selectMachines(_store)
		const operations = selectProductOperations(_store)
		const calendarAdjustments = selectCalendarAdjustments(_store)
		const { processedOrders } = selectCategorizedOrders(_store)

		const {
			booking,
			desiredStartDate,
			adjustments = calendarAdjustments,
		} = args

		if (booking.status === 'completed') {
			toast.error('Cannot move a completed operation')
			return
		}
		const order = processedOrders.find(order =>
			order.bookingIds.includes(booking.id),
		)
		if (!order) return
		const machine = machines.find(machine => machine.id === booking.machineId)
		if (!machine) return
		const planningParameters = getPlanningParameters({
			order,
			operations,
		})
		const operation = planningParameters.operations.find(
			o => o.id === booking.operationId,
		)
		if (!operation) return
		const updatedSchedule = scheduleOperation({
			phases: operation.phases,
			planningParameters: {
				...planningParameters,
				earliestStartDate:
					roundToNearestMinutes(desiredStartDate).toISOString(),
			},
			machine,
			calendarAdjustments: getCalendarAdjustmentsForMachine({
				calendarAdjustments: adjustments,
				machineId: booking.machineId,
			}),
			bookings: [], // No need to consider other machine bookings in the scheduling
			tools: [], // No need to consider tools used by this booking in the scheduling
			direction: 'forward',
		})
		if (!updatedSchedule) {
			toast.error('Could not move operation')
			return
		}

		const changes = {
			...updatedSchedule,
			id: booking.id,
		}
		dispatch(editBooking(changes))

		// TODO: Calculate the new planned period for the order more robustly in the planning slice for the future
		const existingBookings = bookings.filter(
			b => order.bookingIds.includes(b.id) && booking.id !== b.id,
		)
		const plannedPeriod = {
			startDate: min([
				...existingBookings.map(b => b.startDate),
				updatedSchedule.startDate,
			]).toISOString(),
			endDate: max([
				...existingBookings.map(b => b.endDate),
				updatedSchedule.endDate,
			]).toISOString(),
		}
		dispatch(
			editOrder({
				id: order.id,
				changes: { plannedPeriod } as Partial<TPlannedOrder>,
			}),
		)

		dispatch(validatePlan())

		return {
			startDate: updatedSchedule.startDate,
			endDate: updatedSchedule.endDate,
		}
	}

	function addCalendarAdjustment(
		calendarAdjustment: Omit<TCalendarAdjustment, 'id'>,
	) {
		const _store = store.getState()
		const bookings = selectAllBookings(_store)
		const calendarAdjustments = selectCalendarAdjustments(_store)

		dispatch(createCalendarAdjustment(calendarAdjustment))

		const affectedBookings = bookings.filter(
			booking =>
				areIntervalsOverlapping(
					{ start: booking.startDate, end: booking.endDate },
					{
						start: calendarAdjustment.startDate,
						end: calendarAdjustment.endDate,
					},
				) &&
				(booking.status === 'planned' || booking.status === 'in-progress') &&
				isMachineAffectedByCalendarAdjustment({
					calendarAdjustment,
					machineId: booking.machineId,
				}),
		)
		affectedBookings.forEach(booking => {
			moveBooking({
				booking,
				desiredStartDate: new Date(booking.startDate),
				adjustments: [...calendarAdjustments, calendarAdjustment],
			})
		})

		dispatch(validatePlan())
	}

	function removeCalendarAdjustment(id: string) {
		const _store = store.getState()
		const bookings = selectAllBookings(_store)
		const calendarAdjustments = selectCalendarAdjustments(_store)

		const removedCalendarAdjustment = calendarAdjustments.find(c => c.id === id)
		dispatch(deleteCalendarAdjustment(id))
		if (!removedCalendarAdjustment) return
		const affectedBookings = bookings.filter(
			booking =>
				areIntervalsOverlapping(
					{ start: booking.startDate, end: booking.endDate },
					{
						start: removedCalendarAdjustment.startDate,
						end: removedCalendarAdjustment.endDate,
					},
				) &&
				(booking.status === 'planned' || booking.status === 'in-progress') &&
				isMachineAffectedByCalendarAdjustment({
					calendarAdjustment: removedCalendarAdjustment,
					machineId: booking.machineId,
				}),
		)
		affectedBookings.forEach(booking => {
			moveBooking({
				booking,
				desiredStartDate: new Date(booking.startDate),
				adjustments: calendarAdjustments.filter(
					c => c.id !== removedCalendarAdjustment.id,
				),
			})
		})

		dispatch(validatePlan())
	}

	function switchMachine(args: {
		bookingId: string
		toMachineId: string
	}): TPlannedMachineBooking | null {
		const _store = store.getState()
		const bookings = selectAllBookings(_store)
		const machines = selectMachines(_store)
		const calendarAdjustments = selectCalendarAdjustments(_store)
		const { activeOrders } = selectCategorizedOrders(_store)

		const { bookingId, toMachineId } = args

		const booking = bookings.find(b => b.id === bookingId)
		if (!booking) {
			toast.error('Could not find booking')
			return null
		}
		if (booking.status !== 'planned') {
			toast.error('Booking is not in the "Planned" state')
			return null
		}
		const order = activeOrders.find(o => o.id === booking.orderId)
		if (!order) {
			toast.error('Could not find order')
			return null
		}
		const operation = order.planningParameters.operations.find(
			o => o.id === booking.operationId,
		)
		if (!operation) {
			toast.error('Could not find planning parameters for the operation')
			return null
		}
		const toMachine = machines.find(m => m.id === toMachineId)
		if (!toMachine) {
			toast.error('Could not find machine')
			return null
		}

		const updatedSchedule = scheduleOperation({
			phases: operation.phases,
			planningParameters: {
				...order.planningParameters,
				earliestStartDate: booking.startDate,
			},
			machine: toMachine,
			calendarAdjustments: getCalendarAdjustmentsForMachine({
				calendarAdjustments,
				machineId: toMachine.id,
			}),
			direction: 'forward',
			bookings: [], // No need to consider other machine bookings in the scheduling
			tools: [], // No need to consider tools used by this booking in the scheduling
		})
		if (!updatedSchedule) {
			toast.error('Could not switch booking to another machine')
			return null
		}
		const changes = {
			...updatedSchedule,
			id: booking.id,
			machineId: toMachineId,
		}
		dispatch(editBooking(changes))
		toast.success('Booking was switched to another machine')

		// TODO: Update planned period of the order more robustly in the planning slice for the future (together with moveBooking)
		const existingBookings = bookings.filter(
			b => order.bookingIds.includes(b.id) && booking.id !== b.id,
		)
		const plannedPeriod = {
			startDate: min([
				...existingBookings.map(b => b.startDate),
				updatedSchedule.startDate,
			]).toISOString(),
			endDate: max([
				...existingBookings.map(b => b.endDate),
				updatedSchedule.endDate,
			]).toISOString(),
		}

		dispatch(
			editOrder({
				id: order.id,
				changes: { plannedPeriod } as Partial<TPlannedOrder>,
			}),
		)

		dispatch(validatePlan())

		return {
			...booking,
			...changes,
		}
	}

	return {
		planOrder,
		resetOrderPlan,
		moveBooking,
		alignBookings,
		switchMachine,
		addCalendarAdjustment,
		removeCalendarAdjustment,
	}
}

export { useOrderPlanner }
