import { createId } from '@paralleldrive/cuid2'
import {
	createAsyncThunk,
	createEntityAdapter,
	createSelector,
	createSlice,
} from '@reduxjs/toolkit'
import type { EntityState, PayloadAction } from '@reduxjs/toolkit'
import {
	TBookingStatus,
	TCalendarAdjustment,
	TCompletedMachineBooking,
	TInProgressMachineBooking,
	TMachineBooking,
	TOrder,
	TPeriod,
	TPeriodString,
	TPhasedItemV2,
	TPlannedMachineBooking,
	TSchedulingDirection,
} from '@repo/types'
import {
	addHours,
	areIntervalsOverlapping,
	compareAsc,
	endOfDay,
	isAfter,
	isBefore,
	startOfDay,
	subHours,
} from 'date-fns'
import { PURGE } from 'redux-persist'
import { toast } from 'sonner'

import type { RootState } from '@/app/store'
import {
	advanceOrderToInProgress,
	revertOrderToPlanned,
	selectAllOrders,
} from '@/features/orders/orders-slice'

import { selectMachines } from '../machines/machines-slice'
import { calculateStaffDemand } from '../staff-demand/calculate-staff-demand'
import { TPlanningAlert } from '../validation/types'
import { validateEarly } from '../validation/validate-early'
import { validateLate } from '../validation/validate-late'
import { validateOverlaps } from '../validation/validate-overlaps'
import { validateToolOverlaps } from '../validation/validate-tool-overlaps'
import { validateTransition } from '../validation/validate-transition'
import { createAlertHash } from './utils/create-alert-hash'

export type ZoomLevel = '12h' | '24h' | '3d' | '5d' | '14d' | '30d' | '90d'

type TIgnoredAlert = {
	hash: string
	date: string
}

const ignoredAlertsAdapter = createEntityAdapter({
	selectId: (ignoredAlert: TIgnoredAlert) => ignoredAlert.hash,
	sortComparer: (a, b) => compareAsc(a.date, b.date),
})

type PlanningState = {
	bookings: TMachineBooking[]
	alerts: TPlanningAlert[]
	ignoredAlerts: EntityState<TIgnoredAlert, string>
	calendarAdjustments: TCalendarAdjustment[]
	zoomLevel: ZoomLevel
	schedulingDirection: TSchedulingDirection
}

const initialState: PlanningState = {
	bookings: [],
	alerts: [],
	ignoredAlerts: ignoredAlertsAdapter.getInitialState(),
	calendarAdjustments: [],
	zoomLevel: '24h',
	schedulingDirection: 'forward',
}

export const planningSlice = createSlice({
	name: 'planning',
	initialState,
	reducers: {
		createBooking: {
			reducer: (state, action: PayloadAction<TPlannedMachineBooking>) => {
				state.bookings.push(action.payload)
			},
			prepare: (
				bookingData: Omit<TPlannedMachineBooking, 'id'>,
				id: string = createId(),
			) => {
				return { payload: { ...bookingData, id } }
			},
		},
		editBooking: (
			state,
			action: PayloadAction<
				Pick<TMachineBooking, 'id'> & Partial<TMachineBooking>
			>,
		) => {
			const booking = state.bookings.find(
				booking => booking.id === action.payload.id,
			)
			if (booking) {
				Object.assign(booking, action.payload)
			}
		},
		deleteBooking: (state, action: PayloadAction<string>) => {
			state.bookings = state.bookings.filter(
				booking => booking.id !== action.payload,
			)
		},
		startBooking: (
			state,
			action: PayloadAction<{ id: string; startDate: string }>,
		) => {
			const { id, startDate } = action.payload
			const booking = state.bookings.find(b => b.id === id)
			if (booking?.status === 'planned') {
				// TODO:: Explore whether booking.phases can be used to determine if it needs setup and teardown
				const needsSetup = booking.effectiveTimeMinutes.before > 0
				const needsTeardown = booking.effectiveTimeMinutes.after > 0
				const updatedBooking: TInProgressMachineBooking = {
					...booking,
					status: 'in-progress',
					progress: {
						...(needsSetup && {
							before: { completed: false, startDate },
						}),
						during: {
							completed: false,
							percentage: 0,
							quantity: 0,
							...(!needsSetup && {
								startDate,
							}),
						},
						...(needsTeardown && {
							after: { completed: false },
						}),
					},
				}
				Object.assign(booking, updatedBooking)
			}
		},
		completeBookingSetup: (
			state,
			action: PayloadAction<{
				id: string
				endDate: string
			}>,
		) => {
			const { id, endDate } = action.payload
			const booking = state.bookings.find(b => b.id === id)
			if (booking?.status === 'in-progress' && booking.progress.before) {
				booking.progress.before = {
					completed: true,
					startDate: booking.progress.before.startDate,
					endDate,
				}
				booking.progress.during = {
					...booking.progress.during,
					startDate: endDate,
				}
			}
		},
		startBookingProduction: (
			state,
			action: PayloadAction<{
				id: string
				startDate: string
			}>,
		) => {
			const { id, startDate } = action.payload
			const booking = state.bookings.find(b => b.id === id)
			if (booking?.status === 'in-progress') {
				booking.progress.during = {
					...booking.progress.during,
					startDate,
				}
			}
		},
		updateProgress: (
			state,
			action: PayloadAction<{
				id: string
				percentage: number
				quantity: number
			}>,
		) => {
			const { id, percentage, quantity } = action.payload
			const booking = state.bookings.find(b => b.id === id)
			if (booking?.status === 'in-progress') {
				booking.progress.during = {
					...booking.progress.during,
					percentage,
					quantity,
				}
			}
		},
		startBookingTeardown: (
			state,
			action: PayloadAction<{
				id: string
				startDate: string
			}>,
		) => {
			const { id, startDate } = action.payload
			const booking = state.bookings.find(b => b.id === id)
			if (booking?.status === 'in-progress' && booking.progress.after) {
				booking.progress.during = {
					...booking.progress.during,
					completed: true,
					startDate: booking.progress.during.startDate ?? startDate,
					endDate: startDate,
				}
				booking.progress.after = {
					completed: false,
					startDate,
				}
			}
		},
		completeBooking: (
			state,
			action: PayloadAction<{
				id: string
				endDate: string
			}>,
		) => {
			const { id, endDate } = action.payload
			const booking = state.bookings.find(b => b.id === id)
			if (booking?.status === 'in-progress') {
				const updatedBooking: TCompletedMachineBooking = {
					...booking,
					status: 'completed',
					progress: {
						...(booking.progress.before && {
							before: {
								completed: true,
								startDate: booking.progress.before.startDate ?? endDate,
								endDate: booking.progress.before.endDate ?? endDate,
							},
						}),
						during: {
							...booking.progress.during,
							completed: true,
							startDate: booking.progress.during.startDate ?? endDate,
							endDate: booking.progress.during.endDate ?? endDate,
						},
						...(booking.progress.after && {
							after: {
								completed: true,
								startDate: booking.progress.after.startDate ?? endDate,
								endDate: booking.progress.after.endDate ?? endDate,
							},
						}),
					},
				}
				Object.assign(booking, updatedBooking)
			}
		},
		restartBooking: (
			state,
			action: PayloadAction<{ id: string; startDate: string }>,
		) => {
			const { id, startDate } = action.payload
			const booking = state.bookings.find(b => b.id === id)
			if (booking?.status === 'completed') {
				const needsSetup = booking.effectiveTimeMinutes.before > 0
				const needsTeardown = booking.effectiveTimeMinutes.after > 0
				const updatedBooking: TInProgressMachineBooking = {
					...booking,
					status: 'in-progress',
					progress: {
						...(needsSetup && {
							before: { completed: false, startDate },
						}),
						during: {
							completed: false,
							percentage: 0,
							quantity: 0,
							...(!needsSetup && {
								startDate,
							}),
						},
						...(needsTeardown && {
							after: { completed: false },
						}),
					},
				}
				Object.assign(booking, updatedBooking)
			}
		},
		createCalendarAdjustment: {
			reducer: (state, action: PayloadAction<TCalendarAdjustment>) => {
				if (state.calendarAdjustments === undefined) {
					// TODO: Remove this condition after migration
					state.calendarAdjustments = [action.payload]
				} else {
					state.calendarAdjustments.push(action.payload)
				}
			},
			prepare: (
				calendarAdjustmentData: Omit<TCalendarAdjustment, 'id'>,
				id: string = createId(),
			) => {
				return { payload: { ...calendarAdjustmentData, id } }
			},
		},
		deleteCalendarAdjustment: (state, action: PayloadAction<string>) => {
			state.calendarAdjustments = state.calendarAdjustments.filter(
				c => c.id !== action.payload,
			)
		},
		setZoomLevel: (state, action: PayloadAction<ZoomLevel>) => {
			state.zoomLevel = action.payload
		},
		setSchedulingDirection: (
			state,
			action: PayloadAction<TSchedulingDirection>,
		) => {
			state.schedulingDirection = action.payload
		},
		ignoreAlert: (
			state,
			action: Parameters<typeof ignoredAlertsAdapter.addOne>[1],
		) => {
			ignoredAlertsAdapter.addOne(state.ignoredAlerts, action.payload)
		},
		activateAlert: (
			state,
			action: Parameters<typeof ignoredAlertsAdapter.removeOne>[1],
		) => {
			ignoredAlertsAdapter.removeOne(state.ignoredAlerts, action.payload)
		},
		pruneIgnoredAlerts: (state, action: PayloadAction<TPlanningAlert[]>) => {
			const alerts = action.payload
			const idsToPrune = new Set(state.ignoredAlerts.ids)

			alerts.forEach(({ hash }) => idsToPrune.delete(hash))

			ignoredAlertsAdapter.removeMany(state.ignoredAlerts, [...idsToPrune])
		},
		updateBookingOperators: (
			state,
			action: PayloadAction<{
				id: string
				operators: TPhasedItemV2[]
			}>,
		) => {
			const booking = state.bookings.find(b => b.id === action.payload.id)
			if (booking) {
				booking.operators = action.payload.operators
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(validatePlan.fulfilled, (state, action) => {
			state.alerts = action.payload
		})
		builder.addCase(resetProgress.fulfilled, (state, action) => {
			const booking = state.bookings.find(b => b.id === action.payload)
			if (booking) {
				if ('progress' in booking) {
					delete (booking as Partial<TInProgressMachineBooking>).progress
				}
				const updatedBooking: TPlannedMachineBooking = {
					...booking,
					status: 'planned',
				}
				Object.assign(booking, updatedBooking)
			}
		})
		builder.addCase(PURGE, state => {
			state.bookings = initialState.bookings
			state.alerts = initialState.alerts
			state.ignoredAlerts = ignoredAlertsAdapter.getInitialState()
			state.calendarAdjustments = initialState.calendarAdjustments
			state.zoomLevel = initialState.zoomLevel
			state.schedulingDirection = initialState.schedulingDirection
		})
	},
})

export const {
	createBooking,
	editBooking,
	deleteBooking,
	startBooking,
	completeBookingSetup,
	startBookingProduction,
	updateProgress,
	startBookingTeardown,
	completeBooking,
	restartBooking,
	createCalendarAdjustment,
	deleteCalendarAdjustment,
	setZoomLevel,
	setSchedulingDirection,
	ignoreAlert,
	activateAlert,
	pruneIgnoredAlerts,
	updateBookingOperators,
} = planningSlice.actions

export type TBookingFilterCriteria = {
	orderIds?: string[] | null
	machineIds?: string[] | null
	operatorIds?: string[] | null
	dateFilter?: '-24h' | '+24h' | '-7d' | '+7d' | 'all' | null
}

export function calculatePeriod(
	dateFilter: TBookingFilterCriteria['dateFilter'],
): TPeriodString | null {
	const now = new Date()

	switch (dateFilter) {
		case '-24h': {
			const start = startOfDay(subHours(now, 24))
			return {
				startDate: start.toISOString(),
				endDate: now.toISOString(),
			}
		}
		case '+24h': {
			const end = endOfDay(addHours(now, 24))
			return {
				startDate: now.toISOString(),
				endDate: end.toISOString(),
			}
		}
		case '-7d': {
			const start = startOfDay(subHours(now, 24 * 7))
			return {
				startDate: start.toISOString(),
				endDate: now.toISOString(),
			}
		}
		case '+7d': {
			const end = endOfDay(addHours(now, 24 * 7))
			return {
				startDate: now.toISOString(),
				endDate: end.toISOString(),
			}
		}
		default:
			return null
	}
}

export const selectAllBookings = (state: RootState) => state.planning.bookings

const isBookingInPeriod = (
	booking: TMachineBooking,
	period: TPeriodString,
): boolean => {
	return (
		areIntervalsOverlapping(
			{
				start: booking.startDate,
				end: booking.endDate,
			},
			{
				start: period.startDate,
				end: period.endDate,
			},
		) ||
		(booking.status === 'completed' &&
			isAfter(
				booking.progress.after?.endDate ?? booking.progress.during.endDate,
				subHours(period.startDate, 1),
			)) ||
		(booking.status === 'planned' &&
			isBefore(booking.startDate, period.endDate))
	)
}

/* export const selectFilteredBookings = createSelector(
	[
		selectAllBookings,
		(_: RootState, filterCriteria: TBookingFilterCriteria) => filterCriteria,
	],
	(bookings, filterCriteria) =>
		bookings.filter(booking => {
			const orderMatch =
				!filterCriteria.orderId || booking.orderId === filterCriteria.orderId
			const machineMatch =
				!filterCriteria.machineId ||
				booking.machineId === filterCriteria.machineId
			return orderMatch && machineMatch
		}),
) */

export const selectCategorizedBookings = createSelector(
	[
		selectAllBookings,
		(_: RootState, filterCriteria: TBookingFilterCriteria) => filterCriteria,
	],
	(bookings, filterCriteria) => {
		const period = calculatePeriod(filterCriteria.dateFilter)

		const categorizedBookings = bookings
			.filter(
				booking =>
					(!period ||
						booking.status === 'in-progress' ||
						isBookingInPeriod(booking, period)) &&
					(!filterCriteria.orderIds?.length ||
						filterCriteria.orderIds.includes(booking.orderId)) &&
					(!filterCriteria.machineIds?.length ||
						filterCriteria.machineIds.includes(booking.machineId)) &&
					(!filterCriteria.operatorIds?.length ||
						(booking.operators &&
							filterCriteria.operatorIds.some(opId =>
								booking.operators.some(op => op.id === opId),
							))),
			)
			.reduce(
				(acc, booking) => {
					switch (booking.status) {
						case 'planned':
							acc[booking.status].push(booking)
							break
						case 'in-progress':
							acc[booking.status].push(booking)
							break
						case 'completed':
							acc[booking.status].push(booking)
							break
					}
					return acc
				},
				{
					planned: [] as TPlannedMachineBooking[],
					'in-progress': [] as TInProgressMachineBooking[],
					completed: [] as TCompletedMachineBooking[],
				},
			)

		return {
			planned: categorizedBookings.planned.sort((a, b) =>
				compareAsc(a.startDate, b.startDate),
			),
			inProgress: categorizedBookings['in-progress'].sort((a, b) =>
				compareAsc(a.startDate, b.startDate),
			),
			completed: categorizedBookings.completed.sort((a, b) =>
				compareAsc(a.startDate, b.startDate),
			),
		}
	},
)

export const selectBooking = createSelector(
	[selectAllBookings, (_state: RootState, id?: string) => id],
	(bookings, id) =>
		id ? bookings.find(booking => booking.id === id) : undefined,
)

const selectBookingsByStatus = (status: TBookingStatus) =>
	createSelector([selectAllBookings], bookings =>
		bookings
			.filter(booking => booking.status === status)
			.sort((a, b) => compareAsc(a.startDate, b.startDate)),
	)
export const selectPlannedBookings = selectBookingsByStatus('planned')
export const selectInProgressBookings = selectBookingsByStatus('in-progress')
export const selectCompletedBookings = selectBookingsByStatus('completed')
export const selectActiveBookings = createSelector(
	[selectInProgressBookings, selectPlannedBookings],
	(inProgressBookings, plannedBookings) => [
		...inProgressBookings,
		...plannedBookings,
	],
)

export const selectProgressedBookings = createSelector(
	[selectAllBookings],
	bookings =>
		bookings
			.filter(booking => ['in-progress', 'completed'].includes(booking.status))
			.sort((a, b) => compareAsc(a.startDate, b.startDate)),
)

const selectAlerts = (state: RootState) => state.planning.alerts
const selectIgnoredAlerts = (state: RootState) => state.planning.ignoredAlerts

// TODO: Move ignored alerts to separate slice
// TODO: Should alerts just be a selector?
// TODO: Should alerts be a separate slice in own feature folder?
export const selectEnhancedAlerts = createSelector(
	selectAlerts,
	selectIgnoredAlerts,
	selectAllBookings,
	(state: RootState) => state.orders.entities,
	(alerts, ignoredAlerts, bookings, orderEntities) =>
		alerts
			.map(alert => {
				const filteredBookings = alert.bookings
					.map(b => bookings.find(booking => booking.id === b.id))
					.filter((b): b is TMachineBooking => b !== undefined)

				const filteredOrders = alert.orders
					.map(o => orderEntities[o.id])
					.filter((o): o is TOrder => o !== undefined)

				const ignored = Boolean(ignoredAlerts.entities[alert.hash])

				return {
					...alert,
					bookings: filteredBookings,
					orders: filteredOrders,
					ignored,
				}
			})
			.sort((a, b) => compareAsc(a.startDate, b.startDate)),
)

export const selectFilteredAlerts = (args: {
	categories: string[]
	status: 'active' | 'ignored' | 'all'
	machineId?: string
	orderId?: string
}) =>
	createSelector(
		selectAlerts,
		selectIgnoredAlerts,
		selectAllBookings,
		(state: RootState) => state.orders.entities,
		(alerts, ignoredAlerts, bookings, orderEntities) => {
			const { categories, status, machineId, orderId } = args
			return alerts
				.filter(
					alert =>
						categories.length === 0 || categories.includes(alert.category),
				)
				.map(alert => {
					const filteredBookings = alert.bookings
						.map(b => bookings.find(booking => booking.id === b.id))
						.filter((b): b is TMachineBooking => b !== undefined)

					const filteredOrders = alert.orders
						.map(o => orderEntities[o.id])
						.filter((o): o is TOrder => o !== undefined)

					const ignored = Boolean(ignoredAlerts.entities[alert.hash])

					return {
						...alert,
						bookings: filteredBookings,
						orders: filteredOrders,
						ignored,
					}
				})
				.filter(
					alert => status === 'all' || alert.ignored === (status === 'ignored'),
				)
				.filter(
					alert =>
						machineId === undefined ||
						alert.bookings.some(booking => booking.machineId === machineId),
				)
				.filter(
					alert =>
						orderId === undefined ||
						alert.bookings.some(booking => booking.orderId === orderId),
				)
				.sort((a, b) => compareAsc(a.startDate, b.startDate))
		},
	)

export const selectAlertCount = createSelector(
	selectAlerts,
	selectIgnoredAlerts,
	(alerts, ignoredAlerts) =>
		alerts.filter(alert => !ignoredAlerts.entities[alert.hash]).length,
)

/* type CalendarAdjustmentFilters = {
	machineId?: string
	dateRange?: {
		startDate?: string | Date
		endDate?: string | Date
	}
} */

export const selectCalendarAdjustments = createSelector(
	(state: RootState) => state.planning.calendarAdjustments,
	calendarAdjustments => {
		return [...calendarAdjustments].sort((a, b) =>
			compareAsc(a.startDate, b.startDate),
		)
	},
)

export const selectOrdersWithAlerts = createSelector(
	(state: RootState) => state.orders,
	selectAlerts,
	(ordersState, alerts) => {
		const orderIdsWithAlerts = new Set(
			alerts.flatMap(alert => alert.orders.map(order => order.id)),
		)

		if (orderIdsWithAlerts.size === 0) return []

		if (orderIdsWithAlerts.size === ordersState.ids.length) {
			return ordersState.ids.map(id => ordersState.entities[id])
		}

		return Array.from(orderIdsWithAlerts)
			.map(id => ordersState.entities[id])
			.filter(Boolean)
	},
)

export const selectZoomLevel = (state: RootState) => state.planning.zoomLevel

export const selectSchedulingDirection = (state: RootState) =>
	state.planning.schedulingDirection

type StaffDemandProps = {
	period: TPeriod
	intervalMinutes?: number
	staffGroups?: string[]
}

const selectStaffDemandParams = createSelector(
	[
		(_: RootState, props: StaffDemandProps) => props.period,
		(_: RootState, props: StaffDemandProps) => props.intervalMinutes,
		(_: RootState, props: StaffDemandProps) => props.staffGroups,
	],
	(period, intervalMinutes, staffGroups) => ({
		period,
		intervalMinutes: intervalMinutes || 60,
		staffGroups: staffGroups || [],
	}),
)

export const selectStaffDemand = createSelector(
	[
		selectAllBookings,
		selectMachines,
		selectCalendarAdjustments,
		selectStaffDemandParams,
	],
	(bookings, machines, calendarAdjustments, params) => {
		if (!params.period) {
			return {
				intervals: [],
				staffGroups: [],
			}
		}

		return calculateStaffDemand({
			bookings,
			period: params.period,
			intervalMinutes: params.intervalMinutes,
			machines,
			calendarAdjustments,
			staffGroups: params.staffGroups,
		})
	},
)

export const validatePlan = createAsyncThunk(
	`${planningSlice.name}/validatePlan`,
	async (_, { getState, dispatch }) => {
		const state = getState() as RootState

		const orders = selectAllOrders(state)
		const bookings = selectAllBookings(state)
		const plan = { orders, bookings }

		const alerts = [
			...validateOverlaps(plan),
			...validateToolOverlaps(plan),
			...validateEarly(plan),
			...validateLate(plan),
			...validateTransition(plan),
		].map(alert => ({
			...alert,
			hash: createAlertHash(alert),
		}))

		dispatch(pruneIgnoredAlerts(alerts))

		return alerts
	},
)

export const resetProgress = createAsyncThunk(
	`${planningSlice.name}/resetProgress`,
	async (targetBookingId: string | undefined, { getState, dispatch }) => {
		const state = getState() as RootState

		const orderEntities = state.orders.entities
		const bookings = selectAllBookings(state)

		const booking = bookings.find(booking => booking.id === targetBookingId)

		if (!booking) {
			const errorMessage = 'No valid booking selected'
			toast.error(errorMessage)
			throw Error(errorMessage)
		}

		const order = orderEntities[booking.orderId]

		if (order) {
			const bookingIds = 'bookingIds' in order ? order.bookingIds : []
			const orderIsInProgress =
				bookings.filter(
					b =>
						b.id !== booking.id &&
						bookingIds.includes(b.id) &&
						(b.status === 'in-progress' || b.status === 'completed'),
				).length > 0
			dispatch(revertOrderToPlanned(order.id))
			if (orderIsInProgress) {
				dispatch(advanceOrderToInProgress(order.id))
			}
		}
		toast.success('Booking was reset')

		return targetBookingId
	},
)

export default planningSlice.reducer
