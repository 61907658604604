import { createId } from '@paralleldrive/cuid2'
import { format, isAfter, max } from 'date-fns'

import { TPlanValidator } from './types'

const validateLate: TPlanValidator = plan => {
	const alerts: ReturnType<TPlanValidator> = []

	plan.bookings.forEach(booking => {
		if (booking.status === 'completed') return // TODO: Add test case for this

		const order = plan.orders.find(order => order.id === booking.orderId)

		if (order && isAfter(booking.endDate, order.dueDate)) {
			alerts.push({
				id: createId(),
				category: 'late',
				orders: [{ id: order.id }],
				bookings: [{ id: booking.id }],
				startDate: max([booking.startDate, order.dueDate]).toISOString(),
				endDate: booking.endDate,
				suggestion: `Should end no later than ${format(
					order.dueDate,
					'd MMM, HH:mm',
				)}.`,
			})
		}
	})

	return alerts
}

export { validateLate }
