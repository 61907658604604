import { TOrder } from '@repo/types'
import { toast } from 'sonner'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import {
	createCustomer,
	selectCustomers,
} from '@/features/customers/customers-slice'
import { useConfirmExitFormDialog } from '@/features/form-dialog/use-confirm-exit-form-dialog'
import { OrderForm } from '@/features/orders/forms/order-form'
import {
	editOrder,
	selectProductionOrderNumbers,
	updateOrderQuantity,
} from '@/features/orders/orders-slice'
import { validatePlan } from '@/features/planning/planning-slice'
import { selectProducts } from '@/features/products/products-slice'

export function EditOrderDialog(props: {
	order: TOrder
	onClose: () => void
	container?: HTMLElement | null
}) {
	const { order, onClose, container } = props
	const dispatch = useAppDispatch()
	const customers = useAppSelector(selectCustomers)
	const products = useAppSelector(selectProducts)
	const productionOrderNumbers = useAppSelector(selectProductionOrderNumbers)

	const { ConfirmExitAlertDialog, formDialogProps, formProps } =
		useConfirmExitFormDialog<Omit<TOrder, 'id' | 'status'>>({
			onSubmit: changes => {
				// If quantity changed and order is planned, use updateOrderQuantity
				if (changes.quantity !== order.quantity && order.status === 'planned') {
					dispatch(
						updateOrderQuantity({
							id: order.id,
							quantity: changes.quantity,
						}),
					)
					// Update other fields separately
					const {
						productionOrderNumber,
						salesOrderNumber,
						customerName,
						dueDate,
						buffer,
					} = changes
					const otherChanges = {
						productionOrderNumber,
						salesOrderNumber,
						customerName,
						dueDate,
						buffer,
					}
					if (Object.keys(otherChanges).length > 0) {
						dispatch(editOrder({ id: order.id, changes: otherChanges }))
					}
				} else {
					// For non-planned orders or no quantity change, use regular editOrder
					// Preserve original values for disabled fields
					const filteredChanges = {
						...changes,
						// Keep original product if not pending
						product:
							order.status === 'pending' ? changes.product : order.product,
						// Keep original quantity and buffer if completed
						...(order.status === 'completed' && {
							quantity: order.quantity,
							buffer: order.buffer,
						}),
					}
					dispatch(editOrder({ id: order.id, changes: filteredChanges }))
				}
				dispatch(createCustomer({ name: changes.customerName }))
				dispatch(validatePlan())
				toast.success(`Order "${changes.productionOrderNumber}" updated`)
			},
			onClose,
		})

	return (
		<>
			<ConfirmExitAlertDialog container={container} />
			<GenericDialog
				title="Edit order"
				description="Update order details"
				hideDescription
				container={container}
				{...formDialogProps}
			>
				<OrderForm
					submitButtons={<Button type="submit">Save Order</Button>}
					initialValues={{
						...order,
						// Initialize comment as empty string when undefined to prevent react-hook-form
						// from marking the field as dirty due to undefined -> empty string coercion by
						// the textarea element
						comment: order.comment ?? '',
					}}
					invalidProductionOrderNumbers={productionOrderNumbers.filter(
						productionOrderNumber =>
							productionOrderNumber !== order.productionOrderNumber,
					)}
					customers={customers}
					products={products}
					disableProductField={order.status !== 'pending'}
					disableQuantityField={order.status === 'completed'}
					disableBufferField={order.status === 'completed'}
					{...formProps}
				/>
			</GenericDialog>
		</>
	)
}
