import { useEffect, useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'
import { TOrder, TPlannedOrder, TProduct } from '@repo/types'
import { useMutation } from '@tanstack/react-query'
import {
	createFileRoute,
	Link,
	Outlet,
	useNavigate,
} from '@tanstack/react-router'
import {
	ColumnFiltersState,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	PaginationState,
	RowSelectionState,
	SortingState,
	useReactTable,
	VisibilityState,
} from '@tanstack/react-table'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import {
	AlignJustify,
	FileDown,
	FileUp,
	LayoutGrid,
	Loader2,
	Plus,
} from 'lucide-react'
import { toast } from 'sonner'

import { env } from '@/app/env'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { store } from '@/app/store'
import { Draggable } from '@/components/dnd/draggable'
import { GenericAlertDialog } from '@/components/generic-alert-dialog'
import { PageLayout } from '@/components/layout'
import {
	ScrollableToolbar,
	ScrollableToolbarContentLeft,
	ScrollableToolbarContentRight,
} from '@/components/scrollable-toolbar'
import { DataTable } from '@/components/table/data-table'
import { Button } from '@/components/ui/button'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@/components/ui/tooltip'
import { ImportOrdersDialog } from '@/features/orders/components/import-orders-dialog'
import { OrderCard } from '@/features/orders/components/order-card'
import { columns } from '@/features/orders/components/table/columns'
import { DataTableSelectedRowActions } from '@/features/orders/components/table/data-table-selected-row-actions'
import { DataTableToolbar } from '@/features/orders/components/table/data-table-toolbar'
import {
	advanceOrderToInactive,
	createOrder,
	revertOrderToPending,
	selectEnhancedOrders,
	selectProductionOrderNumbers,
} from '@/features/orders/orders-slice'
import { useOrderPlanner } from '@/features/planning/hooks/use-order-planner'
import { useUniqueName } from '@/hooks/use-unique-name'
import { useSyncedPersistedState } from '@/lib/hooks/use-synced-persisted-state'

type ViewOptions = 'list' | 'grid'

type OrderSearch = {
	view?: ViewOptions
	sorting?: SortingState
	columnFilters?: ColumnFiltersState
	columnVisibility?: VisibilityState
	rowSelection?: RowSelectionState
	pageIndex?: PaginationState['pageIndex']
	pageSize?: PaginationState['pageSize']
}

const Route = createFileRoute('/orders')({
	component: OrdersComponent,
	validateSearch: (search: Record<string, unknown>): OrderSearch => {
		return {
			view: search.view as OrderSearch['view'],
			sorting: search.sorting as OrderSearch['sorting'],
			columnFilters: search.columnFilters as OrderSearch['columnFilters'],
			columnVisibility:
				search.columnVisibility as OrderSearch['columnVisibility'],
			rowSelection: search.rowSelection as OrderSearch['rowSelection'],
			pageIndex: search.pageIndex as OrderSearch['pageIndex'],
			pageSize: search.pageSize as OrderSearch['pageSize'],
		}
	},
})

function OrdersComponent() {
	const search = Route.useSearch()
	const navigate = useNavigate({ from: Route.fullPath })

	const view = useSyncedPersistedState<ViewOptions>('orders.view', {
		value: search.view,
		defaultValue: 'list',
	})
	const columnVisibility = useSyncedPersistedState('orders.columnVisibility', {
		value: search.columnVisibility,
		defaultValue: {},
	})
	const pageSize = useSyncedPersistedState('orders.pageSize', {
		value: search.pageSize,
		defaultValue: 20,
	})

	const dispatch = useAppDispatch()
	const orders = useAppSelector(selectEnhancedOrders)
	const productionOrderNumbers = useAppSelector(selectProductionOrderNumbers)
	const [parent] = useAutoAnimate()
	const { getUniqueName } = useUniqueName(productionOrderNumbers, false)
	const [resetPlanOrder, setResetPlanOrder] = useState<TPlannedOrder | null>(
		null,
	)
	const [importDialogOpen, setImportDialogOpen] = useState(false)

	const meta = {
		actions: (order: TOrder<TProduct>) => ({
			onShowInPlan:
				order.status === 'planned' ||
				order.status === 'in-progress' ||
				order.status === 'completed'
					? () => {
							navigate({
								to: '/planning',
								search: {
									bookingId: order.bookingIds[0],
									orderId: order.id,
								},
							})
						}
					: undefined,
			onPlanForward:
				order.status === 'pending'
					? async () => {
							const bookings = await planOrder({
								order,
								direction: 'forward',
							})

							if (bookings) {
								toast.success(`Planned order #${order.productionOrderNumber}`)
							}
						}
					: undefined,
			onPlanBackward:
				order.status === 'pending'
					? async () => {
							const bookings = await planOrder({
								order,
								direction: 'backward',
							})

							if (bookings) {
								toast.success(`Planned order #${order.productionOrderNumber}`)
							}
						}
					: undefined,
			onUnschedule:
				order.status === 'planned'
					? () => openUnscheduleOrderPlanDialog(order)
					: undefined,
			onEdit: () => {
				navigate({
					to: '/orders/$orderId/edit',
					params: { orderId: order.id },
					search: true,
				})
			},
			onDeactivate:
				order.status === 'pending'
					? () => deactivateOrder(order.id)
					: undefined,
			onReactivate:
				order.status === 'inactive'
					? () => reactivateOrder(order.id)
					: undefined,
			onDuplicate: () => duplicateOrder(order),
			onDelete: () => {
				navigate({
					to: '/orders/$orderId/delete',
					params: { orderId: order.id },
					search: true,
				})
			},
		}),
	}

	const table = useReactTable({
		data: orders,
		columns,
		meta,
		initialState: {
			columnPinning: {
				left: ['select'],
				right: ['actions'],
			},
		},
		getRowId: originalRow => originalRow.id,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
	})

	const [tableState, setTableState] = useState({
		...table.initialState,
		sorting: search.sorting ?? [],
		columnFilters: search.columnFilters ?? [],
		rowSelection: search.rowSelection ?? {},
		columnVisibility,
		pagination: {
			pageIndex: search.pageIndex ?? 0,
			pageSize,
		},
	})

	useEffect(() => {
		if (
			Object.keys(search).every(
				key => search[key as keyof OrderSearch] === undefined,
			)
		) {
			setTableState({
				...table.initialState,
				columnVisibility,
				pagination: {
					...table.initialState.pagination,
					pageSize,
				},
			})
		}
	}, [search, table.initialState, columnVisibility, pageSize])

	table.setOptions(prev => ({
		...prev,
		state: tableState,
		onStateChange: updater => {
			const newTableState =
				updater instanceof Function ? updater(tableState) : updater

			navigate({
				search: old => ({
					...old,
					view,
					sorting: newTableState.sorting,
					columnFilters: newTableState.columnFilters,
					columnVisibility: newTableState.columnVisibility,
					rowSelection: newTableState.rowSelection,
					pageIndex: newTableState.pagination.pageIndex,
					pageSize: newTableState.pagination.pageSize,
				}),
				replace: true,
				resetScroll: false,
			})

			setTableState(updater)
		},
	}))

	const { planOrder, resetOrderPlan } = useOrderPlanner()

	function openUnscheduleOrderPlanDialog(order: TPlannedOrder) {
		setResetPlanOrder(order)
	}

	function closeUnscheduleOrderPlanDialog() {
		setResetPlanOrder(null)
	}

	function confirmUnscheduleOrderPlan() {
		if (resetPlanOrder) {
			resetOrderPlan(resetPlanOrder.id)
			toast.success(
				`Unscheduled order #${resetPlanOrder.productionOrderNumber}`,
			)
		}
		closeUnscheduleOrderPlanDialog()
	}

	function deactivateOrder(orderId: string) {
		dispatch(advanceOrderToInactive(orderId))
	}

	function reactivateOrder(orderId: string) {
		dispatch(revertOrderToPending(orderId))
	}

	function duplicateOrder(order: TOrder) {
		dispatch(
			createOrder({
				...order,
				productionOrderNumber: getUniqueName(order.productionOrderNumber),
			}),
		)
		toast.success(`Order "${order.productionOrderNumber}" duplicated`)
	}

	const exportOrdersMutation = useMutation({
		mutationFn: async () => {
			const state = store.getState()
			const response = await fetch(`${env.VITE_CSV_API_URL}/export/orders`, {
				method: 'POST',
				body: JSON.stringify(state),
			})

			if (!response.ok) {
				throw new Error('Failed to export orders')
			}

			const csvContent = await response.text()
			const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
			const fileName = `polly-orders_${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.csv`

			// Add artificial delay of 400ms
			// Research shows 100-400ms is the sweet spot for perceived system feedback
			// - Under 100ms feels instant/unnoticeable
			// - 400ms feels responsive while clearly showing progress
			// - Over 1000ms starts to feel slow/annoying
			await new Promise(resolve => setTimeout(resolve, 400))

			saveAs(blob, fileName)
		},
		onSuccess: () => {
			toast.success('Orders exported!')
		},
		onError: () => {
			toast.error('Failed to export orders')
		},
	})

	return (
		<>
			<PageLayout>
				<ScrollableToolbar>
					<ScrollableToolbarContentLeft>
						<h1 className="text-xl sm:text-2xl">Orders</h1>
					</ScrollableToolbarContentLeft>
					<ScrollableToolbarContentRight>
						<Button asChild>
							<Link to="/orders/create" search={true}>
								<Plus className="mr-2 h-4 w-4" />
								Create Order
							</Link>
						</Button>
						<Button
							variant="outline"
							disabled={exportOrdersMutation.isPending}
							onClick={() => exportOrdersMutation.mutate()}
						>
							{exportOrdersMutation.isPending ? (
								<>
									<Loader2 className="mr-2 h-4 w-4 animate-spin" />
									Exporting...
								</>
							) : (
								<>
									<FileDown className="mr-2 h-4 w-4" />
									Export
								</>
							)}
						</Button>
						<Button variant="outline" onClick={() => setImportDialogOpen(true)}>
							<FileUp className="mr-2 h-4 w-4" />
							Import
						</Button>
						<Tabs value={view}>
							<TabsList aria-label="View options">
								<Tooltip>
									<TooltipTrigger>
										<TabsTrigger
											value="list"
											aria-label="Switch to list view"
											asChild
										>
											<Link to="." search={old => ({ ...old, view: 'list' })}>
												<AlignJustify className="h-4 w-4" />
											</Link>
										</TabsTrigger>
									</TooltipTrigger>
									<TooltipContent>
										<p>List View</p>
									</TooltipContent>
								</Tooltip>
								<Tooltip>
									<TooltipTrigger>
										<TabsTrigger
											value="grid"
											aria-label="Switch to grid view"
											asChild
										>
											<Link to="." search={old => ({ ...old, view: 'grid' })}>
												<LayoutGrid className="h-4 w-4" />
											</Link>
										</TabsTrigger>
									</TooltipTrigger>
									<TooltipContent>
										<p>Grid View</p>
									</TooltipContent>
								</Tooltip>
							</TabsList>
						</Tabs>
					</ScrollableToolbarContentRight>
				</ScrollableToolbar>
				<div className="py-8">
					{view === 'list' && (
						<DataTable
							table={table}
							columns={columns}
							renderToolbar={() => <DataTableToolbar table={table} />}
							renderSelectedRowActions={() => (
								<DataTableSelectedRowActions
									table={table}
									resetOrderPlan={resetOrderPlan}
									planOrder={planOrder}
								/>
							)}
						/>
					)}
					{view === 'grid' && (
						<div
							ref={parent}
							className="mt-4 grid w-full gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
						>
							{orders.map(order => (
								<Draggable key={order.id} id={order.id} className="rounded-lg">
									<OrderCard
										key={order.id}
										order={order}
										totalDurationMinutes={order.productionDurationMinutes}
										{...meta.actions(order)}
									/>
								</Draggable>
							))}
						</div>
					)}
				</div>
				<Outlet />
			</PageLayout>
			{resetPlanOrder && (
				<GenericAlertDialog
					title="Unschedule order"
					description={`Are you sure you want to unschedule order #${resetPlanOrder.productionOrderNumber}? This action cannot be undone, but you can always plan it again.`}
					confirmButtonText="Unschedule"
					onClose={closeUnscheduleOrderPlanDialog}
					onConfirm={confirmUnscheduleOrderPlan}
				/>
			)}
			<ImportOrdersDialog
				open={importDialogOpen}
				onClose={() => setImportDialogOpen(false)}
			/>
		</>
	)
}

export { Route }
