import { forwardRef } from 'react'

import { TMachineBooking, TOrder } from '@repo/types'
import { format, formatDistance, isSameDay } from 'date-fns'
import {
	Bell,
	BellOff,
	Calendar,
	Clock,
	GanttChart,
	MoreVertical,
} from 'lucide-react'

import { useAppSelector } from '@/app/hooks'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Separator } from '@/components/ui/separator'
import { selectMultipleMachines } from '@/features/machines/machines-slice'
import { TPlanningAlert } from '@/features/validation/types'
import { cn } from '@/lib/utils'
import { stopPropagation } from '@/utils/stop-propagation'

import { AlertIcon } from './alert-icon'

export const AlertCard = forwardRef(function MachineCard(
	props: {
		alert: TPlanningAlert<TOrder, TMachineBooking>
		onShowInPlan: () => void
		onIgnore?: () => void
		onActivate?: () => void
	},
	ref: React.Ref<HTMLDivElement>,
) {
	const { alert, onShowInPlan, onIgnore, onActivate } = props

	const machines = useAppSelector(
		selectMultipleMachines(alert.bookings.map(booking => booking.machineId)),
	)

	return (
		<Card
			ref={ref}
			className={cn(
				'relative min-h-40 p-4 transition-opacity',
				alert.ignored && 'opacity-50',
			)}
		>
			<h2 className="flex items-center gap-2 text-lg" title={alert.category}>
				<span
					className={cn(
						'flex h-6 w-6 items-center justify-center rounded-full bg-destructive text-destructive-foreground transition-colors',
						alert.ignored && 'bg-muted text-muted-foreground',
					)}
				>
					<AlertIcon category={alert.category} />
				</span>
				<span className="truncate">
					{alert.category === 'overlap' && 'Overlap'}
					{alert.category === 'tool-overlap' && 'Tool Overlap'}
					{alert.category === 'early' && 'Early'}
					{alert.category === 'late' && 'Late'}
					{alert.category === 'transition-soft-linked' &&
						'Transition: Soft-Linked'}
					{alert.category === 'transition-hard-linked' &&
						'Transition: Hard-Linked'}
				</span>
			</h2>
			<p className="mt-2 text-sm text-muted-foreground">{alert.suggestion}</p>
			<div className="mt-2 flex flex-col gap-2">
				<div className="flex flex-wrap items-center gap-x-1 gap-y-2">
					<Badge variant="outline" className="whitespace-nowrap">
						<Calendar className="mr-2 h-4 w-4 shrink-0" />
						{isSameDay(alert.startDate, alert.endDate) ? (
							<>
								{format(alert.endDate, 'd MMM yyyy')} &bull;{' '}
								{format(alert.startDate, 'HH:mm')} &ndash;{' '}
								{format(alert.endDate, 'HH:mm')}
							</>
						) : (
							<>
								{format(alert.startDate, 'd MMM yyyy')} &ndash;{' '}
								{format(alert.endDate, 'd MMM yyyy')}
							</>
						)}
					</Badge>
				</div>
				<Separator />
				<div className="flex items-center gap-x-1 gap-y-2 text-sm">
					<span className="text-muted-foreground">Conflict:</span>
					<Clock className="h-4 w-4 shrink-0" />
					{formatDistance(alert.startDate, alert.endDate, {
						includeSeconds: true,
					})}
				</div>
				<div className="flex items-center gap-x-1 gap-y-2 text-sm">
					<span className="text-muted-foreground">
						{machines.length === 1 ? 'Machine' : 'Machines'}:
					</span>
					<span className="truncate">
						{machines.map(machine => machine.name).join(', ')}
					</span>
				</div>
				<div className="flex items-center gap-x-1 text-sm">
					<span className="text-muted-foreground">
						{alert.orders.length === 1 ? 'Order' : 'Orders'}:
					</span>
					<div className="grid gap-1">
						{alert.orders.map(order => (
							<Badge
								key={order.id}
								variant="outline"
								className="block truncate"
							>
								#{order.productionOrderNumber} &bull; {order.customerName}
							</Badge>
						))}
					</div>
				</div>
			</div>
			<DropdownMenu>
				<DropdownMenuTrigger className="absolute right-2 top-2" asChild>
					<Button variant="ghost" className="h-8 w-8 p-2">
						<MoreVertical className="h-4 w-4 shrink-0" />
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="end">
					{onShowInPlan && (
						<DropdownMenuItem onClick={stopPropagation(onShowInPlan)}>
							<GanttChart className="mr-2 h-4 w-4" />
							<span>Show In Plan</span>
						</DropdownMenuItem>
					)}
					{onIgnore && (
						<DropdownMenuItem onClick={stopPropagation(onIgnore)}>
							<BellOff className="mr-2 h-4 w-4" />
							<span>Ignore Alert</span>
						</DropdownMenuItem>
					)}
					{onActivate && (
						<DropdownMenuItem onClick={stopPropagation(onActivate)}>
							<Bell className="mr-2 h-4 w-4" />
							<span>Reactivate Alert</span>
						</DropdownMenuItem>
					)}
				</DropdownMenuContent>
			</DropdownMenu>
		</Card>
	)
})
