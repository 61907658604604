import { useState } from 'react'

import { TPhasedItemV2 } from '@repo/types'
import { Plus, X } from 'lucide-react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import { useAppSelector } from '@/app/hooks'
import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Combobox } from '@/components/ui/combobox'
import { DialogFooter } from '@/components/ui/dialog'
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'
import {
	selectOperatorEntities,
	selectOperators,
	selectPopulatedOperators,
} from '@/features/operators/operators-slice'
import { cn } from '@/lib/utils'

import { ManageOperatorsDialog } from './manage-operators-dialog'

type AssignOperatorsDialogProps = {
	open: boolean
	onOpenChange: (open: boolean) => void
	onSubmit: (operators: TPhasedItemV2[]) => void
	initialOperators?: TPhasedItemV2[]
	container?: HTMLElement | null
	booking: {
		effectiveTimeMinutes: { before: number; during: number; after: number }
	}
}

type OperatorFormItem = TPhasedItemV2 & {
	name: string
	initials: string
}

type PhaseToggleGroupProps = {
	name: string
	phases: OperatorFormItem['phases']
	onChange: (value: OperatorFormItem['phases']) => void
	effectiveTimeMinutes: {
		before: number
		during: number
		after: number
	}
}

const PhaseToggleGroup = (props: PhaseToggleGroupProps) => {
	const { name, phases, onChange, effectiveTimeMinutes } = props
	if (name.length === 0) return null

	const selectedValues = Object.entries(phases)
		.filter(([, value]) => value)
		.map(([key]) => key)

	// Only show phases that have time allocated
	const availablePhases = {
		before: effectiveTimeMinutes.before > 0,
		during: true, // Production phase is always available
		after: effectiveTimeMinutes.after > 0,
	}

	const phaseLabels = {
		before: 'Setup',
		during: 'Production',
		after: 'Teardown',
	}

	// Filter to only phases that are available
	const phaseEntries = Object.entries(phaseLabels).filter(
		([key]) => availablePhases[key as keyof typeof availablePhases],
	)

	return (
		<ToggleGroup
			type="multiple"
			value={selectedValues}
			onValueChange={values => {
				onChange({
					before: values.includes('before'),
					during: values.includes('during'),
					after: values.includes('after'),
				})
			}}
			className={cn(
				'grid gap-1.5',
				phaseEntries.length === 3 && 'grid-cols-1 sm:grid-cols-3',
				phaseEntries.length === 2 && 'grid-cols-1 sm:grid-cols-2',
				phaseEntries.length === 1 && 'grid-cols-1',
			)}
		>
			{phaseEntries.map(([phase, label]) => (
				<ToggleGroupItem
					key={phase}
					value={phase}
					aria-label={`Toggle ${label.toLowerCase()} phase`}
					className={cn(
						'h-9 text-xs font-normal transition-colors',
						'data-[state=on]:bg-primary data-[state=on]:text-primary-foreground',
						'data-[state=off]:bg-secondary data-[state=off]:text-secondary-foreground hover:data-[state=off]:bg-secondary/80',
					)}
				>
					{label}
				</ToggleGroupItem>
			))}
		</ToggleGroup>
	)
}

export function AssignOperatorsDialog({
	open,
	onOpenChange,
	onSubmit,
	initialOperators = [],
	booking,
	container,
}: AssignOperatorsDialogProps) {
	const operators = useAppSelector(selectOperators)
	const operatorEntities = useAppSelector(selectOperatorEntities)
	const populatedOperators = useAppSelector(state =>
		selectPopulatedOperators(state, initialOperators),
	)
	const [manageOperatorsOpen, setManageOperatorsOpen] = useState(false)

	const { control, handleSubmit, watch, reset } = useForm({
		defaultValues: {
			operators:
				populatedOperators.length > 0
					? populatedOperators
					: [
							{
								id: '',
								name: '',
								initials: '',
								factor: 1,
								phases: {
									before: false,
									during: true,
									after: false,
								},
							},
						],
		},
	})

	// Reset form when dialog closes
	const handleDialogOpenChange = (isOpen: boolean) => {
		if (!isOpen) {
			// Reset form to initial values based on initialOperators
			reset({
				operators:
					populatedOperators.length > 0
						? populatedOperators
						: [
								{
									id: '',
									name: '',
									initials: '',
									factor: 1,
									phases: {
										before: false,
										during: true,
										after: false,
									},
								},
							],
			})
		}
		onOpenChange(isOpen)
	}

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'operators',
	})

	const watchedOperators = watch('operators')

	// Clean up deleted operators when the manage operators dialog closes
	const handleManageOperatorsClose = (isOpen: boolean) => {
		setManageOperatorsOpen(isOpen)
		if (!isOpen) {
			// Get current form values
			const currentOperators = watchedOperators
			// Filter out operators that no longer exist and keep valid ones
			const validOperators = currentOperators.filter(
				op => op.id === '' || operatorEntities[op.id],
			)
			// Add an empty operator if all were removed
			if (validOperators.length === 0) {
				validOperators.push({
					id: '',
					name: '',
					initials: '',
					factor: 1,
					phases: {
						before: false,
						during: true,
						after: false,
					},
				})
			}
			// Reset form with valid operators
			reset({ operators: validOperators })
		}
	}

	const handleFormSubmit = (data: { operators: OperatorFormItem[] }) => {
		const operatorsWithData = data.operators
			.filter(
				operator => operator.name.length > 0 && operatorEntities[operator.id],
			) // Only include existing operators
			.map(operator => ({
				id: operator.id,
				factor: operator.factor,
				phases: operator.phases,
			}))
		onSubmit(operatorsWithData)
		onOpenChange(false)
	}

	// Function to check if an operator is already assigned
	const isOperatorAssigned = (operatorId: string) => {
		return watchedOperators.some(
			op => op.id === operatorId && op.name.length > 0,
		)
	}

	return (
		<>
			<GenericDialog
				open={open}
				onClose={() => handleDialogOpenChange(false)}
				container={container}
				title="Assign Operators"
				description="Add operators and assign their phases for this booking."
				className="flex h-[90dvh] flex-col gap-0 p-0 sm:max-w-[600px]"
				headerClassName="border-b p-6"
				headerChildren={
					<div className="flex justify-end">
						<Button
							variant="outline"
							size="sm"
							onClick={() => setManageOperatorsOpen(true)}
							className="h-9 whitespace-nowrap"
						>
							<Plus className="mr-2 h-3.5 w-3.5" />
							Manage Operators
						</Button>
					</div>
				}
			>
				<form
					onSubmit={handleSubmit(handleFormSubmit)}
					className="flex min-h-0 flex-1 flex-col overflow-hidden"
				>
					<div className="flex-1 overflow-hidden">
						<div className="h-full overflow-y-auto px-6 py-6">
							<div className="space-y-4">
								{operators.length === 0 ? (
									<Card className="border-dashed p-6">
										<div className="text-center">
											<p className="text-sm font-medium">
												No operators available
											</p>
											<p className="mt-1 text-sm text-muted-foreground">
												Create operators first to assign them to bookings
											</p>
										</div>
									</Card>
								) : (
									<>
										{fields.map(({ id }, index) => (
											<Controller
												key={id}
												name={`operators.${index}`}
												control={control}
												render={({ field }) => (
													<div className="group relative rounded-lg border bg-card p-4 shadow-sm transition-colors hover:bg-accent/10">
														<div className="flex flex-col gap-4">
															{/* Header with remove button */}
															<div className="flex items-center justify-between sm:hidden">
																<div className="text-sm font-medium text-muted-foreground">
																	{field.value.name || 'Select Operator'}
																</div>
																<Button
																	type="button"
																	variant="ghost"
																	size="icon"
																	onClick={() => remove(index)}
																	className="h-8 w-8"
																>
																	<X className="h-4 w-4" />
																</Button>
															</div>

															{/* Main content */}
															<div className="flex flex-col gap-2 sm:flex-row sm:items-start">
																<div className="flex flex-1 flex-col gap-4 sm:flex-row sm:items-center">
																	<div
																		className={cn(
																			'sm:w-[180px]',
																			!field.value.name && 'sm:w-full',
																		)}
																	>
																		<Combobox
																			initialValue={field.value.id}
																			allowCreation={false}
																			options={operators
																				.filter(
																					o =>
																						!isOperatorAssigned(o.id) ||
																						o.id === field.value.id,
																				)
																				.map(o => ({
																					value: o.id,
																					label: `${o.name} (${o.initials})`,
																				}))}
																			searchPlaceholder="Type operator name..."
																			valuePlaceholder="Select an operator..."
																			classNameButton="w-full h-9 truncate"
																			onSelect={value => {
																				const selectedOperator =
																					operatorEntities[value]
																				if (selectedOperator) {
																					field.onChange({
																						...field.value,
																						id: selectedOperator.id,
																						name: selectedOperator.name,
																						initials: selectedOperator.initials,
																					})
																				}
																			}}
																		/>
																	</div>
																	{field.value.name.length > 0 && (
																		<div className="flex-1">
																			<PhaseToggleGroup
																				name={field.value.name}
																				phases={field.value.phases}
																				onChange={phases => {
																					field.onChange({
																						...field.value,
																						phases,
																					})
																				}}
																				effectiveTimeMinutes={
																					booking.effectiveTimeMinutes
																				}
																			/>
																		</div>
																	)}
																</div>

																{/* Desktop remove button */}
																<Button
																	type="button"
																	variant="ghost"
																	size="icon"
																	onClick={() => remove(index)}
																	className="hidden h-9 w-9 sm:flex"
																>
																	<X className="h-4 w-4" />
																</Button>
															</div>
														</div>
													</div>
												)}
											/>
										))}
										{(watchedOperators.length === 0 ||
											(watchedOperators.length > 0 &&
												watchedOperators[watchedOperators.length - 1].name
													.length > 0 &&
												operators.length > watchedOperators.length)) && (
											<Button
												variant="outline"
												type="button"
												onClick={() =>
													append({
														id: '',
														name: '',
														initials: '',
														factor: 1,
														phases: {
															before: false,
															during: true,
															after: false,
														},
													})
												}
												className="h-9 w-full"
											>
												<Plus className="mr-2 h-4 w-4" />
												Add Operator
											</Button>
										)}
									</>
								)}
							</div>
						</div>
					</div>

					<DialogFooter className="border-t p-6">
						<Button
							type="button"
							variant="outline"
							onClick={() => onOpenChange(false)}
							className="w-full sm:w-auto"
						>
							Cancel
						</Button>
						<Button
							type="submit"
							disabled={operators.length === 0}
							className="w-full sm:w-auto"
						>
							Save Changes
						</Button>
					</DialogFooter>
				</form>
			</GenericDialog>

			<ManageOperatorsDialog
				open={manageOperatorsOpen}
				onOpenChange={handleManageOperatorsClose}
				container={container}
			/>
		</>
	)
}
