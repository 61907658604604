import { TOrder } from '@repo/types'
import { format, isSameDay } from 'date-fns'

import { StatusBadge } from '@/components/status-badge'

export function OrderStatusBadge(props: { order: TOrder<unknown> }) {
	const { order } = props
	if (order.status === 'planned' || order.status === 'in-progress') {
		if (!order.plannedPeriod.startDate || !order.plannedPeriod.endDate)
			return null
		return (
			<StatusBadge status={order.status}>
				{isSameDay(
					order.plannedPeriod.startDate,
					order.plannedPeriod.endDate,
				) ? (
					format(order.plannedPeriod.startDate, 'd MMM yyyy')
				) : (
					<>
						{format(order.plannedPeriod.startDate, 'd MMM yyyy')} &ndash;{' '}
						{format(order.plannedPeriod.endDate, 'd MMM yyyy')}
					</>
				)}
			</StatusBadge>
		)
	} else if (order.status === 'completed') {
		if (!order.completionDate) return null
		return (
			<StatusBadge status={order.status}>
				{format(order.completionDate, 'd MMM yyyy')}
			</StatusBadge>
		)
	} else {
		return <StatusBadge status={order.status} />
	}
}
