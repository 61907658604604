import {
	TCalendarAdjustment,
	TCalendarPeriodAdjustment,
	TMachine,
	TPeriod,
	TPeriodString,
	TPhasedItem,
	TPhaseSchedule,
} from '@repo/types'
import {
	addDays,
	addMinutes,
	areIntervalsOverlapping,
	differenceInMinutes,
	isAfter,
	isBefore,
	max,
	min,
	startOfWeek,
} from 'date-fns'

import { adjustOpenPeriods } from '../planning/adjust-open-periods'
import { getCalendarAdjustmentsForMachine } from '../planning/get-calendar-adjustments-for-machine'
import { getOpenPeriods } from '../planning/get-open-periods'

/******************************************************
 * 1) Types
 ******************************************************/
type Booking = {
	startDate: string
	endDate: string
	staffGroups: TPhasedItem[]
	phases: TPhaseSchedule<TPeriodString>
	machineId: string
}

type DemandChange = {
	time: Date
	staffGroup: string
	delta: number
}

type DemandBlock = {
	period: TPeriod
	demands: { [staffGroup: string]: number }
}

type AggregatedDemand = {
	min: { [staffGroup: string]: number }
	avg: { [staffGroup: string]: number }
	max: { [staffGroup: string]: number }
	total: {
		min: number
		avg: number
		max: number
	}
}

export type AggregatedInterval = {
	startDate: string // ISO 8601 date-time string
	endDate: string // ISO 8601 date-time string
	aggregatedDemand: AggregatedDemand
}

export type StaffDemandResult = {
	intervals: AggregatedInterval[]
	staffGroups: string[]
}

/******************************************************
 * 2) Utility Functions
 ******************************************************/
// Splits [period.startDate, period.endDate) into intervals
function splitIntoIntervals(args: {
	period: TPeriod
	intervalMinutes: number
}): TPeriod[] {
	const { period, intervalMinutes } = args
	const result: TPeriod[] = []

	// Special handling for 7-day intervals to align with calendar weeks
	if (intervalMinutes === 10080) {
		// 7 days * 24 hours * 60 minutes
		// For week intervals, always extend to complete weeks
		const firstWeekStart = startOfWeek(period.startDate, { weekStartsOn: 1 })
		const lastWeekStart = startOfWeek(period.endDate, { weekStartsOn: 1 })
		let current = firstWeekStart
		while (!isAfter(current, lastWeekStart)) {
			const weekEnd = addDays(current, 7)
			result.push({
				startDate: current,
				// Ensure the week ends at Sunday 23:59:59.999
				endDate: new Date(weekEnd.getTime() - 1),
			})
			current = weekEnd
		}
		return result
	}

	// Normal handling for other intervals
	let current = period.startDate
	while (isBefore(current, period.endDate)) {
		const next = addMinutes(current, intervalMinutes)
		result.push({
			startDate: current,
			endDate: isAfter(next, period.endDate) ? period.endDate : next,
		})
		current = next
	}
	return result
}

// Returns overlap in minutes between two intervals
function getOverlapInMinutes(args: { a: TPeriod; b: TPeriod }): number {
	const { a, b } = args
	const latestStart = max([a.startDate, b.startDate])
	const earliestEnd = min([a.endDate, b.endDate])
	return Math.max(0, differenceInMinutes(earliestEnd, latestStart))
}

// Rounds a number to two decimals
function roundTwoDecimals(value: number): number {
	return Math.round(value * 100) / 100
}

/******************************************************
 * 3) Main Calculation - Phase-Aware Line-Sweep
 ******************************************************/
export function calculateStaffDemand(args: {
	bookings: Booking[]
	period: TPeriod
	intervalMinutes: number
	machines: TMachine[]
	calendarAdjustments: TCalendarAdjustment[]
	staffGroups?: string[] // Optional filter list
}): StaffDemandResult {
	const {
		bookings,
		period,
		intervalMinutes,
		machines,
		calendarAdjustments,
		staffGroups = [],
	} = args

	/******************************************************
	 * Precompute Lookup Maps
	 ******************************************************/
	// Map machine id => machine
	const machineMap = new Map<string, TMachine>()
	for (const machine of machines) {
		machineMap.set(machine.id, machine)
	}
	// Map machine id => calendar adjustments for that machine
	const machineCalAdjMap = new Map<string, TCalendarPeriodAdjustment[]>()
	for (const machine of machines) {
		const adjustments = getCalendarAdjustmentsForMachine({
			calendarAdjustments,
			machineId: machine.id,
		})
		machineCalAdjMap.set(machine.id, adjustments)
	}

	/******************************************************
	 * A) Gather Change Points
	 ******************************************************/
	const changePoints: DemandChange[] = []

	// Helper: add change points (only during open periods)
	function addChangePointsForPeriod(
		start: Date,
		end: Date,
		isNeeded: boolean,
		staffGroupName: string,
		factor: number,
		openPeriods: TPeriod[],
	) {
		if (!isNeeded || !isBefore(start, end)) return
		// Clip start to the overall period start
		const periodStart = isBefore(start, period.startDate)
			? period.startDate
			: start
		// For each open period, if overlapping then add change points
		for (const openPeriod of openPeriods) {
			if (
				areIntervalsOverlapping(
					{ start: periodStart, end },
					{ start: openPeriod.startDate, end: openPeriod.endDate },
					{ inclusive: true },
				)
			) {
				const intersectionStart = max([periodStart, openPeriod.startDate])
				const intersectionEnd = min([end, openPeriod.endDate])
				changePoints.push({
					time: intersectionStart,
					staffGroup: staffGroupName,
					delta: factor,
				})
				changePoints.push({
					time: intersectionEnd,
					staffGroup: staffGroupName,
					delta: -factor,
				})
			}
		}
	}

	// Process each booking
	for (const booking of bookings) {
		const machine = machineMap.get(booking.machineId)
		if (!machine) {
			console.warn(`Machine ${booking.machineId} not found for booking`)
			continue
		}

		// Compute open periods once for this booking
		const bookingStart = new Date(booking.phases.before.startDate)
		const bookingEnd = new Date(booking.phases.after.endDate)
		const openPeriods = adjustOpenPeriods({
			startDate: bookingStart,
			endDate: bookingEnd,
			calendarAdjustments: machineCalAdjMap.get(machine.id) || [],
			openPeriods: getOpenPeriods({
				startDate: bookingStart,
				endDate: bookingEnd,
				availability: machine.availability,
			}),
		})

		// Convert all phase timestamps once per booking
		const beforeStart = bookingStart
		const beforeEnd = new Date(booking.phases.before.endDate)
		const duringStart = new Date(booking.phases.during.startDate)
		const duringEnd = new Date(booking.phases.during.endDate)
		const afterStart = new Date(booking.phases.after.startDate)
		const afterEnd = new Date(booking.phases.after.endDate)

		// For each staff group in this booking, add change points per phase.
		for (const sg of booking.staffGroups) {
			// sg.phases.{before, during, after} are assumed booleans
			addChangePointsForPeriod(
				beforeStart,
				beforeEnd,
				sg.phases.before,
				sg.name,
				sg.factor,
				openPeriods,
			)
			addChangePointsForPeriod(
				duringStart,
				duringEnd,
				sg.phases.during,
				sg.name,
				sg.factor,
				openPeriods,
			)
			addChangePointsForPeriod(
				afterStart,
				afterEnd,
				sg.phases.after,
				sg.name,
				sg.factor,
				openPeriods,
			)
		}
	}

	// Sort change points by time ascending
	changePoints.sort((a, b) => a.time.getTime() - b.time.getTime())

	/******************************************************
	 * B) Build Demand Blocks with a Line-Sweep
	 ******************************************************/
	const demandBlocks: DemandBlock[] = []
	const demandMap: { [staffGroup: string]: number } = {}
	let previousTime = period.startDate

	for (let i = 0, len = changePoints.length; i < len; i++) {
		const { time, staffGroup, delta } = changePoints[i]
		// Ensure we start no earlier than period.startDate
		const currentTime = isBefore(time, period.startDate)
			? period.startDate
			: time
		if (isAfter(currentTime, period.endDate)) break
		if (isAfter(currentTime, previousTime)) {
			// Save the block with a snapshot of current demands
			demandBlocks.push({
				period: { startDate: previousTime, endDate: currentTime },
				demands: { ...demandMap },
			})
			previousTime = currentTime
		}
		// Update demand for this staff group
		demandMap[staffGroup] = (demandMap[staffGroup] || 0) + delta
	}
	// Final block if needed
	if (isBefore(previousTime, period.endDate)) {
		demandBlocks.push({
			period: { startDate: previousTime, endDate: period.endDate },
			demands: { ...demandMap },
		})
	}

	/******************************************************
	 * C) Aggregate By Interval
	 ******************************************************/
	const intervals = splitIntoIntervals({ period, intervalMinutes })

	// Gather all staff group names from bookings
	const allStaffGroups = new Set<string>()
	for (const booking of bookings) {
		for (const sg of booking.staffGroups) {
			allStaffGroups.add(sg.name)
		}
	}
	// Use provided staffGroups filter if non-empty; otherwise all found staff groups.
	const activeStaffGroups =
		staffGroups.length > 0 ? staffGroups : Array.from(allStaffGroups)

	const result: StaffDemandResult = {
		intervals: [],
		staffGroups: activeStaffGroups,
	}

	// For each interval, compute min, max, and time-weighted average per staff group.
	for (const chunk of intervals) {
		const minMap: { [sg: string]: number } = {}
		const maxMap: { [sg: string]: number } = {}
		const sumMap: { [sg: string]: number } = {}

		for (const sg of activeStaffGroups) {
			minMap[sg] = Number.POSITIVE_INFINITY
			maxMap[sg] = Number.NEGATIVE_INFINITY
			sumMap[sg] = 0
		}

		const chunkMinutes = differenceInMinutes(chunk.endDate, chunk.startDate)

		// Process each demand block that might overlap with the current interval.
		for (const block of demandBlocks) {
			const overlap = getOverlapInMinutes({ a: block.period, b: chunk })
			if (overlap > 0) {
				for (const sg of activeStaffGroups) {
					const demandValue = block.demands[sg] || 0
					if (demandValue < minMap[sg]) minMap[sg] = demandValue
					if (demandValue > maxMap[sg]) maxMap[sg] = demandValue
					sumMap[sg] += demandValue * overlap
				}
			}
		}

		const aggregatedDemand: AggregatedDemand = {
			min: {},
			avg: {},
			max: {},
			total: { min: 0, avg: 0, max: 0 },
		}

		for (const sg of activeStaffGroups) {
			const minVal = minMap[sg] === Number.POSITIVE_INFINITY ? 0 : minMap[sg]
			const maxVal = maxMap[sg] === Number.NEGATIVE_INFINITY ? 0 : maxMap[sg]
			// Time-weighted average
			const avgVal = chunkMinutes === 0 ? 0 : sumMap[sg] / chunkMinutes

			aggregatedDemand.min[sg] = roundTwoDecimals(minVal)
			aggregatedDemand.max[sg] = roundTwoDecimals(maxVal)
			aggregatedDemand.avg[sg] = roundTwoDecimals(avgVal)
			aggregatedDemand.total.min += aggregatedDemand.min[sg]
			aggregatedDemand.total.max += aggregatedDemand.max[sg]
			aggregatedDemand.total.avg += aggregatedDemand.avg[sg]
		}

		// Round totals to two decimals
		aggregatedDemand.total.min = roundTwoDecimals(aggregatedDemand.total.min)
		aggregatedDemand.total.max = roundTwoDecimals(aggregatedDemand.total.max)
		aggregatedDemand.total.avg = roundTwoDecimals(aggregatedDemand.total.avg)

		result.intervals.push({
			startDate: chunk.startDate.toISOString(),
			endDate: chunk.endDate.toISOString(),
			aggregatedDemand,
		})
	}

	return result
}
